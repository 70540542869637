import React from 'react';
import Chart from 'react-apexcharts';

const AverageQuizCompletionTimeChart = ({ classData, selectedUnitId }) => {
    const selectedUnit = classData.unitsStatistics.find(unit => unit.unitId === selectedUnitId);

    if (!selectedUnit) {
        return <div>No data available for the selected unit.</div>;
    }

    const sections = selectedUnit.sectionsStatistics.map(section => section.sectionName);

    // Helper function to convert seconds to appropriate time unit for bar height
    const convertTimeForBars = (seconds) => {
        if (seconds >= 86400) {
            return 86400; // Cap at 24 hours (86400 seconds)
        } else if (seconds >= 3600) {
            return (seconds / 3600).toFixed(2); // Convert to hours
        } else if (seconds >= 60) {
            return (seconds / 60).toFixed(2); // Convert to minutes
        } else {
            return seconds.toFixed(2); // Keep as seconds
        }
    };

    // Helper function to cap and convert time for labels and tooltips
    const formatTime = (seconds) => {
        if (seconds >= 86400) {
            return '>24h'; // For values exceeding 24 hours
        } else if (seconds >= 3600) {
            const hours = (seconds / 3600).toFixed(0);
            return `${hours}h`;
        } else if (seconds >= 60) {
            const minutes = (seconds / 60).toFixed(0);
            return `${minutes}m`;
        } else {
            return `${seconds.toFixed(0)}s`;
        }
    };

    // Convert data for bar heights (converted to appropriate units)
    const avgQuizCompletionTime = selectedUnit.sectionsStatistics.map(section =>
        convertTimeForBars(section.avgQuizCompletionTimeInSeconds)
    );

    const maxValueInData = Math.max(...avgQuizCompletionTime);
    const maxValue = maxValueInData >= 86400 ? 86400 : maxValueInData; // Set the max to 24 hours (86400 seconds) if needed

    const chartOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: true,
            },
        },
        xaxis: {
            categories: sections,
            title: {
                text: 'Section Name',
            },
            labels: {
                style: {
                    fontSize: '12px',
                },
                formatter: function (value) {
                    return value.length > 10 ? value.substring(0, 10) + '...' : value; // Truncate label if longer than 10 characters
                },
            },
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                formatter: (value) => formatTime(value), // Format labels using the helper function
                style: {
                    fontSize: '12px',
                },
            },
            tickAmount: 5,  // Ensures 5 even increments
            min: 0, // Start from 0
            max: maxValue, // Dynamically set the max value
        },
        grid: {
            show: true,
            yaxis: {
                lines: {
                    show: true,
                }
            }
        },
        stroke: {
            curve: 'straight',
        },
        title: {
            text: `Average Quiz Completion Time by Section`,
            align: 'left'
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (value) => formatTime(value), // Also format the tooltip to show converted values
            },
        },
        dataLabels: {
            enabled: false, // Disable the value labels on the bar
        },
    };

    const chartSeries = [
        {
            name: 'Avg Quiz Completion Time',
            data: avgQuizCompletionTime, // Now the bar heights reflect the converted time values
        },
    ];

    return (
        <div>
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height="320"
            />
        </div>
    );
};

export default AverageQuizCompletionTimeChart;
