import React, { useEffect, useState } from "react";
import { Text, Button, Heading, Img, Input } from "..";
import "./style.css";

export default function StartupProgressBar ({sessionData, dashData, stageNumber, stages}) {
  const [stageName, setStageName] = useState("");
  const [percentToComplete, setPercentToComplete] = useState(0);
  const [goalName, setGoalName] = useState("")
  const [goalProgress, setGoalProgress] = useState(0);
  const [goalTotal, setGoalTotal] = useState(0);

  useEffect(() => {
    let _stageNumber = stageNumber;
    let _stageName = stages[_stageNumber];
    let _goalTotal = sessionData["stage"+_stageNumber+"Number"];
    let _goalName = sessionData["stage"+_stageNumber+"Metric"];
    let _percentToComplete = dashData.percentToStageComplete;

    setStageName(_stageName);
    setGoalTotal(_goalTotal);
    setGoalName(_goalName);
    setPercentToComplete(_percentToComplete);
    setGoalProgress(Math.round((_percentToComplete/100) * _goalTotal));
  }, [sessionData, dashData, stageNumber, stages]);

  return (
    <div className="flex w-full rounded-lg border border-solid border-slate-300 bg-white-A700 sm:flex-col">
        <div className="flex w-full px-4 py-[19px] justify-content items-center">
          <div className="flex flex-col w-full items-center gap-1 border-blue_gray-100 px-[15px] sm:px-5">
            <div className="flex self-start">
              <Heading size="5xl" as="h4" className="!font-poppins !text-gray-800_02">
                {stageName} Round Goal
              </Heading>
            </div>
            <div className="flex-1 w-full self-start">
              <Heading size="4xl" as="h4" className="!text-gray-500_02">
                <span>{goalProgress}/{goalTotal} {goalName}: {percentToComplete}% to complete</span>
              </Heading>
            </div>
            <div className="w-full !bg-gray-200 rounded-full h-4">
            <progress class="progress-bar w-full h-4 rounded-lg bg-gray-200" value={percentToComplete} max="100"></progress>
            </div>
          </div>
        </div>
    </div>
  );
}


