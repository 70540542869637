import { useState } from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Heading, Button, CheckBox, Input } from "../../../../components";
import { useNavigate } from "react-router-dom";
import 'react-phone-number-input/style.css';
import PhoneInputWithCountrySelect from "react-phone-number-input";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export default function RegisterStudentForm() {
    const [school, setSchool] = useState("");
    const [first, setFirst] = useState("");
    const [last, setLast] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [username, setUsername] = useState("");
    const [classCode, setClassCode] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [privacy, setPrivacy] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [error, setError] = useState("");
    const [detailedErrors, setDetailedErrors] = useState({});

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate('/Register');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // validate email and passwords
        if (!email || !password) {
            setError("Please fill in all fields.");
        } else if (password !== confirmPassword) {
            setError("Passwords must match");
        } else if (!/\d/.test(password) || !/[^\w\s]/.test(password)) {
            setError("Password must contain at least one number and one special character.");
        } else {
            // clear error message
            setError("");
            setDetailedErrors({});
            // post data to the /register api

            const requestBody = {
                firstName: first,
                middleName: "",
                lastName: last,
                highSchool: school,
                username: username,
                email: email,
                password: password,
                confirmPassword: confirmPassword,
                classToken: classCode
            };

            fetch(API_ENDPOINT + "/register", {
                method: "POST",
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json', // Ensure this header is set
                    'accept': 'application/problem+json'
                },
                body: JSON.stringify(requestBody),
            })
                .then(async (response) => {
                    const responseText = await response.text();
                    let responseData;
                    try {
                        
                    } catch (e) {
                        throw new Error('Invalid JSON response: ' + responseText);
                    }

                    if (response.ok) {
                        setError("Successful Register.");
                        navigate("/register/student/success");
                    } else {
                        if (responseData.errors) {
                            const filteredErrors = { ...responseData.errors };
                            delete filteredErrors.DuplicateUserName;
                            setDetailedErrors(filteredErrors);
                        } else {
                            setError("There was an issue registering");
                        }
                    }
                })
                .catch((error) => {
                    // handle network error
                    console.error("Fetch Error:", error); // Log error
                    setError("Error During Registration. Try refreshing the page and registering again.");
                });
        }
    };

    return (
        <div className="flex max-w-[700px] w-[60%] flex-col gap-8 sm:w-full sm:p-2">
            <form onSubmit={handleSubmit}>
                <div className="text-red-500 text-sm py-4">
                    {error && <p>{error}</p>}
                    {Object.keys(detailedErrors).map((key) => (
                        <p key={key}>{detailedErrors[key]}</p>
                    ))}
                </div>
                <div className="flex flex-col gap-6">
                    <div className="flex flex-col items-start gap-1.5">
                        <Text as="p" className="!font-medium !text-blue_gray-800_01">
                            Institution Name
                        </Text>
                        <Input
                            shape="round"
                            id="school"
                            name="school"
                            value={school}
                            onChange={setSchool}
                            placeholder={`i.e., Indiana High School`}
                            className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                        />
                    </div>
                    <div className="flex gap-8 sm:flex-col">
                        <div className="flex w-full flex-col items-start gap-1.5 sm:w-full">
                            <Text as="p" className="!font-medium !text-blue_gray-800_01">
                                <span className="text-blue_gray-800_01">First Name</span>
                                <span className="text-red-500">*</span>
                            </Text>
                            <Input
                                required
                                shape="round"
                                type="text"
                                id="first"
                                name="first"
                                value={first}
                                onChange={setFirst}
                                placeholder={`First name`}
                                className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                            />
                        </div>
                        <div className="flex w-full flex-col items-start gap-1.5 sm:w-full">
                            <Text as="p" className="!font-medium !text-blue_gray-800_01">
                                <span className="text-blue_gray-800_01">Last Name</span>
                                <span className="text-red-500">*</span>
                            </Text>
                            <Input
                                required
                                shape="round"
                                type="text"
                                id="last"
                                name="last"
                                value={last}
                                onChange={setLast}
                                placeholder={`Last name`}
                                className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col items-start gap-1.5">
                        <Text as="p" className="!font-medium !text-blue_gray-800_01">
                            <span className="text-blue_gray-800_01">Email</span>
                            <span className="text-red-500">*</span>
                        </Text>
                        <Input
                            required
                            shape="round"
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={setEmail}
                            placeholder={`you@company.com`}
                            className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                        />
                    </div>
                    <div className="flex flex-col items-start gap-1.5">
                        <Text as="p" className="!font-medium !text-blue_gray-800_01">
                            <span className="text-blue_gray-800_01">Phone Number</span>
                        </Text>
                        <PhoneInputWithCountrySelect
                            defaultCountry="US"
                            placeholder="(555) 555-5555"
                            id="phone"
                            name="phone"
                            value={phone}
                            className="h-[48px] p-2 self-stretch border rounded-xl border-solid border-blue_gray-100_05 sm:pr-5 focus:border-none"
                            onChange={setPhone}
                        />
                    </div>
                    <div className="flex gap-6 sm:flex-col">
                        <div className="flex w-full flex-col items-start gap-1.5 sm:w-full">
                            <Text as="p" className="!font-medium !text-blue_gray-800_01">
                                <span className="text-blue_gray-800_01">Username</span>
                                <span className="text-red-500">*</span>
                            </Text>
                            <Input
                                required
                                shape="round"
                                type="text"
                                name="username"
                                id="username"
                                value={username}
                                onChange={setUsername}
                                placeholder={``}
                                className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                            />
                        </div>
                        <div className="flex w-full flex-col items-start gap-1.5 sm:w-full">
                            <Text as="p" className="!font-medium !text-blue_gray-800_01">
                                <span className="text-blue_gray-800_01">Class Code</span>
                                <span className="text-red-500">*</span>
                            </Text>
                            <Input
                                shape="round"
                                name="classCode"
                                id="classCode"
                                value={classCode}
                                onChange={setClassCode}
                                placeholder={`AB1D-W9YZ`}
                                className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                            />
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <div className="flex gap-6 sm:flex-col">
                            <div className="flex w-full flex-col items-start gap-1.5 sm:w-full">
                                <Text as="p" className="!font-medium !text-blue_gray-800_01">
                                    Password
                                </Text>
                                <Input
                                    size="xl"
                                    shape="round"
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    id="password"
                                    value={password}
                                    onChange={setPassword}
                                    suffix={<Img src={showPassword ? "/images/img_eye.svg" : "/images/img_eyeoff.svg"}
                                        alt={showPassword ? "eye" : "eye-off"}
                                        onClick={() => setShowPassword(!showPassword)}
                                        className="h-[16px] w-[16px] cursor-pointer" />}
                                    className="gap-[35px] self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                                />
                            </div>
                            <div className="flex w-full flex-col items-start gap-1.5 sm:w-full">
                                <Text as="p" className="!font-medium !text-blue_gray-800_01">
                                    <span className="text-blue_gray-800_01">Confirm Password</span>
                                    <span className="text-red-500">*</span>
                                </Text>
                                <Input
                                    required
                                    size="xl"
                                    shape="round"
                                    type={showConfirmPassword ? "text" : "password"}
                                    name="confirmPassword"
                                    id="confirmPassword"
                                    value={confirmPassword}
                                    onChange={setConfirmPassword}
                                    placeholder={`**********`}
                                    suffix={<Img src={showConfirmPassword ? "/images/img_eye.svg" : "/images/img_eyeoff.svg"}
                                        alt={showConfirmPassword ? "eye" : "eye-off"}
                                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        className="h-[16px] w-[16px] cursor-pointer" />}
                                    className="gap-[35px] self-stretch border border-solid border-blue_gray-100_05"
                                />
                            </div>
                        </div>
                        <p className="text-xs pt-2 text-gray-600">Your password MUST contain one uppercase letter, one digit, and one special character.</p>
                    </div>

                    <CheckBox
                        required
                        label="You agree to our friendly privacy policy."
                        id="privacy"
                        name="privacy"
                        value={privacy}
                        onChange={setPrivacy}
                        className="gap-3 p-px font-inter text-base text-blue_gray-500"
                    />
                    <div className="flex gap-4 justify-between sm:flex-col">
                        <Button
                            size="5xl"
                            variant="outline"
                            shape="round"
                            leftIcon={<Img src="/images/img_arrowdown.svg" alt="arrow_down" className="h-[16px] w-[16px]" />}
                            className="min-w-[161px] gap-2 font-semibold shadow-sm sm:px-5 pl-[1.25rem]"
                            onClick={handleBackClick}
                        >
                            Back
                        </Button>
                        <Button
                            size="5xl"
                            shape="round"
                            className="flex w-full border border-solid border-blue-A200 font-semibold shadow-sm sm:px-5"
                        >
                            Create Account
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    );
}
