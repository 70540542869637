import { apiClient } from "AuthService";

export const updateUserProfile = async (data) => {
    try {
      const response = await apiClient('/api/UserInfo/Edit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        // Handle errors returned from the server
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update profile.');
      }
  
      // Update was successful
      const successData = await response.json();
      return successData;
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  };