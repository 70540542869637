// TopNavLogin.jsx
import { useNavigate } from "react-router-dom";
import { Button, Heading, Img, Text } from "./..";

export default function TopNavLogin({ className, type }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (type === "login") {
      navigate('/register');
    } else if (type === "register") {
      navigate('/login');
    }
  };

  // New function to handle logo clicks
  const handleLogoClick = () => {
    navigate('/login');
  };

  return (
    <header className={`flex items-center justify-center bg-white-A700 p-2 shadow-sm ${className}`}>
      <div className="flex flex-col w-[80%] items-center justify-between gap-8 md:w-full sm:flex-row">
        {/* Make the image clickable */}
        <a onClick={handleLogoClick} className="cursor-pointer">
          <Img
            src="/images/img_header_logo.png"
            alt="headerlogo"
            className="h-[64px] w-[203px] object-contain"
          />
        </a>

        {type === "login" && (
          <a onClick={handleClick} className="cursor-pointer">
            <Text as="p" className="!font-inter !font-medium">
              <span className="text-gray-900_07">Don't have an account?</span>
              <span className="text-gray-900_07">&nbsp;</span>
              <span className="text-blue-A200">Sign up&nbsp;</span>
            </Text>
          </a>
        )}

        {type === "register" && (
          <a onClick={handleClick} className="cursor-pointer">
            <Text as="p" className="!font-inter !font-medium">
              <span className="text-gray-900_07">Already have an account?</span>
              <span className="text-gray-900_07">&nbsp;</span>
              <span className="text-blue-A200">Log in&nbsp;</span>
            </Text>
          </a>
        )}
      </div>
    </header>
  );
}
