import React, {useEffect} from "react";
import { Text, Button, Heading, Img, Input } from "..";
import { IconCashBanknote, IconUser, IconUsers } from "@tabler/icons-react";

export default function StartupTeam() {


  return (
    <div className="flex flex-col w-full h-full rounded-lg border border-solid border-slate-300 bg-white-A700 sm:flex-col">
    <div className="flex flex-row w-full px-4 py-3 justify-content items-center">
      <div className="flex items-center gap-2 border-blue_gray-100 px-[15px] sm:px-5">
        <div className="flex self-start">
          <Heading size="4xl" as="h4" className="!font-poppins !text-gray-800_02 mr-3">
            Team     
          </Heading>
          <div className="relative group">
            <div className="flex flex-col items-center justify-center w-4 h-4 bg-white-A700 text-xs border-2 border-gray-500_02 rounded-full">
              i   
            </div>
            <div className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 w-[calc(100%+15rem)] opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto bg-gray-800 text-white-A700 text-xs rounded py-1 px-2 whitespace-no-wrap">
              <Heading className="!font-poppins !text-white-A700 py-1">Team</Heading>
              <p>Current number of employees and investors working in your business</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-row w-full rounded-b-lg border-t border-solid border-slate-300 h-full">
      <div className="flex flex-col items-center gap-2 border-blue_gray-100 py-3 px-5 w-full">
        <div className="flex flex-row w-full h-1/3 items-center">
          <div className="w-1/6 text-center">
              <IconUser />          
          </div>
          <div className="w-4/6">
            <Text size="6xl" className="!font-poppins !text-gray-800_02">
              Founders          
            </Text>
          </div>
          <div className="w-1/6 text-right">
            <Text size="6xl" className="!font-poppins !text-gray-800_02">
              2          
            </Text>
          </div>
        </div>
        <div className="flex flex-row w-full h-1/3 items-center">
          <div className="w-1/6 text-center">
            <IconCashBanknote />
          </div>
          <div className="w-4/6">
            <Text size="6xl" className="!font-poppins !text-gray-800_02">
              Investors          
            </Text>
          </div>
          <div className="w-1/6 text-right">
            <Text size="6xl" className="!font-poppins !text-gray-800_02">
              6          
            </Text>
          </div>
        </div>
        <div className="flex flex-row w-full h-1/3 items-center">
          <div className="w-1/6 text-center">
            <IconUsers />
          </div>
          <div className="w-4/6">
            <Text size="6xl" className="!font-poppins !text-gray-800_02">
              Employees          
            </Text>
          </div>
          <div className="w-1/6 text-right">
            <Text size="6xl" className="!font-poppins !text-gray-800_02">
              10          
            </Text>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
