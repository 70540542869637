import React, { useState, useEffect } from 'react';
import { formatDate } from 'utils';

const UpcomingEvents = ({ events, currentDay }) => {
    const [thisWeekEvents, setThisWeekEvents] = useState([]);
    const [thisMonthEvents, setThisMonthEvents] = useState([]);
    const [viewMore, setViewMore] = useState({ week: false, month: false });

    useEffect(() => {
        const today = new Date(currentDay);
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay());
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6);

        setThisWeekEvents(
            events.filter((event) => {
                const eventDate = new Date(event.start);
                return eventDate >= startOfWeek && eventDate <= endOfWeek;
            })
        );

        setThisMonthEvents(
            events.filter((event) => {
                const eventDate = new Date(event.start);
                return (
                    eventDate.getMonth() === today.getMonth() &&
                    eventDate.getFullYear() === today.getFullYear() &&
                    !(eventDate >= startOfWeek && eventDate <= endOfWeek)
                );
            })
        );
    }, [events, currentDay]);

    const renderEvents = (eventList, type) => {
        const visibleEvents = viewMore[type] ? eventList : eventList.slice(0, 2);

        return (
            <>
                {visibleEvents.map((event, index) => (
                    <div key={index} className="flex justify-between items-center px-4 py-2">
                        <p className="text-sm font-medium">{event.title}</p>
                        <p className="text-sm">{formatDate(event.start)}</p>
                    </div>
                ))}
                {eventList.length > 2 && (
                    <button
                        onClick={() => setViewMore(prev => ({ ...prev, [type]: !prev[type] }))}
                        className="text-blue-500 text-xs mt-2 hover:underline"
                    >
                        {viewMore[type] ? 'View Less' : 'View More'}
                    </button>
                )}
            </>
        );
    };

    return (
        <div className="flex flex-col grow h-full w-full max-h-fit overflow-auto divide-y rounded-lg border font-inter">
            <h1 className="p-4 font-semibold text-sm">Upcoming Events</h1>

            <div className="flex flex-col grow h-full p-4">
                <h2 className="font-semibold text-gray-700 text-sm mb-2">This Week</h2>
                {thisWeekEvents.length > 0 ? (
                    renderEvents(thisWeekEvents, 'week')
                ) : (
                    <p className="w-full h-full text-center text-sm text-gray-600">No events this week.</p>
                )}
            </div>


            {/* {thisMonthEvents.length > 0 && (
                <div className="flex flex-col p-4">
                    <h2 className="font-semibold text-gray-700 text-sm mb-2">This Month</h2>
                    {renderEvents(thisMonthEvents, 'month')}
                </div>
            )} */}
        </div>
    );
};

export default UpcomingEvents;
