import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Heading, Img, Text } from "../../components";
import StudentSidebar from "../../components/StudentSidebar";
import { useNavigate } from 'react-router-dom';
import JoinClassPopup from "../../components/JoinClassPopup"; // Import the JoinClassPopup component
import CourseCard from "components/CourseCard";
import HomeDate from "components/HomeDate";
import { ConvertDuration } from "utils";
import { fetchInfo, fetchStudentId, fetchStudentPortfolioGraph } from "SimulatorAPIService";
import { fetchCourses } from "StudentAPIService";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export default function CoursesPage() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [studentId, setStudentId] = useState();

  const handleOpenPopup = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch courses data
        const coursesData = await fetchCourses();

        if (coursesData) {
          // Extract courses and student IDs
          setCourses(
            coursesData.flatMap((s) =>
              s.courses.map((c) => ({
                studentId: s.studentId,
                course: c.course,
                class: c.class,
              }))
            )
          );

          // Set the studentId (assuming the first student's ID)
          if (coursesData.length > 0) {
            setStudentId(coursesData[0].studentId);
          }
        }
      } catch (error) {
        if (error.status === 401) {
          navigate("/timeout");
        } else {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>

      <div className="flex w-full h-screen overflow-hidden m-auto">
        <div><StudentSidebar /></div>

        <div className="flex flex-col w-full m-4 gap-4">
          <HomeDate className="w-full px-4 rounded-lg shadow-sm" />

          <div className="flex flex-col w-full gap-6">
            <div className="flex self-start pb-[7px] pt-3.5">
              <h1 className="text-2xl font-semibold ml-2">My Courses</h1>
            </div>
            <div className="grid grid-cols-3 gap-6 rounded-lg w-full">
              {courses.map((course, index) => (
                <CourseCard
                  key={index}
                  className={course.class.name} // Adjust according to data structure
                  courseName={course.course.name} // Example: "Not Started", "Continue"
                  expectedDuration={course.course.expectedDuration}
                  durationUnit={ConvertDuration(course.course.durationUnit)}
                  courseId={course.course.id}
                  studentId={studentId}
                  to={`/courses/course/${course.course.id}/student/${course.studentId}`} // Adjust routing as needed
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
