import React, { useEffect, useState } from 'react';
import { formatUSD } from 'utils';

const OptionsComponent = ({ onSubmit, progressData }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [error, setError] = useState(false); // State for showing error message
    const [options, setOptions] = useState([]);

    useEffect(() => {
        // Parse the progress data into the options format
        if (progressData?.length > 0) {
            const record = progressData[0]; // Assuming we're using the first progress record
            setOptions([
                {
                    id: "choice1",
                    title: record.choice1Title,
                    cost: record.choice1Impact,
                    details: record.choice1Description,
                },
                {
                    id: "choice2",
                    title: record.choice2Title,
                    cost: record.choice2Impact,
                    details: record.choice2Description,
                },
                {
                    id: "choice3",
                    title: record.choice3Title,
                    cost: record.choice3Impact,
                    details: record.choice3Description,
                },
            ]);
        }
    }, [progressData]);

    const handleOptionClick = (option) => {
        if (selectedOption === option) {
            setSelectedOption(null);
            return;
        }
        setSelectedOption(option);
        setError(false); // Clear error message when an option is selected
    };

    const handleSubmit = () => {
        if (selectedOption) {
            const optionIndex = options.findIndex(option => option === selectedOption) + 1; // Get the 1-based index
            onSubmit(optionIndex); // Pass the index to the parent's callback
        } else {
            setError(true); // Show error message
        }
    };
    
    return (
        <div className='flex flex-col p-4 items-center gap-4'>
            <h1 className='flex text-xl pb-2 font-semibold w-full border-b border-b-gray-200'>
                {progressData[0]?.situationTitle || "Loading..."}
            </h1>

            <p className='text-gray-700 text-center'>
                {progressData[0]?.situationDescription}
            </p>

            <div className='flex flex-col gap-4'>
                <h2 className='text-gray-700'>What will you do?</h2>
                {options.map((option) => (
                    <button
                        key={option.id}
                        onClick={() => handleOptionClick(option)}
                        className={`flex flex-col group justify-start gap-2 w-full h-full items-start p-4 font-inter text-md border border-gray-200 rounded-lg hover:bg-blue-500 hover:text-white-A700 ${
                            selectedOption === option ? 'bg-blue-600 text-white-A700' : 'bg-white-A700'
                        } transform transition-transform duration-300 ease-in-out hover:scale-105`}
                    >
                        <div className='flex gap-4 text-lg whitespace-nowrap'>
                            <h2 className='font-semibold'>{option.title}</h2>
                            <p className='text-red-500'>{formatUSD(option.cost)}</p>
                        </div>

                        <p
                            className={`text-md text-left group-hover:text-white-A700 ${
                                selectedOption === option ? 'text-white-A700' : 'text-gray-700'
                            }`}
                        >
                            {option.details}
                        </p>
                    </button>
                ))}
            </div>

            <div className='flex flex-col w-full gap-2 items-center'>
                <button
                    type='submit'
                    onClick={handleSubmit}
                    className={`flex w-[50%] justify-center items-end p-4 font-inter font-semibold bg-blue-500 text-white-A700 text-md border rounded-lg hover:bg-blue-600 transform transition-transform duration-300 ease-in-out hover:scale-105`}
                >
                    Submit
                </button>
                {error && (
                    <p className='text-red-500 text-sm mt-2'>
                        Please select an option before submitting.
                    </p>
                )}
            </div>
        </div>
    );
};

export default OptionsComponent;
