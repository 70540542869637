import React, { createContext, useState, useContext } from 'react';

// Create the UserContext
const UserContext = createContext();

// Create a custom hook to use the UserContext
const useUser = () => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};

// Create a provider component
const UserProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState(() => {
        try {
            const savedUserInfo = localStorage.getItem('userInfo');
            return savedUserInfo ? JSON.parse(savedUserInfo) : null;
        } catch (error) {
            console.error("Error reading userInfo from localStorage", error);
            return null;
        }
    });

    const updateUserInfo = (infoOrUpdater) => {
        if (typeof infoOrUpdater === 'function') {
            setUserInfo((prevUserInfo) => {
                const newUserInfo = infoOrUpdater(prevUserInfo);
                try {
                    localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
                } catch (error) {
                    console.error("Error saving userInfo to localStorage", error);
                }
                return newUserInfo;
            });
        } else if (infoOrUpdater) {
            try {
                setUserInfo(infoOrUpdater);
                localStorage.setItem('userInfo', JSON.stringify(infoOrUpdater));
            } catch (error) {
                console.error("Error saving userInfo to localStorage", error);
            }
        }
    };


    const logout = () => {
        setUserInfo(null);
        try {
            localStorage.removeItem('userInfo');
            document.cookie = 'userInfo=; Max-Age=0'; // Remove cookie
        } catch (error) {
            console.error("Error removing userInfo from localStorage or cookies", error);
        }
    };

    return (
        <UserContext.Provider value={{ userInfo, setUserInfo: updateUserInfo, logout }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider, useUser };
