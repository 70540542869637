import React, {useEffect, useState} from "react";
import { Button } from "..";
import { IconAlertCircle } from "@tabler/icons-react";
import { fetchStatus, postSettings } from "StartupSimAPIService";
import { useParams } from 'react-router-dom';
import NotFound from "pages/NotFound";
import ConfettiExplosion from 'react-confetti-explosion';

export default function StartupSimProgress({progressChange, setShowPopup, finishedStage, stages, stageNumber}) {
  const [changedMetric, setChangedMetric] = useState("")
  const [arrowDirection, setArrowDirection] = useState("");
  const [direction, setDirection] = useState("");
  const [changedBy, setChangedBy] = useState(0);
  const [changedByWord, setChangedByWord] = useState("");
  const [nextStepButtonPhrase, setNextStepButtonPhrase] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);

  const numberFormatOptions = { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 };
  const celebrateConfetti = () => {

    if (!showConfetti) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000);
    }

  };

  const nextStepButtonPhrases = {
    positive: [
      "You’re doing great!",
      "Keep it up!",
      "Fantastic effort!",
      "You’re crushing it!",
      "Stay on fire!",
      "Amazing progress!",
      "You’re on a roll!",
      "Keep shining!",
      "Great job—keep going!",
      "You’ve got this!",
      "Awesome work!",
      "Keep making magic!",
      "Stay consistent!",
      "Outstanding work!",
      "Way to go!",
      "Stay driven!",
      "Impressive work!",
      "You’re unstoppable!",
      "Keep it flowing!",
      "Stay the course!"
    ],
    negative: [
      "Let’s aim higher.",
      "Room for improvement.",
      "We can step it up.",
      "Let’s take it further.",
      "We can do more.",
      "Let’s push harder.",
      "Let’s refine this.",
      "We can elevate it.",
      "Challenge ourselves more.",
      "Let’s make it great.",
      "Room to perfect this.",
      "Let’s tighten it up.",
      "We can make it stronger.",
      "Let’s make it exceptional.",
      "Let’s enhance it.",
      "Build on this.",
      "Sharpen it up.",
      "Let’s dig deeper.",
      "We can exceed this.",
      "Reach new heights."
    ]
  };
  
  useEffect(() => {
    let _changedMetric = progressChange.changed;
    setChangedMetric(_changedMetric);

    if(progressChange.now > progressChange.was){
      setArrowDirection("arrow-up");
      setDirection("increased");
      setNextStepButtonPhrase(nextStepButtonPhrases['positive'][Math.floor(Math.random() * nextStepButtonPhrases['positive'].length)]);
    } else {
      setArrowDirection("arrow-down");
      setDirection("decreased");
      setNextStepButtonPhrase(nextStepButtonPhrases['negative'][Math.floor(Math.random() * nextStepButtonPhrases['negative'].length)]);
    }

    if(_changedMetric !== "Employee Wellness"){
      setChangedBy(new Intl.NumberFormat('en-US', numberFormatOptions).format(Math.abs(progressChange.now - progressChange.was)));
      setChangedByWord("");
    } else {
      setChangedBy(Math.abs(progressChange.now - progressChange.was));
      setChangedByWord("points");
    }

    if(finishedStage){
      celebrateConfetti();
    }
    console.log(stages);
  },[])

  return (
    <div className="p-4">
    <div className="flex flex-col gap-3">
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div class={arrowDirection}></div>
    </div>
        <h2 className="text-center">Your {changedMetric} {direction} by {changedBy} {changedByWord}</h2>
        {finishedStage && (
          <h2 className="text-center">Congratulations on finishing the {stages[(stageNumber - 1).toString()]} stage!</h2>
        )}
        {showConfetti && (
          <div className="absolute -top-10 left-1/2 transform -translate-x-1/2">
            <ConfettiExplosion />
          </div>
        )}
        <div className="flex gap-4 mt-4">
          <Button
            size="3xl"
            variant="outline"
            shape="round"
            className="w-full min-w-[200px] bg-blue-700 font-roboto font-medium tracking-[0.10px] sm:px-5"
            onClick={() => setShowPopup(false)}
            >
            <span className="text-white-A700">
                {nextStepButtonPhrase}
            </span>
          </Button>
        </div>
    </div>
</div>
  );
}