import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "UserContext";
import { Img } from "components";

/**
 * The `menuItems` array is shared by both the desktop and mobile layouts.
 * You can adjust or add items as needed.
 */
const menuItems = [
  {
    name: "Dashboard",
    path: "/studenthome",
    icon: "/images/img_container.svg",
    activeIconStyle:
      "bg-blue-100 saturate-100 invert-33 sepia-94 saturate-1863 hue-rotate-196 brightness-101 contrast-101",
  },
  {
    name: "Courses",
    path: "/courses",
    icon: "/images/img_container_gray_800_01_40x40.svg",
    activeIconStyle:
      "bg-blue-100 saturate-100 invert-33 sepia-94 saturate-1863 hue-rotate-196 brightness-101 contrast-101",
  },
  {
    name: "Stock Simulator",
    path: "/dashboard",
    icon: "/images/img_container_gray_800_01.svg",
    activeIconStyle:
      "bg-blue-100 saturate-100 invert-33 sepia-94 saturate-1863 hue-rotate-196 brightness-101 contrast-101",
  },
  {
    name: "Budget Simulator",
    path: "/budgetsimulator",
    icon: "/images/img_container_gray_800_01_40x40.svg",
    activeIconStyle:
      "bg-blue-100 saturate-100 invert-33 sepia-94 saturate-1863 hue-rotate-196 brightness-101 contrast-101",
  },
  {
    name: "Startup Simulator",
    path: "/startup",
    icon: "/images/img_container_gray_800_01.svg",
    activeIconStyle:
      "bg-blue-100 saturate-100 invert-33 sepia-94 saturate-1863 hue-rotate-196 brightness-101 contrast-101",
  },
  {
    name: "Chatbot",
    path: "/chatbot",
    icon: "/images/img_container_gray_800_01.svg",
    activeIconStyle:
      "bg-blue-100 saturate-100 invert-33 sepia-94 saturate-1863 hue-rotate-196 brightness-101 contrast-101",
  },
];

export default function StudentSidebar({ className = "" }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);

  // Determine if mobile or not.
  // You can use a prop, a custom hook, or Tailwind hidden classes.
  // For simplicity, let's just rely on Tailwind classes to show/hide layouts.
  // We'll show the desktop sidebar on md+ screens, and the mobile nav on smaller screens.
  
  const isActive = (path) => location.pathname.startsWith(path);

  return (
    <>
      {/* Desktop Sidebar (hidden on small screens) */}
      <div
        className={`hidden md:flex flex-col font-semibold justify-between w-20 pt-2 pb-2 border-r border-blue_gray-100 bg-white-A700 h-screen top-0 left-0 ${className}`}
      >
        <div className="flex flex-col space-y-6">
          {/* Brand Logo */}
          <div
            className="cursor-pointer flex justify-center"
            onClick={() => navigate("/studenthome")}
          >
            <Img
              src="/images/img_brand.svg"
              alt="brand"
              className="h-8 w-8"
            />
          </div>

          {/* Menu Items */}
          <nav className="flex flex-col">
            {menuItems.map((item) => (
              <button
                key={item.name}
                onClick={() => navigate(item.path)}
                className={`flex flex-col group items-center p-2 rounded ${
                  isActive(item.path) ? "text-blue-500" : "text-gray-700"
                } hover:text-blue-500 transition-colors`}
              >
                <Img
                  src={item.icon}
                  alt={item.name}
                  className={`h-10 w-10 rounded transition-colors group-hover:bg-blue-200 ${
                    isActive(item.path) ? item.activeIconStyle : ""
                  }`}
                />
                <span className="text-xs font-semibold mt-1 text-center">
                  {item.name}
                </span>
              </button>
            ))}
          </nav>
        </div>

        {/* User Profile */}
        <div className="flex flex-col text-sm md:text-md items-center">
          <button
            onClick={() => navigate("/myprofile")}
            className="flex flex-col items-center space-y-1 cursor-pointer"
          >
            <Img
              src={userInfo?.profileImage.url || "/images/default_avatar.png"}
              alt="Profile"
              className="h-10 w-10  rounded-full object-cover"
            />
            <span className="text-sm font-inter font-semibold text-gray-700">
              {userInfo?.firstName} {userInfo?.lastName}
            </span>
          </button>
        </div>
      </div>

      {/* Mobile Bottom Nav (hidden on medium and larger screens) */}
      <nav
        className={`fixed bottom-0 left-0 right-0  w-full bg-white-A700 border-t border-blue_gray-100 md:hidden ${className}`}
      >
        <div className="flex justify-between w-full items-center h-24 p-1">
          {/* Menu Items */}
          <div className="flex w-full gap-4">
            {menuItems.map((item) => (
              <button
                key={item.name}
                onClick={() => navigate(item.path)}
                className={`flex flex-col h-full items-center ${
                  isActive(item.path) ? "text-blue-500" : "text-gray-700"
                } hover:text-blue-500 transition-colors`}
              >
                <Img
                  src={item.icon}
                  alt={item.name}
                  className={`h-8 w-8 rounded ${
                    isActive(item.path) ? item.activeIconStyle : ""
                  }`}
                />
                <span className="text-[10px] whitespace-normal break-words text-center mt-1">{item.name}</span>
              </button>
            ))}

            {/* User Profile */}
            <button
              onClick={() => navigate("/myprofile")}
              className="flex flex-col items-center justify-center text-gray-700 hover:text-blue-500 transition-colors"
            >
              <Img
                src={userInfo?.profileImage.url || "/images/default_avatar.png"}
                alt="Profile"
                className="h-8 w-8 rounded-full object-cover"
              />
              <span className="text-xs mt-1">Profile</span>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
}
