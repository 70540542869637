import React, { useState, useEffect } from 'react';

const StartQuestion = ({ index, question, answers, nextQuestion, submitQuestion }) => {
    const [data, setData] = useState(null);

    const ProgressBar = Array.from({ length: 6 }, (_, index) => index)

    useEffect(() => {
    }, []);

    const [selectedAnswer, setSelectedAnswer] = useState(null);

    const handleAnswerClick = (answer) => {
        if (selectedAnswer === answer) {
            setSelectedAnswer(null);
            return;
        }
        setSelectedAnswer(answer);
    };

    return (
        <div className='flex flex-col items-center justify-center gap-20 p-6 py-20 w-full rounded-lg border border-gray-200'>
            <h1 className='flex h-10 justify-start font-inter font-semibold text-xl md:font-lg border-b border-b-gray-200'>Let's learn more about your spending habits before we begin!</h1>

            <div className='flex flex-col justify-center w-full gap-8'>
                <h1 className='flex justify-center text-md font-inter md:text-sm'>{index + 1} of 6: Basic Spending Needs</h1>

                <div className='flex w-full px-64 justify-center gap-4'>
                    {ProgressBar.map((bar) => (
                        <div key={bar} className={`flex h-1 w-full rounded ${bar <= index ? 'bg-blue-600' : 'bg-blue-200'} transition-colors duration-300`} />
                    ))}
                </div>

                <h2 className='flex font-semibold font-inter text-xl  text-gray-700'>{question}</h2>

                <div className='flex gap-2 h-36'>
                    {answers.map((answer, index) => (
                        <button
                            key={index}
                            onClick={() => handleAnswerClick(answer)}
                            className={`flex justify-center w-full h-full items-center p-4 font-inter text-md border border-gray-200 rounded-lg hover:bg-blue-500 hover:text-white-A700 ${selectedAnswer === answer ? 'bg-blue-600 text-white-A700' : 'bg-white-A700'} transform transition-transform duration-300 ease-in-out hover:scale-105`}
                        >
                            {answer}
                        </button>
                    ))}
                </div>
            </div>



            {index === 5 ? (
                <div className='flex justify-center w-full'>
                    <button
                        onClick={submitQuestion} // TODO: CHANGE THIS TO CALL SUBMIT ENDPOINT AND ROUTE TO DASHBOARD
                        className='flex justify-center w-[25%] h-12 items-center p-4 font-inter text-md border border-gray-200 rounded-lg bg-blue-500 hover:bg-blue-400 text-white-A700 transform transition-transform duration-300 ease-in-out active:scale-95'
                    >
                        Begin Simulation
                    </button>


                </div >
            ) : (
                <div className='flex justify-end w-full'>
                    <button
                        onClick={nextQuestion}
                        className='flex justify-center w-[25%] h-12 items-center p-4 font-inter text-md border border-gray-200 rounded-lg bg-blue-500 hover:bg-blue-400 text-white-A700 transform transition-transform duration-300 ease-in-out active:scale-95'
                    >
                        Next
                    </button>


                </div >
            )}

        </div >
    );
};

export default StartQuestion;
