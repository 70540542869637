import React, { useState, useEffect } from 'react';
import { formatUSD } from 'utils';
import Popup from 'reactjs-popup';
import TransferMoneyModal from '../TransferMoneyModal';
import PayCreditCardModal from '../PayCreditCardModal';

const typeDisplayNames = {
    'checking': 'CHECKING ACCOUNT',
    'savings': 'SAVINGS ACCOUNT',
    'credit_card': 'CREDIT CARD',
};

const AccountComponent = (props) => {
    const { id, name, numbers } = props;

    const displayName = typeDisplayNames[name] || 'Unknown Account';
    const balanceLabel = name === 'credit_card' ? 'Current Balance' : 'Available Balance';
    const buttonText = name === 'credit_card' ? 'Pay Credit Card' : 'Transfer Money';

    useEffect(() => {

    }, []);

    return (
        <div className='flex flex-col font-inter divide-y justify-start first-letter:w-full rounded-lg border'>
            <h1 className='p-4 text-gray-700 text-sm'>{displayName}</h1>

            <div className='flex w-full justify-between'>
                <div className='flex flex-col w-full p-4'>
                    <h2 className='text-xl font-semibold'>{formatUSD(numbers)}</h2>
                    <p className='text-sm'>{balanceLabel}</p>
                </div>

                <div className='flex w-full justify-between gap-2 p-4'>

                    <div className='flex flex-col gap-2'>
                        <h2>{formatUSD(500)}</h2>
                        <p>Earnings</p>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <h2>{formatUSD(500)}</h2>
                        <p>Earnings</p>
                    </div>

                </div>

                <div className='flex w-full justify-end items-center p-4'>
                    <Popup
                        trigger={
                            <button
                                className={`flex justify-center h-[80%] items-center p-4 font-inter font-semibold text-blue-500 text-md border border-blue-500 bg-white rounded-lg hover:bg-blue-100 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
                            >
                                {buttonText}
                            </button>
                        }
                        modal
                        nested
                    >
                        {(close) => (
                            <div className='modal rounded-lg'>
                                {name === 'credit_card' ? (
                                    <PayCreditCardModal onClose={close} />
                                ) : (
                                    <TransferMoneyModal onClose={close} />
                                )}
                            </div>
                        )}
                    </Popup>
                </div>
            </div>
        </div>
    );
};

export default AccountComponent;