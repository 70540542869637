import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Heading, Img, Text } from "../../components";
import Header from "../../components/Header";
import Sidebar1 from "../../components/Sidebar1";
import { TabPanel, TabList, Tab, Tabs } from "react-tabs";
import { useNavigate } from 'react-router-dom';
import TransferMoney from "../../components/TransferMoney";

export default function BudgetSimulatorThreePage() {
  const navigate = useNavigate();
  const [showTransfer, setShowTransfer] = useState(false);

  const handleTransferClick = () => {
    setShowTransfer(true);
  };

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-center bg-white-A700">
        <Sidebar1 />
        <div className="container-lg md:p-5">
          <div>
            <Header className="px-4 py-2" />
            <div className="bg-white-A700 p-4">
              <div className="pb-[296px] md:pb-5">
                <Tabs
                  className="flex flex-col gap-4 rounded-lg border border-solid border-blue_gray-100 pb-4"
                  selectedTabClassName=""
                  selectedTabPanelClassName="relative tab-panel--selected"
                >
                  <div className="flex flex-col px-4 pt-[15px]">
                    <div className="flex w-[98%] items-center justify-between gap-5 md:w-full sm:flex-col">
                      <div className="flex flex-col items-start pb-2 pl-4 pr-4">
                        <Heading size="11xl" as="h1" className="!font-poppins pt-4 !text-gray-900_05">
                          Budget Simulator
                        </Heading>
                        <div className="flex pt-4">
                          <Text as="p" className="tracking-[0.25px] !text-gray-800_01">
                            May 1, 2024
                          </Text>
                        </div>
                      </div>
                      <a target="_blank">
                        <Button
                          size="3xl"
                          variant="outline"
                          shape="round"
                          leftIcon={
                            <Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[16px] w-[16px]" />
                          }
                          className="min-w-[221px] gap-4 font-inter font-medium tracking-[0.50px] sm:pr-5" onClick={() => navigate('/budgetsimulatortwo')}
                        >
                          Back to simulation
                        </Button>
                      </a>
                    </div>
                    <TabList className="flex items-start gap-6 border-b border-solid border-blue_gray-100 px-8 sm:px-5">
                      <Tab className="font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01 py-2 mx-2" onClick={() => navigate('/budgetsimulatortwo')}>Calendar</Tab>
                      <Tab className="font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01 py-2 mx-2" style={{color: '#007BFF'}} onClick={() => navigate('/budgetsimulatorthree')}>
                        Accounts
                        <Img src="images/img_close_blue_a200.svg" alt="close" className="h-[14px]" />
                      </Tab>
                      
                      <Tab className="font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01 py-2 mx-2" onClick={() => navigate('/budgetsimulatorfour')}>
                        Statement
                      </Tab>
                    </TabList>
                  </div>
                  {[...Array(3)].map((_, index) => (
                    <TabPanel key={`tab-panel${index}`} className="absolute items-center">
                      <div className="w-full">
                        <div className="flex flex-col gap-4">
                        <div className="px-4">
                            <div className="rounded-lg border border-solid border-blue_gray-50_01">
                              <div className="flex border-b border-solid border-blue_gray-50_01 px-4 py-3">
                                <Heading size="4xl" as="p" className="!text-blue_gray-700">
                                  Checking Account
                                </Heading>
                              </div>
                              <div className="flex items-center justify-between gap-5 px-4 pb-4 pt-[15px] sm:flex-col">
                                <div className="flex flex-col items-start gap-0.5">
                                  <Heading size="13xl" as="h2">
                                    $5,625.50
                                  </Heading>
                                  <div className="flex">
                                    <Heading size="6xl" as="h6" className="!text-blue_gray-700">
                                      Current balance
                                    </Heading>
                                  </div>
                                </div>
                                <div className="flex self-end">
                                  <div className="flex flex-col items-start gap-[3px]">
                                    <div className="flex">
                                      <Heading size="6xl" as="h6">
                                        +$500.00
                                      </Heading>
                                    </div>
                                    <div className="flex">
                                      <Heading size="6xl" as="h6" className="!text-blue_gray-700">
                                        Earnings
                                      </Heading>
                                    </div>
                                  </div>
                                  <div className="flex flex-col mx-10 items-start gap-[3px]">
                                    <div className="flex">
                                      <Heading size="6xl" as="h6">
                                        -$5,125.50
                                      </Heading>
                                    </div>
                                    <div className="flex">
                                      <Heading size="6xl" as="h6" className="!text-blue_gray-700">
                                        Spendings
                                      </Heading>
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="3xl"
                                  shape="round"
                                  className="min-w-[164px] border border-solid border-blue-A200 font-inter font-medium tracking-[0.50px] !text-blue-A200 sm:px-5"
                                  onClick={() => setShowTransfer(true)}
                                >
                                  Transfer Money
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="px-4">
                            <div className="rounded-lg border border-solid border-blue_gray-50_01">
                              <div className="flex border-b border-solid border-blue_gray-50_01 px-4 py-3">
                                <Heading size="4xl" as="p" className="!text-blue_gray-700">
                                  Savings Account
                                </Heading>
                              </div>
                              <div className="flex items-center justify-between gap-5 px-4 pb-4 pt-[15px] sm:flex-col">
                                <div className="flex flex-col items-start gap-0.5">
                                  <Heading size="13xl" as="h2">
                                    $5,625.50
                                  </Heading>
                                  <div className="flex">
                                    <Heading size="6xl" as="h6" className="!text-blue_gray-700">
                                      Current balance
                                    </Heading>
                                  </div>
                                </div>
                                <div className="flex self-end">
                                  <div className="flex flex-col items-start gap-[3px]">
                                    <div className="flex">
                                      <Heading size="6xl" as="h6">
                                        +$500.00
                                      </Heading>
                                    </div>
                                    <div className="flex">
                                      <Heading size="6xl" as="h6" className="!text-blue_gray-700">
                                        Earnings
                                      </Heading>
                                    </div>
                                  </div>
                                  <div className="flex flex-col mx-10 items-start gap-[3px]">
                                    <div className="flex">
                                      <Heading size="6xl" as="h6">
                                        -$5,125.00
                                      </Heading>
                                    </div>
                                    <div className="flex">
                                      <Heading size="6xl" as="h6" className="!text-blue_gray-700">
                                        Spending
                                      </Heading>
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="3xl"
                                  shape="round"
                                  className="min-w-[164px] border border-solid border-blue-A200 font-inter font-medium tracking-[0.50px] !text-blue-A200 sm:px-5"
                                  onClick={() => setShowTransfer(true)}
                                >
                                  Transfer Money
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="px-4">
                            <div className="rounded-lg border border-solid border-blue_gray-50_01">
                              <div className="flex border-b border-solid border-blue_gray-50_01 px-4 py-3">
                                <Heading size="4xl" as="p" className="!text-blue_gray-700">
                                  Credit Card
                                </Heading>
                              </div>
                              <div className="flex items-center justify-between gap-5 px-4 pb-4 pt-[15px] sm:flex-col">
                                <div className="flex flex-col items-start gap-0.5">
                                  <Heading size="13xl" as="h2">
                                    $100.00
                                  </Heading>
                                  <div className="flex">
                                    <Heading size="6xl" as="h6" className="!text-blue_gray-700">
                                      Current balance
                                    </Heading>
                                  </div>
                                </div>
                                <div className="flex self-end">
                                  <div className="flex flex-col items-start gap-[3px]">
                                    <div className="flex">
                                      <Heading size="6xl" as="h6">
                                        $100.00
                                      </Heading>
                                    </div>
                                    <div className="flex">
                                      <Heading size="6xl" as="h6" className="!text-blue_gray-700">
                                        Available Credit
                                      </Heading>
                                    </div>
                                  </div>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="3xl"
                                  shape="round"
                                  className="min-w-[164px] border border-solid border-blue-A200 font-inter font-medium tracking-[0.50px] !text-blue-A200 sm:px-5"
                                >
                                  Pay Credit Card
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showTransfer && <TransferMoney onClose={() => setShowTransfer(false)} />}
    </>
  );
}
