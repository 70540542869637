import React from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, Img, Button } from "../../components";
import Header from "../../components/Header";
import { ReactTable } from "../../components/ReactTable";
import Sidebar1 from "../../components/Sidebar1";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from 'react-router-dom';
import { TabList, Tab } from "react-tabs";

const table7Data = [
  { tableheader: "Apr 8, 2024", tableheader1: "Paid for Lunch", tableheader2: "-$12", tableheader3: "Credit Card" },
  { tableheader: "Apr 3, 2024", tableheader1: "Weekly salary", tableheader2: "+$500", tableheader3: "Checking" },
  {
    tableheader: "Apr 1, 2024",
    tableheader1: "Transfer to savings account",
    tableheader2: "-$100",
    tableheader3: "Checking",
  },
];

export default function BudgetSimulatorFourPage() {
  const navigate = useNavigate();
  const table7Columns = React.useMemo(() => {
    const table7ColumnHelper = createColumnHelper();
    return [
      table7ColumnHelper.accessor("tableheader", {
        cell: (info) => (
          <div className="flex border-b border-solid border-white-A700">
            <Text size="7xl" as="p" className="!font-roboto pl-3 tracking-[0.50px]">
              {info?.getValue?.()}
            </Text>
          </div>
        ),
        header: (info) => (
          <Heading
            size="4xl"
            as="h3"
            className="relative right-2.5 flex items-center justify-start border-b border-solid border-white-A700 bg-white-A700 py-3 pl-6 pr-[35px] !text-blue_gray-700 sm:px-5"
          >
            Date
          </Heading>
        ),
        meta: { width: "314px" },
      }),
      table7ColumnHelper.accessor("tableheader1", {
        cell: (info) => (
          <Text
            size="7xl"
            as="p"
            className="flex items-center justify-start border-b border-solid border-white-A700 !font-roboto tracking-[0.50px] pl-6"
          >
            {info?.getValue?.()}
          </Text>
        ),
        header: (info) => (
          <div className="flex justify-start border-b border-solid border-white-A700 bg-white-A700 px-6 pb-[11px] pt-[13px] sm:px-5">
            <Heading size="4xl" as="h4" className="!text-blue_gray-700">
              Description
            </Heading>
          </div>
        ),
        meta: { width: "314px" },
      }),
      table7ColumnHelper.accessor("tableheader2", {
        cell: (info) => (
          <Text
            size="7xl"
            as="p"
            className="flex items-center justify-center border-b border-solid border-white-A700 pl-[7.45rem] !font-roboto tracking-[0.50px]"
          >
            {info?.getValue?.()}
          </Text>
        ),
        header: (info) => (
          <div className="flex justify-center border-b border-solid border-white-A700 bg-white-A700 px-6 pb-[11px] pt-3 sm:px-5">
            <Heading size="4xl" as="h5" className="!text-blue_gray-700">
              Amount
            </Heading>
          </div>
        ),
        meta: { width: "314px" },
      }),
      table7ColumnHelper.accessor("tableheader3", {
        cell: (info) => (
          <Text
            size="7xl"
            as="p"
            className="flex items-center justify-center border-b border-solid pl-[7.75rem] border-white-A700 !font-roboto tracking-[0.50px]"
          >
            {info?.getValue?.()}
          </Text>
        ),
        header: (info) => (
          <div className="flex justify-center border-b border-solid border-white-A700 bg-white-A700 px-6 py-3 sm:px-5">
            <Heading size="4xl" as="h6" className="!text-blue_gray-700">
              Source
            </Heading>
          </div>
        ),
        meta: { width: "314px" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-center bg-white-A700">
        <Sidebar1 />
        <div className="container-md md:p-5">
          <div>
            <Header className="px-4 py-2" />
            <div className="p-4">
              <div className="rounded-lg bg-white-A700">
                <div className="rounded-lg">
                  <div className="rounded-lg border border-solid border-blue_gray-100 pb-[198px] md:pb-5">
                    <div className="flex flex-col pb-4 pt-[15px]">
                      <div className="flex w-[98%] justify-between gap-5 md:w-full sm:flex-col">
                        <div className="flex flex-col items-start pb-2 pl-4 pr-4">
                          <Heading size="11xl" as="h1" className="m-2.5 !font-poppins !text-gray-900_05">
                            Budget Simulator
                          </Heading>
                          <div className="flex">
                            <Text as="p" className="m-2.5 tracking-[0.25px] !text-gray-800_01">
                              May 1, 2024
                            </Text>
                          </div>
                        </div>
                        <a target="_blank">
                          <div className="flex py-3">
                            <Button
                              size="3xl"
                              variant="outline"
                              shape="round"
                              leftIcon={
                                <Img src="images/img_arrowdown.svg" alt="arrow_down" className="h-[16px] w-[16px]" />
                              }
                              className="min-w-[221px] gap-4 font-inter font-medium tracking-[0.50px] sm:pr-5" onClick={() => navigate('/budgetsimulatortwo')}
                            >
                              Back to simulation
                            </Button>
                          </div>
                        </a>
                      </div>
                      <div className="flex border-b border-solid border-blue_gray-100 px-4">
                        <div className="flex w-[38%] md:w-full">
                        <TabList className="flex items-start gap-6 border-b border-solid border-blue_gray-100 px-8 sm:px-5">
                      <Tab className="font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01 py-2 mx-2" onClick={() => navigate('/budgetsimulatortwo')}>Calendar</Tab>
                      <Tab className="font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01 py-2 mx-2" onClick={() => navigate('/budgetsimulatorthree')}>
                        Accounts
                      </Tab>
                      <Tab className="font-roboto text-sm font-medium tracking-[0.10px] text-gray-800_01 py-2 mx-2" style={{color: '#007BFF'}} onClick={() => navigate('/budgetsimulatorfour')}>
                        Statement
                        <Img src="images/img_close_blue_a200.svg" alt="close" className="h-[16px]" />
                      </Tab>
                    </TabList>
                        </div>
                      </div>
                    </div>
                    <div className="px-4">
                      <div className="rounded-lg border border-solid border-blue_gray-50_01">
                        <div className="flex flex-col justify-center gap-2 rounded-lg border border-solid border-blue_gray-100 pb-[359px] md:pb-5">
                          <div className="flex border-b border-solid border-blue_gray-100 px-4 py-3">
                            <Heading size="4xl" as="h2" className="!text-blue_gray-700">
                              ALL TRANSACTIONS
                            </Heading>
                          </div>
                          <ReactTable
                            size="md"
                            variant="simple"
                            bodyProps={{ className: "" }}
                            headerProps={{ className: "border-blue_gray-50_01 border-b border-solid md:flex-col" }}
                            rowDataProps={{ className: "md:flex-col" }}
                            className="w-[98%]"
                            columns={table7Columns}
                            data={table7Data}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
