import { useState } from "react";
import { Text, Img, Button, Input } from "../../../../components";
import { useNavigate, useSearchParams } from "react-router-dom";
import 'react-phone-number-input/style.css'

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export default function ResetPasswordForm() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token")

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [error, setError] = useState("");

    const navigate = useNavigate();

    // handle submit event for the form
    const handleSubmit = (e) => {
        e.preventDefault();
        // validate email and passwords
        if (!email || !password) {
            setError("Please fill in all fields.");
        } else if (password !== confirmPassword) {
            setError("Passwords must match");
        } else {
            // clear error message
            setError("");
            // post data to the /register api

            fetch(API_ENDPOINT + "/resetPassword", {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    resetCode: token,
                    newPassword: password,
                }),
            })
                .then((data) => {
                    // handle success or error from the server
                    if (data.ok) {
                        setError("Successful Reset.");
                        navigate("/login");
                    }
                    else {
                        data.json().then((d) => {
                            if (d.detail !== undefined) {
                                setError(d.detail);
                            } else {
                                setError("There was an issue resetting the password. Contact support or try again.")
                            }
                        });
                    }
                })
                .catch((error) => {
                    // handle network error
                    console.error(error);
                    setError("There was an issue resetting the password. Contact support or try again.")
                });
        }
    };


    return <div className="flex max-w-[700px] w-[60%] flex-col gap-8 md:w-full">
        <form
            onSubmit={handleSubmit}>
            <div className="text-red-500 text-sm">
                {error !== "" ? error : ""}
            </div>
            <div className="flex flex-col gap-6">
                <div className="flex flex-col items-start gap-1.5">
                    <Text as="p" className="!font-medium !text-blue_gray-800_01">
                        <span className="text-blue_gray-800_01">Email</span>
                        <span className="text-red-500">*</span>
                    </Text>
                    <Input
                        required
                        shape="round"
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={setEmail}
                        placeholder={`you@company.com`}
                        className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                    />
                </div>

                <div className="flex gap-6 sm:flex-col">
                    <div className="flex w-full flex-col items-start gap-1.5 sm:w-full">
                        <Text as="p" className="!font-medium !text-blue_gray-800_01">
                            Password
                        </Text>
                        <Input
                        required
                            size="xl"
                            shape="round"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            id="password"
                            value={password}
                            onChange={setPassword}
                            suffix={<Img src={showPassword ? "/images/img_eye.svg" : "/images/img_eyeoff.svg"}
                                alt={showPassword ? "eye" : "eye-off"}
                                onClick={() => setShowPassword(!showPassword)}
                                className="h-[16px] w-[16px] cursor-pointer" />}
                            className="gap-[35px] self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                        />
                    </div>
                    <div className="flex w-full flex-col items-start gap-1.5 sm:w-full">
                        <Text as="p" className="!font-medium !text-blue_gray-800_01">
                            <span className="text-blue_gray-800_01">Confirm Password</span>
                            <span className="text-red-500">*</span>
                        </Text>
                        <Input
                            required
                            size="xl"
                            shape="round"
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            id="confirmPassword"
                            value={confirmPassword}
                            onChange={setConfirmPassword}
                            placeholder={`**********`}
                            suffix={<Img src={showConfirmPassword ? "/images/img_eye.svg" : "/images/img_eyeoff.svg"}
                                alt={showConfirmPassword ? "eye" : "eye-off"}
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                className="h-[16px] w-[16px] cursor-pointer" />}
                            className="gap-[35px] self-stretch border border-solid border-blue_gray-100_05"
                        />
                    </div>
                </div>

                <div className="flex gap-4 justify-between sm:flex-col">
                    <Button
                        size="5xl"
                        shape="round"
                        className="w-full border border-solid border-blue-A200 font-semibold shadow-sm sm:px-5"
                    >
                        Reset Password
                    </Button>
                </div>
            </div>
        </form>
    </div>

}
