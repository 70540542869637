import { apiClient } from "AuthService";

export const fetchClasses = async () => {
    const response = await apiClient('/api/Classroom', {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch classrooms" };
    }

    const data = await response.json();
    return data;
};

export const fetchCourses = async () => {
    const response = await apiClient('/api/Courses', {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch courses" };
    }

    const data = await response.json();
    return data;
};

export const fetchClassroomDetails = async (classroomId) => {
    const response = await apiClient(`/api/Classroom/${classroomId}`, {
        method: 'GET',
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch classroom details" };
    }

    const data = await response.json();
    return data;
};

export const createClassroom = async (classroomData) => {
    try {
        const response = await apiClient('/api/Classroom/New', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(classroomData),
        });

        if (!response.ok) {
            throw { status: response.status, message: "Failed to create classroom" };
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error creating classroom:', error);
        throw error;
    }
};

export const createShareCode = async (classroomId) => {
    try {
        const response = await apiClient(`/api/Classroom/${classroomId}/Tokens/New`, {
            method: 'POST',
        });

        if (!response.ok) {
            throw { status: response.status, message: "Failed to create a share code" };
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error creating share code:', error);
        throw error;
    }
};

export const addCourseToClassroom = async (classroomId, courseId) => {
    try {
        const response = await apiClient(`/api/Classroom/${classroomId}/AddCourse/${courseId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw { status: response.status, message: "Failed to add course to classroom" };
        }
    } catch (error) {
        console.error('Error adding course to classroom:', error);
        throw error;
    }
};

export const addTeacherToClassroom = async (classroomId, teacherId) => {
    try {
        const response = await apiClient(`/api/Classroom/${classroomId}/Teachers/${teacherId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw { status: response.status, message: "Failed to add teacher to classroom" };
        }
    } catch (error) {
        console.error('Error adding teacher to classroom:', error);
        throw error;
    }
};

export const addTeacherToClassroomByEmail = async (classroomId, teacherEmail) => {
    try {
        const urlEncodedEmail = encodeURIComponent(teacherEmail);
        const response = await apiClient(`/api/Classroom/${classroomId}/TeacherEmail/${urlEncodedEmail}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            return {
                success: true,
                status: response.status,
                message: 'Co-instructor added successfully',
            };
        } else {
            let errorMessage = 'Failed to add co-instructor. Please ensure the email is correct and try again.';
            try {
                const contentType = response.headers.get('Content-Type');
                if (contentType && contentType.includes('application/json')) {
                    const errorBody = await response.json();
                    errorMessage = errorBody.message || errorMessage;
                } else {
                    const errorText = await response.text();
                    errorMessage = errorText || errorMessage;
                }
            } catch (error) {
                console.error('Error parsing response:', error);
            }
            return {
                success: false,
                status: response.status,
                message: errorMessage,
            };
        }
    } catch (error) {
        console.error('Error adding teacher by email:', error);
        return {
            success: false,
            status: 500,
            message: error.message || 'An unexpected error occurred',
        };
    }
};

export const fetchStudentDetails = async (studentId) => {
    try {
        const response = await apiClient(`/api/Students/${studentId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw { status: response.status, message: "Failed to fetch student details" };
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching student details:', error);
        throw error;
    }
};

export const fetchAnalyticsData = async (studentId, courseId) => {
    try {
        const response = await apiClient(`/api/Students/${studentId}/Analytics/Course/${courseId}`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
            },
        });

        if (!response.ok) {
            throw { status: response.status, message: "Failed to fetch analytics data" };
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching analytics data:', error);
        throw error;
    }
};

export const fetchStudentProgress = async (courseId, studentId) => {
    const response = await apiClient(`/api/Students/${studentId}/Status?courseId=${courseId}`, {
        method: 'GET',
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch student progress" };
    }

    const data = await response.json();
    return data;
};

export const fetchCourseDetails = async (courseId) => {
    const response = await apiClient(`/api/Courses/${courseId}`, {
        method: 'GET',
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch course details" };
    }

    const data = await response.json();
    return data;
};

export const addAssignment = async (classroomId, assignmentData) => {
    const response = await apiClient(`/api/Classroom/${classroomId}/AddAssignment`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(assignmentData),
    });

    if (!response.ok) {
        throw new Error('Failed to add assignment');
    }

    // If there's no response body, return null or some success message
    return null;
};

export const fetchUnitDetails = async (unitId) => {
    const response = await apiClient(`/api/Courses/Units/${unitId}`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch unit details" };
    }

    const data = await response.json();
    return data;
};

export const fetchSectionDetails = async (sectionId) => {
    const response = await apiClient(`/api/Courses/Units/Sections/${sectionId}`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch section details" };
    }

    const data = await response.json();
    return data;
};

export const deleteStudent = async (studentId, ignoreExistingActivity) => {
    try {
        const response = await apiClient(`/DeleteStudent/${studentId}?ignoreExistingActivity=${ignoreExistingActivity}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
            },
        });

        if (!response.ok) {
            let errorMessage = 'Failed to delete student';
            let errorData = {};

            // Try to parse the error response
            try {
                const responseText = await response.text();
                if (responseText) {
                    errorData = JSON.parse(responseText);
                    errorMessage = errorData.message || errorMessage;
                }
            } catch (parseError) {
                console.error('Error parsing the error response:', parseError);
            }

            throw { status: response.status, message: errorMessage };
        }
    } catch (error) {
        console.error('Error deleting student:', error);
        throw error;
    }
};

export const fetchClassroomAnalytics = async (classroomId, courseId) => {
    const response = await apiClient(`/api/Classroom/${classroomId}/Analytics/Course/${courseId}`, {
        method: 'GET',
        headers: {
            accept: 'application/json',
        },
    });

    if (!response.ok) {
        throw { status: response.status, message: "Failed to fetch classroom analytics" };
    }

    const data = await response.json();
    return data;
};


export const fetchInstructors = async (classroomId) => {
    try {
        const response = await apiClient(`/api/Classroom/${classroomId}/Teachers`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
            },
        });

        if (!response.ok) {
            if (response.status === 401) {
                // Handle unauthorized access
                window.location.href = '/timeout'; // Or handle this in your apiClient
                return null;
            }
            throw new Error('Failed to fetch instructors');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching instructors:', error);
        throw error;
    }
};


export const fetchUnitTree = async (unitId, studentId) => {
    try {
        const response = await apiClient(`/api/Courses/Units/${unitId}/tree/${studentId}`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
            },
        });

        if (response.status === 401) {
            throw { status: 401, message: 'Unauthorized' };
        }

        if (!response.ok) {
            throw new Error('Failed to fetch unit tree');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching unit tree:', error);
        throw error;
    }
};

export const fetchUnits = async (courseId) => {
    try {
        const response = await apiClient(`/api/Courses/${courseId}/Units/`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
            },
        });

        if (response.status === 401) {
            throw { status: 401, message: 'Unauthorized' };
        }

        if (!response.ok) {
            throw new Error('Failed to fetch unit tree');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching unit tree:', error);
        throw error;
    }
};

export const fetchSectionContent = async (sectionId) => {
    try {
        const response = await apiClient(`/api/Courses/Units/Sections/${sectionId}`, {
            method: 'GET',
            headers: {
                accept: 'application/json',
            },
        });

        if (response.status === 401) {
            throw { status: 401, message: 'Unauthorized' };
        }

        if (!response.ok) {
            throw new Error(`Failed to fetch section with id ${sectionId}`);
        }

        const sectionData = await response.json();
        return sectionData;
    } catch (error) {
        console.error('Error fetching section content:', error);
        throw error;
    }
};

export const createStudents = async (classroomId, defaultUsername, numStudents) => {
    try {
        const response = await apiClient(`/api/Classroom/${classroomId}/CreateStudents`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                classroomId: classroomId,
                userName: defaultUsername,
                numStudents: numStudents,
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.detail || 'Failed to create student accounts.');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error creating student accounts:', error);
        throw error;
    }
};


export const createExcelFile = async (classroomId, responseData) => {
    try {
        const response = await apiClient(`/api/Classroom/${classroomId}/CreateExcelFile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            body: JSON.stringify(responseData),
        });

        if (!response.ok) {
            throw new Error('Failed to generate Excel file.');
        }

        const blob = await response.blob();
        return blob;
    } catch (error) {
        console.error('Error generating Excel file:', error);
        throw error;
    }
};

export const fetchCourseUnits = async (courseId) => {
    try {
        const data = await apiClient(`/api/Courses/${courseId}/Units`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json', // Adjust if your API expects a different format
            },
        });
        return data;
    } catch (error) {
        console.error('Error fetching course units:', error);
        throw error;
    }
};
