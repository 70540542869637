import React, { useEffect, useRef } from 'react';

const TopStoriesComponent = () => {
    const widgetContainerRef = useRef(null);

    useEffect(() => {
        const widgetConfig = {
            "feedMode": "all_symbols",
            "isTransparent": false,
            "displayMode": "regular",
            "width": "100%",
            "height": "100%",
            "colorTheme": "light",
            "locale": "en"
        };

        if (widgetContainerRef.current) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
            script.innerHTML = JSON.stringify(widgetConfig);
            widgetContainerRef.current.innerHTML = ''; // Clear the container
            widgetContainerRef.current.appendChild(script);
        }
    }, []);

    return (
        <div class="tradingview-widget-container" ref={widgetContainerRef}>
            <div class="tradingview-widget-container__widget"></div>
            <div class="tradingview-widget-copyright">
            </div>
        </div>
    );
};

export default TopStoriesComponent;
