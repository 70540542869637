// src/components/PayCreditCardModal.jsx
import React, { useState } from 'react';
import { formatUSD } from 'utils'; // Ensure this utility function is correctly imported

const PayCreditCardModal = ({ onClose }) => {
    // Hardcoded credit card details for demonstration
    const creditCardData = {
        nickname: "Visa Platinum",
        currentBalance: 235.00, // Positive value for owed amount
        remainingStatementBalance: 500.00,
        minimumPaymentDue: 35.00,
        dueDate: "2024-12-05",
    };

    // Define payment options
    const paymentOptions = [
        {
            label: "Minimum Payment",
            value: creditCardData.minimumPaymentDue,
        },
        {
            label: "Remaining Statement Balance",
            value: creditCardData.remainingStatementBalance,
        },
        {
            label: "Current Balance",
            value: creditCardData.currentBalance,
        },
        {
            label: "Other Amount",
            value: null, // Indicates a custom amount
        },
    ];

    const [selectedOption, setSelectedOption] = useState(null);
    const [paymentAmount, setPaymentAmount] = useState('');
    const [error, setError] = useState('');

    // Handler for selecting a payment option
    const handleOptionSelect = (option) => {
        setSelectedOption(option.label);
        if (option.value !== null) {
            setPaymentAmount(option.value);
            setError('');
        } else {
            setPaymentAmount(''); // Clear payment amount for custom input
        }
    };

    // Handler for custom payment amount input
    const handlePaymentChange = (e) => {
        setPaymentAmount(e.target.value);
        setError('');
    };

    // Handler for form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const amount = parseFloat(paymentAmount);

        // Basic validation
        if (isNaN(amount) || amount <= 0) {
            setError('Please enter a valid payment amount.');
            return;
        }

        // Additional validation for specific options
        if (selectedOption === "Minimum Payment" && amount < creditCardData.minimumPaymentDue) {
            setError(`Payment must be at least ${formatUSD(creditCardData.minimumPaymentDue)}.`);
            return;
        }

        if (selectedOption === "Remaining Statement Balance" && amount > creditCardData.remainingStatementBalance) {
            setError(`Payment cannot exceed the remaining statement balance of ${formatUSD(creditCardData.remainingStatementBalance)}.`);
            return;
        }

        if (selectedOption === "Current Balance" && amount > creditCardData.currentBalance) {
            setError(`Payment cannot exceed the current balance of ${formatUSD(creditCardData.currentBalance)}.`);
            return;
        }

        // Proceed with the payment logic
        console.log(`Paying ${formatUSD(amount)} towards ${creditCardData.nickname}`);
        alert(`Successfully initiated payment of ${formatUSD(amount)} towards ${creditCardData.nickname}.`);
        onClose(); // Close the modal
    };

    return (
        <div className='flex flex-col p-6 gap-4 rounded-lg w-full'>

            <div className='flex flex-col gap-2 '>
                <h1 className='text-2xl font-semibold'>Pay Credit Card</h1>
                <p className='text-gray-600'>Manage your credit card payments below.</p>
            </div>


            {/* Credit Card Details */}
            <div className='flex flex-col gap-2 mb-6'>
                <div className='flex justify-between'>
                    <span className='font-medium text-gray-700'>Credit Card:</span>
                    <span className='text-gray-700'>{creditCardData.nickname}</span>
                </div>
                <div className='flex justify-between'>
                    <span className='font-medium text-gray-700'>Current Balance:</span>
                    <span className='text-green-500'>{formatUSD(creditCardData.currentBalance)}</span>
                </div>
                <div className='flex justify-between'>
                    <span className='font-medium text-gray-700'>Remaining Statement Balance:</span>
                    <span className='text-green-500'>{formatUSD(creditCardData.remainingStatementBalance)}</span>
                </div>
                <div className='flex justify-between'>
                    <span className='font-medium text-gray-700'>Minimum Payment Due:</span>
                    <span className='text-red-500'>{formatUSD(creditCardData.minimumPaymentDue)}</span>
                </div>
                <div className='flex justify-between'>
                    <span className='font-medium text-gray-700'>Due Date:</span>
                    <span className='text-gray-700'>{creditCardData.dueDate}</span>
                </div>
            </div>

            {/* Payment Options */}
            <div className='flex flex-col gap-2 mb-6'>
                <span className='font-medium text-gray-700 mb-2'>Select Payment Option:</span>
                {paymentOptions.map((option, index) => (
                    <button
                        key={index}
                        type="button"
                        onClick={() => handleOptionSelect(option)}
                        className={`p-2 border rounded-md text-left ${selectedOption === option.label
                            ? 'bg-blue-500 text-white-A700'
                            : 'bg-white-A700 text-blue-500 hover:bg-blue-100'
                            } transition-colors duration-200`}
                    >
                        {option.label} {option.value !== null && `(${formatUSD(option.value)})`}
                    </button>
                ))}
            </div>

            {/* Payment Form */}
            <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
                {/* Payment Amount Input */}
                <div className='flex flex-col'>
                    <label htmlFor='payment-amount' className='mb-2 font-medium text-gray-700'>Payment Amount:</label>

                    <div className='p-4 rounded-lg border'>
                        <input
                            type='number'
                            step='1'
                            id='payment-amount'
                            value={paymentAmount}
                            onChange={handlePaymentChange}
                            placeholder='Enter amount to pay'
                            className={`p-2 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400 ${selectedOption !== "Other Amount" ? 'bg-gray-100 cursor-not-allowed' : ''
                                }`}
                            min='0'
                            required
                            disabled={selectedOption !== "Other Amount"}
                        />
                    </div>
                </div>

                {/* Error Message */}
                {error && <p className='text-red-500'>{error}</p>}

                {/* Buttons */}
                <div className='flex w-full gap-4'>
                    {/* Back Button */}
                    <button
                        type="button"
                        onClick={onClose}
                        className={`flex w-[30%] justify-center items-center p-4 font-inter font-semibold text-blue-500 text-md border border-blue-500 bg-white rounded-lg hover:bg-blue-100 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
                    >
                        Back
                    </button>

                    {/* Pay Button */}
                    <button
                        type="submit"
                        className={`flex w-[70%] justify-center items-center p-4 font-inter font-semibold bg-blue-500 text-white-A700 text-md border rounded-lg hover:bg-blue-600 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
                    >
                        Pay
                    </button>
                </div>
            </form>
        </div>
    );

};

export default PayCreditCardModal;
