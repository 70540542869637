
import { apiClient } from "AuthService";

export const fetchStatus = async (classroomId) => {
    try {
        const response = await apiClient(`/api/StartupSim/${classroomId}/Status`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
            },
        });

        console.log('response', response.data);

        return response.json();
    } catch (error) {
        const message = error.response?.data?.message || "Failed to fetch status";
        console.error('Error fetching status:', message);
        throw { status: error.response?.status, message };
    }
};

export const postSettings = async (classroomId, settingData) => {
    try {
        const response = await apiClient(`/api/StartupSim/${classroomId}/SetOptions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(settingData)
        });

        return response.data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to post settings";
        console.error('Error posting settings:', message);
        throw { status: error.response?.status, message };
    }
};

export const postBeginStartup = async (studentId, startupType) => {
    try {
        const response = await apiClient(`/api/StartupSim/${studentId}/Begin/${startupType}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to begin startup";
        console.error('Error beginning startup:', message);
        throw { status: error.response?.status, message };
    }
};

export const fetchResumeStartup = async (studentId) => {
    try {
        const response = await apiClient(`/api/StartupSim/${studentId}/Resume`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
            },
        });

        const data = response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to fetch resume startup data";
        console.error('Error fetching resume startup data:', message);
        throw { status: error.response?.status, message };
    }
};

export const postContinueStartup = async (studentId, decision) => {
    try {
        const response = await apiClient(`/api/StartupSim/${studentId}/Continue/${decision}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to continue startup";
        console.error('Error continuing startup:', message);
        throw { status: error.response?.status, message };
    }
};