import React, { useEffect, useState } from "react";
import { Heading, Text, Img, Button } from "../../components";
import { fetchAssignments, fetchStudentDetails, fetchSection } from "StudentAPIService";
import { useNavigate } from "react-router-dom";

const DashboardCalendar = ({ studentId }) => {
    const [assignments, setAssignments] = useState([]);
    const [currentWeek, setCurrentWeek] = useState([]);
    const [selectedDay, setSelectedDay] = useState(null);
    const [sections, setSections] = useState({});
    const [classrooms, setClassrooms] = useState({});
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (studentId) {
                try {
                    setLoading(true);

                    // Fetch both assignments and student info
                    const [assignments, studentInfo] = await Promise.all([
                        fetchAssignments(studentId),
                        fetchStudentDetails(studentId),
                    ]);

                    setAssignments(assignments);

                    // Extract classrooms from the student info and store them
                    const classroomsData = studentInfo.courses.reduce((acc, course) => {
                        acc[course.class.id] = course.class;
                        return acc;
                    }, {});

                    setClassrooms(classroomsData);

                    // Fetch section data for each assignment
                    const sectionPromises = assignments.map((assignment) => fetchSection(assignment.sectionId));
                    const sectionResults = await Promise.all(sectionPromises);

                    const sectionsData = sectionResults.reduce((acc, section) => {
                        acc[section.id] = section;
                        return acc;
                    }, {});

                    setSections(sectionsData);

                    // Set up the current week and select today's date
                    const today = new Date();
                    const currentDayOfWeek = today.getDay(); // 0-6 (Sun-Sat)
                    const firstDayOfWeek = new Date(today);
                    firstDayOfWeek.setDate(today.getDate() - currentDayOfWeek);

                    const week = Array.from({ length: 7 }, (_, i) => {
                        const date = new Date(firstDayOfWeek);
                        date.setDate(firstDayOfWeek.getDate() + i);
                        return date;
                    });

                    setCurrentWeek(week);
                    setSelectedDay(today);
                } catch (error) {
                    if (error.message === '401') { // If you throw or handle the 401 error with a specific message
                        navigate('/timeout'); // Redirect to timeout page
                    } else {
                        console.error("Error fetching data:", error);
                    }
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [studentId]);

    const getAssignmentsForDay = (date) => {
        return assignments.filter(
            (assignment) => new Date(assignment.dueUTC).toDateString() === date.toDateString()
        );
    };

    return (
        <div className="flex flex-col font-inter divide-y w-full h-full bg-white-A700 rounded-lg">
            <h1 className="flex w-full font-semibold p-4">
                Upcoming Events
            </h1>

            <div className="flex flex-col w-full h-full">

                <div className="flex justify-between w-full p-4">
                    {currentWeek.map((date, index) => (
                        <div key={index} className="flex flex-col items-center justify-evenly gap-2">
                            <Text size="4xl" as="p">
                                {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][date.getDay()]}
                            </Text>

                            <button
                                onClick={() => setSelectedDay(date)}
                                className={`flex flex-col items-center justify-center w-8 h-8 rounded-full ${selectedDay && selectedDay.toDateString() === date.toDateString()
                                    ? "bg-blue-200"
                                    : "bg-white-A700 hover:bg-gray-200"
                                    }`}
                            >
                                <Text size="7xl" as="p">
                                    {date.getDate()}
                                </Text>
                            </button>
                        </div>
                    ))}
                </div>

                <div className="flex flex-col h-full overflow-y-auto p-4">
                    {selectedDay && (
                        <div className="flex flex-col gap-2 grow rounded-lg border border-solid border-blue_gray-100 p-4">
                            <Text size="4xl" as="p" className="!text-gray-700">
                                {selectedDay.toLocaleDateString(undefined, {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })}
                            </Text>

                            {loading ? (
                                <Text size="4xl" as="p" className="!text-gray-600">
                                    Loading assignments...
                                </Text>
                            ) : (
                                <>
                                    {getAssignmentsForDay(selectedDay).length === 0 ? (
                                        <Text size="4xl" as="p" className="!text-gray-600">
                                            No assignments due today.
                                        </Text>
                                    ) : (
                                        getAssignmentsForDay(selectedDay).map((assignment) => {
                                            const section = sections[assignment.sectionId];
                                            const classroom = classrooms[assignment.classroomId];
                                            return (
                                                <div
                                                    key={assignment.id}
                                                    className="mt-1.5 flex items-center justify-left self-stretch rounded-md border border-solid border-gray-300 bg-gray-50_03 p-2.5"
                                                >
                                                    <div className="flex flex-col items-start gap-1.5">
                                                        <Heading size="6xl" as="h6" className="!text-blue_gray-900_02">
                                                            {section ? section.name : "Loading..."}
                                                        </Heading>
                                                        <Text size="2xl" as="p" className="!font-normal !text-gray-700">
                                                            Due: {new Date(assignment.dueUTC).toLocaleDateString(undefined, {
                                                                weekday: 'long',
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric',
                                                            })} at {new Date(assignment.dueUTC).toLocaleTimeString()}
                                                        </Text>
                                                        <Text size="xl" as="p" className="!font-normal !text-gray-600">
                                                            Classroom: {classroom ? classroom.name : "Loading..."}
                                                        </Text>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>

            </div>
        </div>
    );
};

export default DashboardCalendar;


/* 

<div className="flex flex-col h-[300px] border border-green-500 overflow-y-auto"> {/* Added a fixed height and overflow for scroll 
{selectedDay && (
    <div className="flex flex-col gap-2 rounded-lg border border-solid border-blue_gray-100 p-4">
        <Text size="4xl" as="p" className="!text-gray-700">
            {selectedDay.toLocaleDateString(undefined, {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            })}
        </Text>

        {loading ? (
            <Text size="4xl" as="p" className="!text-gray-600">
                Loading assignments...
            </Text>
        ) : (
            <>
                {getAssignmentsForDay(selectedDay).length === 0 ? (
                    <Text size="4xl" as="p" className="!text-gray-600">
                        No assignments due today.
                    </Text>
                ) : (
                    getAssignmentsForDay(selectedDay).map((assignment) => {
                        const section = sections[assignment.sectionId];
                        const classroom = classrooms[assignment.classroomId];
                        return (
                            <div
                                key={assignment.id}
                                className="mt-1.5 flex items-center justify-left self-stretch rounded-md border border-solid border-gray-300 bg-gray-50_03 p-2.5"
                            >
                                <div className="flex flex-col items-start gap-1.5">
                                    <Heading size="6xl" as="h6" className="!text-blue_gray-900_02">
                                        {section ? section.name : "Loading..."}
                                    </Heading>
                                    <Text size="2xl" as="p" className="!font-normal !text-gray-700">
                                        Due: {new Date(assignment.dueUTC).toLocaleDateString(undefined, {
                                            weekday: 'long',
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })} at {new Date(assignment.dueUTC).toLocaleTimeString()}
                                    </Text>
                                    <Text size="xl" as="p" className="!font-normal !text-gray-600">
                                        Classroom: {classroom ? classroom.name : "Loading..."}
                                    </Text>
                                </div>
                            </div>
                        );
                    })
                )}
            </>
        )}
    </div>
)}
</div>
*/