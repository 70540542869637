import React from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Heading, Button, CheckBox, Input } from "../../../../components";
import RegisterOneRow from "../../../../components/RegisterOneRow";
import { useNavigate } from 'react-router-dom';
import TopNavLogin from "components/TopNavLogin";
import RegisterStudentForm from "./RegisterStudentForm";
import LoginIPadStudent from "components/LoginIPadStudent";
import LoginSideGraphic from "components/LoginSideGraphic";


export default function RegisterOnePage() {
  return (
    <>
      <Helmet>
        <title>Register | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex h-screen overscroll-none">
        <div className="w-[75%] bg-white-A700 md:w-full relative md:absolute">
          <TopNavLogin className="absolute top-0 left-0 right-0 z-10 md:relative" type={"register"} />

          <div className="flex-1 flex flex-col gap-16 h-full justify-center items-center overflow-auto sm:pt-4">
            <div className="gap-3 flex flex-col items-center">
              <Heading size="12xl" as="h1" className="!text-gray-900_05 flex gap-2 items-center">
                Student Registration
              </Heading>
              <Text as="p" className="w-full text-center font-semibold  !text-gray-900_05">
                A class code is required to register. If you do not have a class code, please contact your teacher.{" "}
              </Text>
              <Text as="p" className="w-full text-center font-semibold  !text-gray-900_05">
                <span className="text-gray-700_01">
                  A class code is an 8-character, alphanumeric code that is provided by your teacher and will look like
                </span>
                <span className="font-bold italic text-gray-700_01"> GFRW-9KLH.</span>
              </Text>

            </div>



            <div className="flex items-center justify-center w-[80%] gap-16 md:w-full md:flex-col">
              <RegisterStudentForm />
            </div>

          </div>
        </div>

        <div className="w-[25%] md:hidden">
          <LoginSideGraphic />
        </div>
      </div>
    </>
  );
}
