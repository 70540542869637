import React, { useEffect, useRef, memo } from 'react';

function FundamentalData({ stockInfo }) {
    const container = useRef(null);

    useEffect(() => {
        container.current.innerHTML = '';
        if (stockInfo) {
            const script = document.createElement('script');
            script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-financials.js';
            script.type = 'text/javascript';
            script.async = true;
            script.innerHTML = JSON.stringify({
                "isTransparent": false,
                "largeChartUrl": "",
                "displayMode": "regular",
                "width": "100%",
                "height": "100%",
                "colorTheme": "light",
                "symbol": `${stockInfo.profile?.exchange}:${stockInfo.profile?.symbol}`,
                "locale": "en"
            });
            container.current.appendChild(script);
        }
    }, [stockInfo.symbol]);

    return (
        <div className='h-full w-full'>
            <div className="tradingview-widget-container" ref={container}>
                <div className="tradingview-widget-container__widget"></div>
            </div>
        </div>
    );
}

export default memo(FundamentalData);