import React, { useEffect } from "react";

export default function TickerTape({ stocks }) {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
    script.innerHTML = JSON.stringify({
      "symbols": [
        {
          "proName": "FOREXCOM:SPXUSD",
          "title": "S&P 500 Index"
        },
        {
          "proName": "NASDAQ:AAPL",
          "title": "Apple Inc."
        },
        {
          "proName": "NASDAQ:NVDA",
          "title": "NVIDIA Corporation"
        },
        {
          "proName": "NASDAQ:MSFT",
          "title": "Microsoft Corporation"
        },
        {
          "proName": "NASDAQ:GOOGL",
          "title": "Alphabet Inc."
        },
        {
          "proName": "NASDAQ:META",
          "title": "Meta Platforms Inc."
        },
        {
          "proName": "NYSE:ORCL",
          "title": "Oracle Corporation"
        },
        {
          "proName": "NASDAQ:NFLX",
          "title": "Netflix, Inc."
        },
        {
          "proName": "NASDAQ:AMZN",
          "title": "Amazon.com, Inc."
        },
        {
          "proName": "NYSE:WMT",
          "title": "Walmart Inc."
        },
        {
          "proName": "NASDAQ:COST",
          "title": "Costco Wholesale Corporation"
        },
        {
          "proName": "NYSE:HD",
          "title": "The Home Depot, Inc."
        },
        {
          "proName": "NYSE:LLY",
          "title": "Eli Lilly and Company"
        },
        {
          "proName": "NYSE:JNJ",
          "title": "Johnson & Johnson"
        },
        {
          "proName": "NYSE:PG",
          "title": "The Procter & Gamble Company"
        },
        {
          "proName": "NYSE:NKE",
          "title": "NIKE, Inc."
        },
        {
          "proName": "NYSE:KO",
          "title": "The Coca-Cola Company"
        },
        {
          "proName": "NYSE:XOM",
          "title": "Exxon Mobil Corporation"
        },
        {
          "proName": "NYSE:CVX",
          "title": "Chevron Corporation"
        },
        {
          "proName": "NASDAQ:TSLA",
          "title": "Tesla, Inc."
        },
        {
          "proName": "NYSE:MCD",
          "title": "McDonald's Corporation"
        },
        {
          "proName": "NASDAQ:CMCSA",
          "title": "Comcast Corporation"
        },
        {
          "proName": "NASDAQ:SBUX",
          "title": "Starbucks Corporation"
        },
        {
          "proName": "NYSE:V",
          "title": "Visa Inc."
        },
        {
          "proName": "NYSE:MA",
          "title": "Mastercard Incorporated"
        },
        {
          "proName": "NASDAQ:TMUS",
          "title": "T-Mobile US, Inc."
        },
        {
          "proName": "NYSE:VZ",
          "title": "Verizon Communications Inc."
        },
        {
          "proName": "NYSE:T",
          "title": "AT&T Inc."
        },
        {
          "proName": "NYSE:CMG",
          "title": "Chipotle Mexican Grill, Inc."
        },
        {
          "proName": "NYSE:BRK.B",
          "title": "Berkshire Hathaway Inc."
        },
        {
          "proName": "NYSE:JPM",
          "title": "JPMorgan Chase & Co."
        },
        {
          "proName": "NYSE:BAC",
          "title": "Bank of America Corporation"
        },
        {
          "proName": "NYSE:WFC",
          "title": "Wells Fargo & Company"
        },
        {
          "proName": "NYSE:C",
          "title": "Citigroup Inc."
        },
        {
          "proName": "NYSE:GS",
          "title": "The Goldman Sachs Group, Inc."
        }
      ],
      "showSymbolLogo": true,
      "isTransparent": false,
      "displayMode": "regular",
      "colorTheme": "light",
      "locale": "en"
    });
    const container = document.querySelector('.tradingview-widget-container__widget');
    container.appendChild(script);
  }, []);

  return (
    <div class="tradingview-widget-container" className="pointer-events-none">
      <div class="tradingview-widget-container__widget"></div>
    </div>
  );
}
