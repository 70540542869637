import React from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, Img } from "../../../../components";
import RegisterColumniAmA from "../../../../components/RegisterColumniAmA";
import { useNavigate } from 'react-router-dom';
import TopNavLogin from "components/TopNavLogin";
import LoginSideGraphic from "components/LoginSideGraphic";

export default function RegisterPage() {
  const navigate = useNavigate();

  const handleRegisterClickStudent = () => {
    navigate('/register/student');
  };
  const handleRegisterClickTeacher = () => {
    navigate('/register/teacher');
  };
  const handleLoginClickLogin = () => {
    navigate('/login');
  };

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>

      <div className="flex h-screen overscroll-none md:overflow-auto m-0">
        <div className="w-[75%] bg-white-A700 md:w-full relative md:absolute">
          <TopNavLogin className="absolute top-0 left-0 right-0 z-10 md:relative" type={"register"}/>

          <div className="flex-1 flex flex-col gap-16 h-full justify-center items-center">
            <div className="gap-3 flex flex-col">
              <Heading size="12xl" as="h1" className="!text-gray-900_05 flex gap-2 items-center">
                Get Started With <span><Img src="/images/img_intertwined_text.svg" alt="headerlogo" className="h-[27px] object-contain" /></span>
              </Heading>
              <Text as="p" className="w-full text-center font-semibold  !text-gray-900_05">
                Take Control of Your Finances — Achieve Financial Literacy Today
              </Text>
              <Text as="p" className="w-full text-center font-semibold  !text-gray-900_05">
                It all begins with <span className="font-bold">you</span>.
              </Text>

            </div>



            <div className="flex w-[80%] gap-16 md:w-full md:flex-col">
              <RegisterColumniAmA
                userimage="/images/img_avatars_3d_avatar_26.png"
                label="Student"
                header="Class code required. Contact your teacher for more information."
                onClick={handleRegisterClickStudent}
              />
              <RegisterColumniAmA
                userimage="/images/img_avatars_3d_avatar_20.png"
                label="Teacher"
                header="Create your first classroom here."
                onClick={handleRegisterClickTeacher}
              />
            </div>

          </div>
        </div>

        <div className="w-[25%] md:hidden">
          <LoginSideGraphic />
        </div>
      </div>
    </>
  );
}
