import React, { useState } from 'react';
import Chart from 'react-apexcharts';

// Utility function to round to the nearest tenth
const roundToTenth = (num) => {
    return Math.round(num * 10) / 10;
};

// Utility function to truncate strings
const truncate = (str, maxLength) => {
    return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
};

const TimeCompletionChart = ({ analyticsData }) => {
    // State to track the selected module and dropdown visibility
    const [selectedModuleIndex, setSelectedModuleIndex] = useState(0);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // Add a check to ensure `analyticsData` is available
    if (!analyticsData || analyticsData.length === 0) {
        return <div>Loading chart data...</div>;
    }

    // Extract the selected module
    const selectedModule = analyticsData[selectedModuleIndex];
    // Truncate section names if they are too long
    const truncatedSections = selectedModule.sections.map((section) => truncate(section.sectionName, 15)); // Adjust the max length as needed
    // Round the time data for the chart
    const times = selectedModule.sections.map((section) => roundToTenth(section.completedSectionInMinutes));

    const chartOptions = {
        chart: {
            type: 'bar',
            toolbar: {
                show: true,
                tools: {
                    download: true, // Enable download button
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
        },
        xaxis: {
            categories: truncatedSections, // Use truncated section names
            title: {
                text: 'Sections',
            },
            labels: {
                rotate: -45, // Rotate labels for better visibility if many sections
                style: {
                    fontSize: '12px',
                },
            },
        },
        yaxis: {
            title: {
                text: 'Time (Minutes)',
            },
            labels: {
                formatter: (value) => roundToTenth(value), // Round y-axis labels
                style: {
                    fontSize: '12px',
                },
            },
        },
        tooltip: {
            y: {
                formatter: (value) => `${roundToTenth(value)} min`, // Show rounded time in tooltip
            },
            x: {
                formatter: (_, { dataPointIndex }) => selectedModule.sections[dataPointIndex].sectionName // Show full section name in tooltip
            }
        },
        grid: {
            show: true,
            yaxis: {
                lines: {
                    show: true,
                }
            }
        },
        title: {
            text: `Time Spent on ${selectedModule.unitName}`,
            align: 'left',
        },
        dataLabels: {
            enabled: true,
            formatter: (value) => `${roundToTenth(value)} min`, // Round data labels
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '60%',
            }
        }
    };

    const chartSeries = [
        {
            name: 'Time Spent (Minutes)',
            data: times, // Use the rounded times array
        },
    ];

    // Handle module selection
    const handleModuleChange = (index) => {
        setSelectedModuleIndex(index);
        setIsDropdownOpen(false); // Close dropdown after selection
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev); // Toggle dropdown visibility
    };

    return (
        <div className="relative">
            {/* Dropdown to select the module */}
            <button
                onClick={toggleDropdown}
                className="flex items-center justify-between px-4 py-2 text-gray-700 bg-white-A700 border border-gray-300 rounded-md shadow-sm focus:outline-none w-150 mb-4"
            >
                <span className="">
                    {analyticsData[selectedModuleIndex].unitName} {/* Selected option */}
                </span>
                <svg
                    className={`w-4 h-4 transform ${isDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
            </button>

            {/* Dropdown menu */}
            {isDropdownOpen && (
                <div className="absolute z-10 w-[60%] mt-2 bg-white-A700 border border-gray-300 rounded-lg shadow-md">
                    {analyticsData.map((module, index) => (
                        <div
                            key={index}
                            onClick={() => handleModuleChange(index)}
                            className="flex items-center rounded-lg p-4 text-gray-700 cursor-pointer bg-white-A700 hover:bg-gray-100"
                        >
                            {module.unitName}
                        </div>
                    ))}
                </div>
            )}

            {/* Chart */}
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height="320"
            />
        </div>
    );
};

export default TimeCompletionChart;
