import React from "react";
import { Helmet } from "react-helmet";
import { Img, Text } from "../../components";
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar1 from "components/Sidebar1";
import HomeDate from "components/HomeDate";
import StartupSimSettings from "components/StartupSimSettings";

export default function SimulatorSettings() {
    const { classroomID } = useParams();

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(`/classroom/${classroomID}`);
    };

    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Edit settings for startup simulator" />
            </Helmet>

            <div className="flex h-screen overflow-hidden">
                <Sidebar1 />

                <div className="flex flex-col w-full text-left">
                    <HomeDate />

                    <button className="flex shadow-md p-4" onClick={handleBackClick}>
                        <Img
                            src="/images/img_arrow_left_blue_a200.svg"
                            alt="arrowleft"
                            className="relative right-px"
                        />
                        <Text as="p" className="text-blue-500 cursor-pointer justify-start ">
                            Back
                        </Text>
                    </button>

                    <StartupSimSettings />
                </div>
            </div>
        </>
    );
}
