import React, { useEffect, useContext, useState } from "react";
import Popup from "reactjs-popup";
import { Helmet } from "react-helmet";
import StudentSidebar from "components/StudentSidebar";
import { useNavigate } from "react-router-dom";
import { UserContext } from "UserContext";
import BalancesBar from "components/BudgetSimulatorComponents/BalancesBar";
import { formatDate } from "utils";
import LoadingAnimation from "components/LoadingAnimation";
import DashboardTabs from "components/BudgetSimulatorComponents/DashboardTabs";
import DashboardGaugeChart from "components/BudgetSimulatorComponents/DashboardGaugeChart";
import MonthlySpendingChart from "components/BudgetSimulatorComponents/MonthlySpendingChart";
import OptionsComponent from "components/BudgetSimulatorComponents/OptionsComponent";
import UpcomingEvents from "components/BudgetSimulatorComponents/UpcomingEvents";
import { fetchStudentId } from "SimulatorAPIService";
import {
    fetchStudentBudgetSimStatus,
    fetchBudgetSimProgress,
    postContinueBudget,
} from "BudgetSimulatorAPIService";

const fakesimulationData = {
    sessionBalances: {
        checkingAccount: "1000.50",
        savingsAccount: "654323.21",
        creditCard: "-235",
        netCash: "250",
    },
    wellnessMeter: "",
    creditScore: "",
    monthlySpending: {
        food: "",
        housing: "",
        travel: "",
        fun: "",
        other: "",
    },
    allExpenses: {},
    currentDate: "2024-11-27T14:30:00+00:00",
    latestDate: "2024-11-27T14:30:00+00:00", // Added latestDate
    progressRecords: [], // Added to prevent errors
};

export default function BudgetSimulatorDashboard() {
    const navigate = useNavigate();
    const userInfo = useContext(UserContext);
    const [canStart, setCanStart] = useState(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [simulationData, setSimulationData] = useState(fakesimulationData);
    const [studentId, setStudentId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [events, setEvents] = useState([]);

    const getNthWeekdayOfMonth = (year, month, weekday, nth) => {
        const firstDayOfMonth = new Date(year, month, 1);
        const firstWeekday = new Date(
            firstDayOfMonth.setDate(
                firstDayOfMonth.getDate() + ((weekday - firstDayOfMonth.getDay() + 7) % 7)
            )
        );
        return new Date(firstWeekday.setDate(firstWeekday.getDate() + (nth - 1) * 7));
    };

    const adjustForWeekday = (date) => {
        const dayOfWeek = date.getDay();
        if (dayOfWeek === 6) {
            date.setDate(date.getDate() + 2);
        } else if (dayOfWeek === 0) {
            date.setDate(date.getDate() + 1);
        }
        return date;
    };

    const generateRecurringEvents = (currentDay) => {
        const events = [];
        let today = new Date(currentDay);

        if (isNaN(today.getTime())) {
            console.warn("Invalid currentDay:", currentDay);
            today = new Date("2024-11-27T14:30:00+00:00");
        }

        const year = today.getFullYear();

        for (let month = 0; month < 12; month++) {
            events.push(
                {
                    title: "Rent",
                    start: adjustForWeekday(new Date(year, month, 1))
                        .toISOString()
                        .slice(0, 10),
                    description: "Monthly rent payment",
                    classNames: [
                        "bg-red-500 border border-red-500 text-white-A700 font-inter font-semibold",
                    ],
                },
                {
                    title: "Payday",
                    start: getNthWeekdayOfMonth(year, month, 5, 1)
                        .toISOString()
                        .slice(0, 10),
                    description: "First payday of the month",
                    classNames: [
                        "bg-green-500 border border-green-500 text-white-A700 font-inter font-semibold",
                    ],
                },
                {
                    title: "Payday",
                    start: getNthWeekdayOfMonth(year, month, 5, 3)
                        .toISOString()
                        .slice(0, 10),
                    description: "Second payday of the month",
                    classNames: [
                        "bg-green-500 border border-green-500 text-white-A700 font-inter font-semibold",
                    ],
                },
                {
                    title: "Utilities",
                    start: adjustForWeekday(new Date(year, month, 10))
                        .toISOString()
                        .slice(0, 10),
                    description: "Monthly utilities bill",
                    classNames: [
                        "bg-orange-500 border border-orange-500 text-white-A700 font-inter font-semibold",
                    ],
                },
                {
                    title: "Groceries",
                    start: new Date(year, month, 15).toISOString().slice(0, 10),
                    description: "Budget for monthly groceries",
                    classNames: [
                        "bg-purple-500 border border-purple-500 text-white-A700 font-inter font-semibold",
                    ],
                }
            );
        }

        return events;
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const id = await fetchStudentId();
                if (!id) {
                    navigate("/timeout");
                } else {
                    setStudentId(id);
                    const studentStatus = await fetchStudentBudgetSimStatus(id);

                    if (studentStatus.canStart && !studentStatus.started) {
                        setCanStart(true);
                    } else {
                        if (studentStatus.started) {
                            const resumeData = await fetchBudgetSimProgress(id);
                            if (resumeData) {
                                setSimulationData(resumeData);
                                console.log("resumeData", resumeData);
                            }
                        } else {
                            navigate("start");
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [navigate]);

    // New useEffect to generate events when latestDate changes
    useEffect(() => {
        if (simulationData.latestDate) {
            const newEvents = generateRecurringEvents(simulationData.latestDate);
            setEvents(newEvents);
        } else {
            console.warn("simulationData.latestDate is undefined");
        }
    }, [simulationData.latestDate]);

    const handleNextDay = () => {
        setIsPopupOpen(true);
    };

    const handlePopupSubmit = async (selectedOptionIndex) => {
        setIsSubmitting(true);
        setIsPopupOpen(false);

        try {
            await postContinueBudget(studentId, selectedOptionIndex);
            const updatedData = await fetchBudgetSimProgress(studentId);
            setSimulationData(updatedData);
            // Events will be updated via useEffect when simulationData.latestDate changes
        } catch (error) {
            console.error("Error submitting data or fetching updated progress", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>
            <div className="flex w-full h-screen overflow-hidden m-auto ">

                <StudentSidebar />


                {loading ? (
                    <LoadingAnimation />
                ) : (
                    <div className="flex flex-col w-full h-full p-4 gap-4">
                        <BalancesBar balances={simulationData.progressRecords?.[0]?.accountBalance || {}} />

                        <div className="flex h-full w-full bg-white-A700 divide-x rounded-lg border border-gray-200">
                            <div className="flex flex-col w-[70%]">
                                <div className="flex justify-between p-6">
                                    <div className="flex flex-col gap-2">
                                        <h1 className="text-2xl font-semibold">Budget Simulator</h1>
                                        <h2 className="text-gray-600 text-md">
                                            {formatDate(simulationData.latestDate)}
                                        </h2>
                                    </div>

                                    <button
                                        onClick={handleNextDay}
                                        className={`flex justify-center h-full items-center p-4 font-inter font-semibold text-white-A700 text-md border border-gray-200 bg-blue-500 rounded-lg hover:bg-blue-400 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? (
                                            <div className="h-6 w-6 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                                        ) : (
                                            "Next Day"
                                        )}
                                    </button>
                                </div>

                                <div className="flex h-full w-full">
                                    <DashboardTabs progressData={simulationData} events={events} />
                                </div>
                            </div>

                            <div className="flex flex-col w-[30%] p-4 gap-4">
                                <DashboardGaugeChart id={"wm"} name={"Wellness Meter"} value={85} maxValue={100} />
                                <DashboardGaugeChart id={"cs"} name={"Credit Score"} value={670} maxValue={900} />
                                <MonthlySpendingChart />
                                <UpcomingEvents
                                    currentDay={simulationData.latestDate || new Date()}
                                    events={events}
                                />
                            </div>
                        </div>

                        <Popup open={isPopupOpen} closeOnDocumentClick={false} modal nested>
                            <OptionsComponent
                                onSubmit={handlePopupSubmit}
                                progressData={simulationData.progressRecords || []}
                            />
                        </Popup>
                    </div>
                )}
            </div>
        </>
    );
}
