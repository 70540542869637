import { apiClient } from "AuthService";

export const fetchStudentBudgetSimStatus = async (studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${studentId}/Progress`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
            },
        });
        const data = response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to fetch student startup sim status';
        console.error('Error fetching student startup sim status:', message);
        throw { status: error.response?.status, message };
    }
};

export const fetchBudgetSimProgress = async (studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${studentId}/AllProgress`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
            },
        });
        const data = response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || 'Failed to fetch student startup sim status';
        console.error('Error fetching student startup sim status:', message);
        throw { status: error.response?.status, message };
    }
};

export const postBeginBudget = async (studentId) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${studentId}/Begin/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to begin startup";
        console.error('Error beginning startup:', message);
        throw { status: error.response?.status, message };
    }
};

export const postContinueBudget = async (studentId, decision) => {
    try {
        const response = await apiClient(`/api/BudgetSim/${studentId}/Continue/${decision}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = response.json();
        return data;
    } catch (error) {
        const message = error.response?.data?.message || "Failed to continue startup";
        console.error('Error continuing startup:', message);
        throw { status: error.response?.status, message };
    }
};