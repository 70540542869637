import { Text, Img, Button, Input } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export default function ForgotPasswordForm() {
    // State variables
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false); // Loading state

    const navigate = useNavigate();

    const handleClickBack = () => {
        navigate(-1);
    };

    // Handle submit event for the form
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate email
        if (!email) {
            setError("Please enter your email address.");
            return;
        }

        // Clear previous messages
        setError("");
        setSuccess(false);
        setLoading(true);

        try {
            // Retrieve JWT from localStorage or sessionStorage
            const jwt = localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken");

            if (!jwt) {
                // If JWT is not found, redirect to login or show an error
                setError("Authentication token not found. Please log in.");
                setLoading(false);
                // Optionally, redirect to login page after a delay
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
                return;
            }

            // Define the API URL
            const apiUrl = `${API_ENDPOINT}/forgotPassword`;

            // Make the API request with JWT in Authorization header
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${jwt}`, // Include JWT in headers
                    "Accept": "application/json",
                },
                body: JSON.stringify({
                    email: email,
                }),
            });

            if (response.ok) {
                setSuccess(true);
                setError("");
            } else {
                // Attempt to parse error message from response
                let errorDetail = "There was a problem. Please try again later.";
                try {
                    const errorData = await response.json();
                    if (errorData.detail) {
                        errorDetail = errorData.detail;
                    }
                } catch (e) {
                    console.error("Error parsing error response:", e);
                }
                setError(errorDetail);
            }
        } catch (error) {
            console.error("Network error:", error);
            setError("There was a problem. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            {success ? (
                <div className="text-green-600 text-lg">
                    <p>An email has been sent to {email} with instructions to reset your password.</p>
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    {/* Display error message */}
                    {error && <div className="text-red-500 text-sm">{error}</div>}

                    <div className="flex flex-col gap-8">
                        <div className="flex flex-col gap-[23px]">
                            <div className="flex flex-col items-start gap-[5px]">
                                <Text as="p" className="!font-medium !text-blue_gray-800_01">
                                    Your Email
                                </Text>
                                <Input
                                    size="xl"
                                    shape="round"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={setEmail}
                                    className="self-stretch border border-solid border-blue_gray-100_05 sm:pr-5"
                                    // Ensure the input allows form submission on Enter
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            handleSubmit(e);
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex gap-8 self-start sm:flex-col">
                            <Button
                                size="5xl"
                                variant="outline"
                                shape="round"
                                leftIcon={
                                    <Img
                                        src="/images/img_arrowdown.svg"
                                        alt="arrow_down"
                                        className="h-[16px] w-[16px]"
                                    />
                                }
                                className="min-w-[161px] gap-0.5 font-semibold shadow-sm sm:px-5 flex justify-center items-center"
                                onClick={handleClickBack}
                            >
                                Back
                            </Button>
                            <Button
                                type="submit" // Ensure the button submits the form
                                size="5xl"
                                shape="round"
                                className="min-w-[367px] border border-solid border-blue-A200 font-semibold shadow-sm sm:px-5"
                            >
                                Reset Password
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        </div>
    );
}
