import { Helmet } from "react-helmet";
import { Text, Heading } from "../../../../components";
import TopNavLogin from "components/TopNavLogin";
import LoginSideGraphic from "components/LoginSideGraphic";
import ResendConfirmationForm from "./ResendConfirmationForm";

export default function ResendConfirmationPage() {
    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Web site created using create-react-app" />
            </Helmet>
            <div className="flex h-screen overscroll-none overflow-hidden m-0">
                <div className="w-[75%] bg-white-A700 md:w-full relative">
                    <TopNavLogin className="absolute top-0 left-0 right-0 z-10" type={"register"} />

                    <div className="flex-1 flex flex-col gap-16 h-full justify-center items-center">
                        <div className="gap-3 flex flex-col items-center">
                            <Heading size="12xl" as="h1" className="!text-gray-900_05 flex gap-2 items-center">
                                Resend Email Confirmation
                            </Heading>
                            <Text as="p" className="!text-gray-900_05">
                                Enter your account email to get a link to confirm your account.
                            </Text>

                        </div>



                        <div className="flex items-center justify-center w-[80%] gap-16 md:w-full md:flex-col">
                            <ResendConfirmationForm />
                        </div>

                    </div>
                </div>

                <div className="w-[25%] md:hidden">
                    <LoginSideGraphic />
                </div>
            </div>
        </>
    );
}
