import React, {useEffect} from "react";
import { Text, Button, Heading, Img, Input } from "..";

export default function StartupInfoItem({title, value, description, valueClass="!text-black-900"}) {


  return (
    <div className="flex w-full rounded-lg border border-solid border-slate-300 bg-white-A700 sm:flex-col">
        <div className="flex w-full py-[19px] justify-content items-center">
          <div className="flex flex-col items-center gap-2 border-blue_gray-100 px-[15px] sm:px-5">
            <div className="flex self-start">
              <Heading size="5xl" className="!text-gray-600">
                {title}
              </Heading>
              <div className="p-1 flex flex-col relative group">
                <div className="flex flex-col items-center justify-center w-4 h-4 bg-white-A700 text-xs border-2 border-gray-500_02 rounded-full">
                  i   
                </div>
                <div style={{zIndex: 100, transform: "translateZ(0)"}} className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 w-[calc(100%+15rem)] opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto bg-gray-800 text-white-A700 text-xs rounded py-1 px-2 whitespace-no-wrap">
                  <Heading className="!font-poppins !text-white-A700 py-1">{title}:</Heading>
                  <p>{description}</p>
                </div>
              </div>
            </div>
            <div className="flex self-start justify-center items-center">
              <div className="flex flex-col relative group mr-3">
                <Heading size="7xl" className={valueClass}>
                  {value}
                </Heading>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}
