import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Heading, Img, Input } from "../../components";
import { ReactTableHoldings } from "../../components/ReactTableHoldings";
import StudentSidebar from "../../components/StudentSidebar";
import { createColumnHelper } from "@tanstack/react-table";
import { TabPanel, Tabs } from "react-tabs";
import { Link, useNavigate } from 'react-router-dom';
import TickerTape from "components/TickerTape";
import { fetchStudentId, fetchStudentPortfolioGraph, fetchStudentStocks } from "SimulatorAPIService";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';
import StockSearch from "components/StockSearch";
import PortfolioChart from "components/PortfolioChart";
import StockSimulatorTabs from "components/StockSimulatorTabs";

export default function HoldingsPage() {
  const [searchBarValue35, setSearchBarValue35] = useState("");
  const [studentId, setStudentId] = useState(null);
  const [stocks, setStocks] = useState([]);
  const [portfolioGraph, setPortfolioGraph] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const table6Columns = React.useMemo(() => {
    const table6ColumnHelper = createColumnHelper();
    return [
      table6ColumnHelper.accessor("name", {
        cell: (info) => (
          <div className="flex w-full justify-center items-center !text-blue-500 border-solid border-blue_gray-100">
            <Link
              to={{
                pathname: `/stockview/${info.row.original.symbol}`,
                state: { symbol: info.row.original.symbol }
              }}
              className="text-blue-500 hover:text-blue-600 text-center"
            >
              {info.getValue()}
            </Link>
          </div>
        ),
        header: () => "Stock",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("symbol", {
        cell: (info) => (
          <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
            {info?.getValue?.()}
          </div>
        ),
        header: () => "Symbol",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("shareCount", {
        cell: (info) => (
          <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
            {info?.getValue?.()}
          </div>
        ),
        header: () => "Qty",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("current", {
        cell: (info) => (
          <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
            {info?.getValue?.()}
          </div>
        ),
        header: () => "Current Value",
        meta: { width: "full" },
      }),
    ];
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = await fetchStudentId();
        setStudentId(id);

        if (id) {
          const portfolioData = await fetchStudentPortfolioGraph(id);
          setPortfolioGraph(portfolioData);

          const stockData = await fetchStudentStocks(id);
          setStocks(stockData);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/timeout");
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Holdings | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>

      <div className="flex w-full h-screen overflow-hidden m-auto">

        <div><StudentSidebar /></div>

        <div className="flex flex-col w-full">
          <TickerTape />

          <div className="flex w-full h-full">

            <div className="flex flex-grow flex-shrink-0 w-[25%] ml-4 mt-4 h-[90%] border rounded-lg">
              <StockSearch />
            </div>

            <div className="flex flex-col justify-start  w-full h-[90%] gap-4 m-4 border border-solid rounded-lg ">

              <div className="flex w-full gap-6 border-b p-4 justify-end">
                <StockSimulatorTabs currentTab={"holdings"}/>
              </div>

              <div className="flex flex-col overflow-auto gap-4 p-4">

                <div className="items-stretch w-full gap-1.5">
                  <Tabs
                    className="flex flex-col gap-4"
                    selectedTabClassName="!text-blue-500"
                    selectedTabPanelClassName="relative tab-panel--selected"
                  >
                    {[...Array(5)].map((_, index) => (
                      <TabPanel key={`tab-panel${index}`} className="absolute items-center">
                        <div className="w-full">
                          <div className="flex flex-col gap-4">
                            <div className="flex self-start">
                              <Heading size="11xl" as="h2">
                                Holdings ({stocks.length}){" "}
                              </Heading>
                            </div>
                            <div className="flex rounded-lg  items-center justify-center border border-solid border-blue_gray-50_01 bg-white-A700 px-4 py-[19px]">
                              <div className="flex flex-col items-center gap-2 border-r border-solid border-blue_gray-100 px-[29px] sm:px-5">
                                <div className="flex self-start">
                                  <Heading as="h3" className="!font-poppins !text-gray-500_02">
                                    Balance Funds
                                  </Heading>
                                </div>
                                <div className="flex-1 w-full self-start">
                                  <Heading size="7xl" as="h4" className="!text-gray-800_02">
                                    <span>${portfolioGraph.currentBalance?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || <Skeleton width={"85%"} count={1} />}</span>
                                  </Heading>
                                </div>
                              </div>
                              <div className="flex flex-col items-center gap-2 border-l border-r border-solid border-blue_gray-100 px-9 sm:px-5">
                                <div className="flex self-start">
                                  <Heading as="h5" className="!font-poppins !text-gray-500_02">
                                    Total Investment
                                  </Heading>
                                </div>
                                <div className="flex-1 w-full self-start">
                                  <Heading size="7xl" as="h6" className="!text-gray-800_02">
                                    <span>$
                                      {isNaN(portfolioGraph.startingBalance - portfolioGraph.currentBalance) ? (
                                        <Skeleton width="100px" height="1em" />
                                      ) : (
                                        `${(portfolioGraph.startingBalance - portfolioGraph.currentBalance).toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2
                                        })}`
                                      )}
                                    </span>
                                  </Heading>
                                </div>
                              </div>
                              <div className="flex flex-col items-center gap-2 border-l border-r border-solid border-blue_gray-100 px-[42px] md:px-5">
                                <div className="flex self-start">
                                  <Heading as="p" className="!font-poppins !text-gray-500_02">
                                    Current Value
                                  </Heading>
                                </div>
                                <div className="flex-1 w-full self-start">
                                  <Heading size="7xl" as="h6" className="!text-gray-800_02">
                                    <span>${portfolioGraph.portfolioTotalValue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) || <Skeleton width={"85%"} count={1} />}</span>
                                  </Heading>
                                </div>
                              </div>
                              <div className="flex flex-col items-center text-center gap-2 border-l border-r border-solid border-blue_gray-100 px-7 sm:px-5">
                                <div className="flex">
                                  <Heading as="p" className="!font-poppins !text-gray-500_02">
                                    Day's P&L
                                  </Heading>
                                </div>
                                <div className="flex-1 w-full self-start">
                                  <Heading size="7xl" as="h6">
                                    <span>
                                      ${portfolioGraph.historicalGraphPoints &&
                                        portfolioGraph.historicalGraphPoints.length > 1 ? (
                                        <>
                                          {(
                                            portfolioGraph.portfolioTotalValue -
                                            portfolioGraph.historicalGraphPoints[portfolioGraph.historicalGraphPoints.length - 1].portfolioValue
                                          ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                          <span
                                            className={
                                              (portfolioGraph.portfolioTotalValue -
                                                portfolioGraph.historicalGraphPoints[portfolioGraph.historicalGraphPoints.length - 1].portfolioValue) > 0
                                                ? "text-green-500"
                                                : "text-red-500"
                                            }
                                          >
                                            {` (${(
                                              ((portfolioGraph.portfolioTotalValue -
                                                portfolioGraph.historicalGraphPoints[portfolioGraph.historicalGraphPoints.length - 1].portfolioValue) /
                                                portfolioGraph.historicalGraphPoints[portfolioGraph.historicalGraphPoints.length - 1].portfolioValue) * 100
                                            ).toFixed(2)
                                              }%)`}
                                          </span>
                                        </>
                                      ) : (
                                        <Skeleton width={"65%"} count={1} />
                                      )}
                                    </span>
                                  </Heading>
                                </div>
                              </div>
                              <div className="flex flex-col items-center text-center gap-2 border-l border-solid border-blue_gray-100 px-[35px] sm:px-5">
                                <div className="flex">
                                  <Heading as="p" className="!font-poppins !text-gray-500_02">
                                    Total P&L
                                  </Heading>
                                </div>
                                <div className="flex-1 w-full self-start">
                                  <Heading size="7xl" as="h6" className="!font-poppins !text-gray-800_02">
                                    <span>$
                                      {portfolioGraph.portfolioTotalValue !== undefined &&
                                        portfolioGraph.startingBalance !== undefined ? (
                                        <>
                                          {(
                                            portfolioGraph.portfolioTotalValue - (portfolioGraph.startingBalance - portfolioGraph.currentBalance)
                                          ).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                          <span
                                            className={
                                              (portfolioGraph.portfolioTotalValue - (portfolioGraph.startingBalance - portfolioGraph.currentBalance)) > 0
                                                ? "text-green-500"
                                                : "text-red-500"
                                            }
                                          >
                                            {` (${(
                                              ((portfolioGraph.portfolioTotalValue - (portfolioGraph.startingBalance - portfolioGraph.currentBalance)) /
                                                portfolioGraph.startingBalance) * 100
                                            ).toFixed(2)
                                              }%)`}
                                          </span>
                                        </>
                                      ) : (
                                        <Skeleton width={"65%"} count={1} />
                                      )}
                                    </span>
                                  </Heading>
                                </div>
                              </div>
                            </div>
                            <div className="flex w-[97%] h-full min-h-[350px] flex-col gap-6 rounded-lg border border-solid border-blue_gray-50_01 bg-white-A700 p-4 ml-3">
                              <PortfolioChart portfolioData={portfolioGraph}/>
                            </div>
                            <div className="">
                              <ReactTableHoldings
                                size="2xl"
                                bodyProps={{ className: "w-full" }}
                                headerProps={{
                                  className: "w-full border-blue_gray-100 border-b border-solid bg-gray-50_01",
                                }}
                                rowDataProps={{ className: "w-full border-t border-b border-solid border-blue_gray-100" }}
                                className="w-full"
                                columns={table6Columns}
                                data={isLoading ? [] : stocks}
                                isLoading={isLoading} // Add this prop to handle loading state
                              />
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    ))}
                  </Tabs>
                </div>

              </div>
            </div>

          </div>
        </div>


      </div>


    </>
  );
}

