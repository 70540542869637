import React from "react";
import { Helmet } from "react-helmet";
import { Text, Img, Heading, Button } from "../../components";
import Header from "../../components/Header";
import Sidebar1 from "../../components/Sidebar1";
import StudentsSegment from "../../components/StudentsSegment";
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { createColumnHelper } from "@tanstack/react-table";
import { ReactTable } from "../../components/ReactTable";


export default function StudentsPage() {
  const navigate = useNavigate();

  const handleAllClassesClick = () => {
    navigate('/teacherhome');
  };

  const handleAssignedCoursesClick = () => {
    navigate('/assignedcourses');
  };

  const handleStudentsClick = () => {
    navigate('/students');
  };

  const handleInstructorClick = () => {
    navigate('/instructor');
  };

  const handleAssignmentClick = () => {
    navigate('/assignments');
  };

  const handleStudentPerformanceClick = () => {
    navigate('/performancecourses');
  };

  const table6Data = [
    { firstName: "John", lastName: "Doe", gradeLevel: "9", difficulty: "Medium", lastActivity: "January 1, 2024" },
    { firstName: "Michael", lastName: "Scott", gradeLevel: "10", difficulty: "Low", lastActivity: "January 1, 2024" },
    { firstName: "Steve", lastName: "Harvey", gradeLevel: "12", difficulty: "High", lastActivity: "January 1, 2024" },
  ];

  const table6Columns = React.useMemo(() => {
    const table6ColumnHelper = createColumnHelper();
    return [
      table6ColumnHelper.accessor("firstName", {
        cell: (info) => (
          <div className="flex w-full justify-between ml-12 items-center !text-blue-500 border-solid border-blue_gray-100">
            <Link to={`/Performancecourses`} className="text-blue-500 hover:text-blue-600">
              {info.getValue()}
            </Link>
          </div>
        ),
        header: () => "First Name",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("lastName", {
        cell: (info) => (
          <div className="flex w-full justify-between ml-12 items-center !text-blue-500 border-solid border-blue_gray-100">
            <Link to={`/Performancecourses`} className="text-blue-500 hover:text-blue-600">
              {info.getValue()}
            </Link>
          </div>
        ),
        header: () => "Last Name",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("gradeLevel", {
        cell: (info) => <div className="flex w-full justify-between ml-16 items-center border-solid border-blue_gray-100">{info.getValue()}</div>,
        header: () => "Grade Level",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("difficulty", {
        cell: (info) => <div className="flex w-full justify-between ml-10 items-center border-solid border-blue_gray-100">{info.getValue()}</div>,
        header: () => "Difficulty",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("lastActivity", {
        cell: (info) => <div className="flex w-full justify-between ml-4 items-center border-solid border-blue_gray-100">{info.getValue()}</div>,
        header: () => "Last Online Activity",
        meta: { width: "full" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>Students | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-center bg-white-A700">
        <Sidebar1 className="h-screen" />
        <div className="flex flex-col w-full p-5">
          <div>
            <div className="flex flex-col">
              <div className="flex self-start px-4 py-3.5">
                <div className="flex gap-2 cursor-pointer" onClick={handleAllClassesClick}>
                  <Img src="images/img_arrow_left_blue_a200.svg" alt="arrowleft" className="h-[20px]" />
                  <div className="flex">
                  <Heading as="h1" className="!text-blue-500 mt-[2px]">
                    All Classes
                  </Heading>
                  </div>
                </div>
              </div>
              <div className="relative mt-[-3px] flex items-center justify-between gap-5 px-4 sm:flex-col">
                <div className="flex flex-col items-start gap-0.5">
                  <Heading size="11xl" as="h2" className="!font-poppins !text-gray-900_05 pb-4">
                    Class Financial 101
                  </Heading>
                  <div className="flex gap-3 self-center pb-4">
                    <Text as="p" className="self-start tracking-[0.25px] !text-gray-800_01">
                      20 students
                    </Text>
                    <Text as="p" className="self-end tracking-[0.25px] !text-gray-800_01">
                      |
                    </Text>
                    <Text as="p" className="self-start tracking-[0.25px] !text-gray-800_01">
                      Grade 4th
                    </Text>
                    <div className="flex flex-wrap gap-1.5 self-end">
                      <Text as="p" className="tracking-[0.25px] !text-gray-800_01">
                        |
                      </Text>
                      <Text as="p" className="tracking-[0.25px] !text-gray-800_01">
                        Jan, 24 2024
                      </Text>
                    </div>
                  </div>
                </div>
                <div className="mb-2 flex gap-4 self-end">
                  <Button color="blue_gray_100" size="2xl" variant="outline" shape="round" className="w-[40px] border">
                    <Img src="images/img_search.svg" />
                  </Button>
                  <Button
                    size="3xl"
                    shape="round"
                    leftIcon={<Img src="images/img_plus_white_a700.svg" alt="plus" className="h-[18px] w-[18px]" />}
                    className="min-w-[157px] gap-2 font-roboto font-medium tracking-[0.10px] sm:pr-5"
                  >
                    Add Instructor
                  </Button>
                </div>
              </div>
              <div className="flex border-b border-solid border-blue_gray-100 px-4 sm:flex-col">
                <div className="flex px-4 pb-3.5 pt-4">
                  <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer" onClick={handleAssignedCoursesClick}>
                    Assigned courses
                  </Text>
                </div>
                <div className="flex justify-center px-4 sm:w-full">
                  <div className="flex w-full flex-col pt-[15px]">
                    <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-blue-A200 cursor-pointer" onClick={handleStudentsClick}>
                      Students
                    </Text>
                  </div>
                </div>
                <div className="flex p-[15px]">
                  <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer" onClick={handleInstructorClick}>
                    Instructors
                  </Text>
                </div>
                <div className="flex px-4 pb-3.5 pt-4">
                  <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer" onClick={handleAssignmentClick}>
                    Assignment
                  </Text>
                </div>
                <div className="flex p-[15px]">
                  <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer" onClick={handleStudentPerformanceClick}>
                    Student Performance
                  </Text>
                </div>
              </div>
              <div>
                <div className="pb-[78px] pr-4 md:pb-5">
                  <div className="flex items-start gap-4 pr-[63px] md:flex-col md:pr-5">
                    <div className="flex flex-1 flex-col justify-center gap-2 pb-[375px] pt-4 md:self-stretch md:pb-5">
                      <div className="flex items-center gap-2 self-end px-4 py-2">
                        <Button
                          size="3xl"
                          variant="outline"
                          shape="round"
                          className="min-w-[166px] font-roboto font-medium tracking-[0.10px] sm:px-5"
                        >
                          Create Credentials
                        </Button>
                        <div className="flex flex-col items-center p-1">
                          <Img src="images/img_notification.svg" alt="notification" className="h-[24px] w-[24px]" />
                        </div>
                      </div>
                      <div className="w-[97%] rounded-lg border border-solid border-blue_gray-50_01 md:w-full" style={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)' }}>
                      <div className="p-1" style={{ margin: '5px' }}>
                          <ReactTable
                            size="xl"
                            bodyProps={{ className: "w-full" }}
                            headerProps={{
                              className: "w-full border-blue_gray-100 border-b border-solid bg-gray-50_01 md:flex-col",
                            }}
                            rowDataProps={{ className: "w-full border-t border-b border-solid border-blue_gray-100" }}
                            className="w-full"
                            columns={table6Columns}
                            data={table6Data}
                          />
                      </div>
                      </div>
                    </div>
                    <div className="flex w-[19%] flex-col gap-6 md:w-full">
                      <div className="flex px-4">
                        <div className="flex pb-3.5 pt-4">
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01">
                            Quick Links
                          </Text>
                        </div>
                      </div>
                      <div className="flex flex-col gap-6">
                        <div className="flex flex-1 items-center justify-center gap-2 rounded-lg border border-solid border-blue_gray-50_01 p-2">
                          <div className="flex flex-col items-center rounded-md bg-purple-50 px-3 pb-4 pt-[15px]">
                            <Img src="images/img_user_gray_800_01.svg" alt="add_course" className="h-[10px]" />
                          </div>
                          <div className="flex flex-1 items-center justify-between gap-5">
                            <Text as="p" className="tracking-[0.50px] !text-gray-800_01">
                              Add Course
                            </Text>
                            <Img src="images/img_plus.svg" alt="add_course" className="h-[16px] w-[16px]" />
                          </div>
                        </div>
                        <div className="flex flex-1 items-center justify-center gap-2 rounded-lg border border-solid border-blue_gray-50_01 p-2">
                          <Button color="teal_50" size="2xl" className="w-[40px] rounded-md">
                            <Img src="images/img_television_gray_800_01.svg" />
                          </Button>
                          <div className="flex flex-1 items-center justify-center gap-4">
                            <Text as="p" className="w-[88%] leading-4 tracking-[0.50px] !text-gray-800_01">
                              Create Assignments
                            </Text>
                            <Img src="images/img_plus_gray_900_07.svg" alt="plus" className="h-[16px] w-[16px]" />
                          </div>
                        </div>
                        <div className="flex flex-1 items-center justify-center gap-2 rounded-lg border border-solid border-blue_gray-50_01 p-2">
                          <Img
                            src="images/img_container_light_blue_50.svg"
                            alt="container"
                            className="h-[42px] rounded-md"
                          />
                          <div className="flex flex-1 items-center justify-center gap-4">
                            <Text as="p" className="w-[88%] leading-4 tracking-[0.50px] !text-gray-800_01">
                              Create Simulator Excercise
                            </Text>
                            <Img src="images/img_plus_gray_900_07.svg" alt="plus" className="h-[16px] w-[16px]" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}