// src/components/LoginGraphic.jsx

import React from "react";
import { Img } from "components";

export default function LoginSideGraphic() {
  return (
    <div className="w-full h-full flex justify-end items-center bg-gradient-to-br from-secondary to-primary">
      <Img
        src="/images/img_login_graphic.svg"
        alt="Login Graphic"
        className="h-[90%] w-auto relative left-10 top-1/2 transform -translate-y-1/2"
      />
    </div>
  );
}
