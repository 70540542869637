// src/components/DashboardTabs.jsx
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; // Import default styles
import BudgetSimDashboardCalendar from '../DashboardCalendar';
import BudgetSimulatorAccounts from '../Accounts';
import Statements from '../Statements';

const DashboardTabs = ({ progressData, events }) => {
    return (
        <Tabs className="container flex flex-col h-full" selectedTabPanelClassName='flex w-full h-full'>
            <TabList className="flex w-full border-b border-b-gray-200 mb-4 gap-8 px-12">
                <Tab
                    className="text-sm pb-2 cursor-pointer text-black"
                    selectedClassName="border-b-2 border-blue-500 text-blue-500"
                >
                    Calendar
                </Tab>
                <Tab
                    className="text-sm pb-2 cursor-pointer text-black"
                    selectedClassName="border-b-2 border-blue-500 text-blue-500"
                >
                    Accounts
                </Tab>
                <Tab
                    className="text-sm pb-2 cursor-pointer text-black"
                    selectedClassName="border-b-2 border-blue-500 text-blue-500"
                >
                    Statement
                </Tab>
            </TabList>

            {/* Calendar Tab Panel */}

            <TabPanel>
                <div className='grow'>
                    <BudgetSimDashboardCalendar currentDay={progressData.latestDate} events={events} />
                </div>
            </TabPanel>

            {/* Accounts Tab Panel */}
            <TabPanel >
                <BudgetSimulatorAccounts />
            </TabPanel>

            {/* Statement Tab Panel */}
            <TabPanel >
                <Statements />
            </TabPanel>

        </Tabs>
    );
};

export default DashboardTabs;
