import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Button, Heading } from "../../components";
import Header from "../../components/Header";
import { ReactTable } from "../../components/ReactTable";
import StudentSidebar from "../../components/StudentSidebar";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { fetchClassroomDetails, fetchInstructors } from "TeacherAPIService";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ReactTableHoldings } from "components/ReactTableHoldings";
import AddInstructorPopup from "components/AddInstructorPopup";
import QuickLink from "components/QuickLink";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

const Instructors = ({ classroomData }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [instructorData, setInstructorData] = useState([]);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();

    const getInstructors = async () => {
        try {
            const data = await fetchInstructors(classroomData.id);
            setInstructorData(data);
        } catch (error) {
            if (error.status === 401) {
                navigate('/timeout'); // Navigate to timeout page on 401
            } else {
                console.error('Error fetching instructors:', error);
                setInstructorData([]);
            }
        }
    };

    useEffect(() => {
        getInstructors();
    }, [classroomData.id, navigate]);


    const handleSelectRow = (id) => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.includes(id)
                ? prevSelectedRows.filter((rowId) => rowId !== id)
                : [...prevSelectedRows, id]
        );
    };

    const handleSelectAllRows = () => {
        if (selectedRows.length === instructorData.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(instructorData.map((instructor) => instructor.id));
        }
    };

    const table1Columns = React.useMemo(() => {
        const table1ColumnHelper = createColumnHelper();
        return [
            table1ColumnHelper.accessor("firstName", {
                cell: (info) => (
                    <div className="flex justify-center text-center items-center text-sm h-14"> {/* HEIGHT FOR CELLS IS DETERMINED HERE */}
                        {info?.getValue?.()}
                    </div>
                ),
                header: (info) => (
                    <Heading size="4xl" as="h3" className=" !text-blue_gray-500 flex items-center justify-center h-11">
                        First Name
                    </Heading>
                ),
                meta: { width: "auto" },
            }),
            table1ColumnHelper.accessor("lastName", {
                cell: (info) => (
                    <div className="justify-center items-center text-sm md:self-stretch">
                        {info?.getValue?.()}
                    </div>
                ),
                header: (info) => (
                    <Heading size="4xl" as="h4" className="!text-blue_gray-500">
                        Last Name
                    </Heading>
                ),
                meta: { width: "auto" },
            }),
            table1ColumnHelper.accessor("email", {
                cell: (info) => (
                    <div className="justify-center items-center text-sm md:self-stretch">
                        {info?.getValue?.()}
                    </div>
                ),
                header: (info) => (
                    <Heading size="4xl" as="h4" className="!text-blue_gray-500">
                        Email
                    </Heading>
                ),
                meta: { width: "auto" },
            })
        ];
    }, [selectedRows]);

    return (
        <div className="flex">
            <div className="flex flex-col w-[85%] h-[75vh] gap-4 p-4 md:w-full">

                <div className="flex w-full gap-2 justify-end">
                    <Button
                        size="3xl"
                        variant="outline"
                        shape="round"
                        className="min-w-[166px] font-roboto font-medium tracking-[0.10px] sm:px-5"
                        onClick={() => setPopupOpen(true)}
                    >
                        Add Instructor
                    </Button>
                </div>

                <AddInstructorPopup
                    open={isPopupOpen}
                    onClose={() => setPopupOpen(false)}
                    onInstructorAdded={() => getInstructors()}
                    classroomData={classroomData} />

                <div className="flex flex-col h-full w-full border border-gray-300 rounded-lg overflow-hidden">
                    <div className="flex justify-end px-4  border-b">
                        <button>
                            <p className="p-2 font-bold text-xl">⋮</p>
                        </button>
                    </div>
                    <div className="overflow-auto">
                        <ReactTableHoldings
                            size="lg"
                            bodyProps={{ className: "" }}
                            headerProps={{
                                className: "rounded-lg border-blue_gray-50_01 border-b border-solid bg-gray-50_01 md:flex-col",
                            }}
                            rowDataProps={{ className: "md:flex-col border-b" }}
                            className="w-full rounded-lg"
                            columns={table1Columns}
                            data={instructorData}
                        />
                    </div>

                </div>
            </div>

            <div className="w-px min-h-[90vh] bg-gray-300"></div>

            <div className="flex flex-col w-[25%] h-full m-4 gap-5 md:w-[0%] md:hidden">
                <QuickLink classroomData={classroomData} />
            </div>
        </div>
    );
};

export default Instructors;
