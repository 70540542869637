import React from "react";
import { Link } from 'react-router-dom';
import { Text } from "../../components";

const StockSimulatorTabs = ({ currentTab }) => {

    const tabClass = (tab) => {
        return currentTab === tab
            ? "!font-roboto !font-medium tracking-[0.10px] !text-blue-500"
            : "!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01";
    };

    return (
        <>
            <Link to="/dashboard">
                <Text as="p" className={tabClass("dashboard")}>Dashboard</Text>
            </Link>
            <Link to="/topstories">
                <Text as="p" className={tabClass("topstories")}>Top Stories</Text>
            </Link>
            <Link to="/topstocks">
                <Text as="p" className={tabClass("topstocks")}>Top Stocks</Text>
            </Link>
            <Link to="/watchlist">
                <Text as="p" className={tabClass("watchlist")}>Watchlist</Text>
            </Link>
            <Link to="/heatmap">
                <Text as="p" className={tabClass("heatmap")}>Heatmap</Text>
            </Link>
            <Link to="/forex">
                <Text as="p" className={tabClass("forex")}>Forex</Text>
            </Link>
            <Link to="/earningscalendar">
                <Text as="p" className={tabClass("earningscalendar")}>Earnings Calendar</Text>
            </Link>
            <Link to="/orders">
                <Text as="p" className={tabClass("orders")}>Orders</Text>
            </Link>
            <Link to="/holdings">
                <Text as="p" className={tabClass("holdings")}>Holdings</Text>
            </Link>

        </>
    );
};

export default StockSimulatorTabs;