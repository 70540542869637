import React, { useState, useEffect, useCallback } from "react";
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { createColumnHelper } from "@tanstack/react-table";
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import { ReactTableAnalytics } from "components/ReactTableAnalytics";
import StudentStockSimulator from "components/StudentStockSimulator";
import StudentStartupSimulator from "components/StudentStartupSimulator";
import { fetchStudentDetails, fetchStudentProgress, fetchAnalyticsData } from "TeacherAPIService";
import AveragePerformanceChart from "components/AveragePerformanceChart";
import { IconSortAscendingLetters, IconSortDescendingLetters } from '@tabler/icons-react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';
import TimeCompletionChart from "components/TimeCompletedChart";

const Spinner = () => (
    <div className="spinner-container">
        <div className="spinner"></div>
    </div>
);

const studentDataCache = {};

export default function StudentPerformance({ classroomData }) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [activeTab, setActiveTab] = useState(null);
    const [studentDetails, setStudentDetails] = useState(null);
    const [activeSection, setActiveSection] = useState('Courses');
    const [courseStatus, setCourseStatus] = useState(null);
    const [analyticsData, setAnalyticsData] = useState([]);
    const [isStudentLoading, setIsStudentLoading] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [expandedRows, setExpandedRows] = useState({});
    const [expandedSections, setExpandedSections] = useState({});
    const [isAscending, setIsAscending] = useState(true); // State to track sort order
    const [sortedStudents, setSortedStudents] = useState(classroomData.students); // State to hold sorted students

    const handleSort = () => {
        const sorted = [...sortedStudents].sort((a, b) => {
            if (isAscending) {
                return a.last.localeCompare(b.last); // Sort by last name in ascending order
            } else {
                return b.last.localeCompare(a.last); // Sort by last name in descending order
            }
        });
        setSortedStudents(sorted);
        setIsAscending(!isAscending); // Toggle sort order
    };

    const handleOptionClick = async (option) => {
        setSelectedOption(option.course.name);
        setIsOpen(false);

        if (studentDetails) {
            setIsStudentLoading(true);
            const studentId = studentDetails.studentId || studentDetails.id;
            const courseId = option.course.id;

            try {
                console.log(studentId, courseId)
                const progress = await handleStudentProgress(studentId, courseId);
                const analytics = await fetchAnalyticsData(studentId, courseId);
                const processedAnalyticsData = processAnalyticsData(analytics);

                setCourseStatus(progress);
                setAnalyticsData(processedAnalyticsData);
            } catch (error) {
                console.error("Error fetching data:", error);
                // Optionally, set an error state here to inform the user
            } finally {
                setIsStudentLoading(false);
            }
        }
    };


    const handleStudentInfo = async (studentId) => {
        setIsStudentLoading(true);
        if (studentDataCache[studentId]) {
            setStudentDetails(studentDataCache[studentId].details);
            setCourseStatus(studentDataCache[studentId].courseStatus);
            const processedAnalyticsData = processAnalyticsData(studentDataCache[studentId].analyticsData);
            setAnalyticsData(processedAnalyticsData);
            setIsStudentLoading(false);
            return;
        }

        const details = await fetchStudentDetails(studentId);
        setStudentDetails(details);
        if (details.courses && details.courses.length > 0) {
            setSelectedOption(details.courses[0].course.name);
            const progress = await handleStudentProgress(studentId, details.courses[0].course.id);
            const analytics = await fetchAnalyticsData(studentId, details.courses[0].course.id);
            const processedAnalyticsData = processAnalyticsData(analytics);
            studentDataCache[studentId] = {
                details,
                courseStatus: progress,
                analyticsData: processedAnalyticsData
            };
            setCourseStatus(progress);
            setAnalyticsData(processedAnalyticsData);
        }
        setIsStudentLoading(false);
    };

    const handleStudentProgress = async (studentId, courseId) => {
        const progress = await fetchStudentProgress(courseId, studentId);
        return progress;
    };

    const handleActiveSection = async (newSection) => {
        setActiveSection(newSection);
        if (newSection === "Courses" && studentDetails && studentDetails.courses.length > 0) {
            const progress = await handleStudentProgress(studentDetails.studentId, studentDetails.courses[0].course.id);
            setCourseStatus(progress);
            const analytics = await fetchAnalyticsData(studentDetails.studentId, studentDetails.courses[0].course.id);
            const processedAnalyticsData = processAnalyticsData(analytics);
            setAnalyticsData(processedAnalyticsData);
        }
    };

    const handleExpandRow = (rowIndex) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [rowIndex]: !prevState[rowIndex],
        }));
    };

    const handleExpandSection = (rowIndex, sectionIndex) => {
        setExpandedSections((prevState) => ({
            ...prevState,
            [rowIndex]: {
                ...prevState[rowIndex],
                [sectionIndex]: !prevState[rowIndex]?.[sectionIndex],
            }
        }));
    };

    useEffect(() => {
        if (classroomData.students && classroomData.students.length > 0) {
            setActiveTab(sortedStudents[0].id);
            handleStudentInfo(sortedStudents[0].id);
        }
    }, [classroomData, isAscending]);

    const calculateAvgTestReattempts = (analytics) => {
        const totalReattempts = analytics?.reduce((acc, unit) => {
            return acc + unit?.sections?.reduce((sectionAcc, section) => sectionAcc + section.retriesUsed, 0);
        }, 0);
        const totalSections = analytics?.reduce((acc, unit) => acc + unit?.sections?.length, 0);
        return totalSections ? (totalReattempts / totalSections).toFixed(2) : 0;
    };

    const calculateLateAssignments = (analytics) => {
        return analytics?.reduce((acc, unit) => {
            return acc + unit?.sections?.reduce((sectionAcc, section) => sectionAcc + (section.late ? 1 : 0), 0);
        }, 0);
    };

    const getStatus = (progress) => {
        switch (progress) {
            case 0:
                return <span className="text-red-800 font-semibold p-2 border border-red-600 rounded-lg bg-red-400">Not Started</span>;
            case 1:
                return <span className="text-yellow-800 font-semibold p-2 border border-yellow-600 rounded-lg bg-yellow-300">In Progress</span>;
            case 2:
                return <span className="text-green-800 font-semibold p-2 border border-green-600 rounded-lg bg-green-300">Completed</span>;
            default:
                return <span className="text-red-800 font-semibold p-2 border border-red-600 rounded-lg bg-red-300">Not Started</span>;
        }
    };

    const getStatusClass = (progress) => {
        switch (progress) {
            case 0:
                return "text-red-500 bg-red-200 border border-red-600";
            case 1:
                return "text-yellow-700 bg-yellow-200 border border-yellow-700";
            case 2:
                return "text-green-700 bg-green-200 border border-green-700";
            default:
                return "";
        }
    };

    const processAnalyticsData = (analytics) => {
        const processedData = analytics.map((unit) => {
            // Determine the status of the unit based on the progress of its sections
            const unitStatus = unit.sections.some(section => section.progress > 0 && section.progress < 100)
                ? 1 // In Progress
                : unit?.sections?.every(section => section.progress === 2)
                    ? 2 // Completed
                    : 0; // Not Started

            // Sort sections within each unit by sequenceNumber in ascending order
            unit.sections.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

            // Return the unit with the new status and sorted sections
            return {
                ...unit,
                unitStatus,
            };
        });

        // Sort units by numerical prefix in unitName first, then alphabetically
        processedData.sort((a, b) => {
            const numA = parseInt(a.unitName);
            const numB = parseInt(b.unitName);

            if (numA !== numB) {
                return numA - numB; // Sort by numerical part first
            }

            return a.unitName.localeCompare(b.unitName); // Sort alphabetically if numerical parts are equal
        });

        return processedData;
    };



    const avgTestReattempts = calculateAvgTestReattempts(analyticsData);
    const lateAssignments = calculateLateAssignments(analyticsData);

    const table5Columns = React.useMemo(() => {
        const table5ColumnHelper = createColumnHelper();
        return [
            table5ColumnHelper.accessor("unitName", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {info.getValue()}
                    </div>
                ),
                header: () => "Modules",
                meta: { width: "auto" },
            }),

            table5ColumnHelper.accessor("unitStatus", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        <span className={`border p-2 rounded-lg ${getStatusClass(info.getValue())}`}>
                            {getStatus(info.getValue())}
                        </span>
                    </div>
                ),
                header: () => "Status",
                meta: { width: "auto" },
            }),

            table5ColumnHelper.accessor("c", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {"20 min"}
                    </div>
                ),
                header: () => "Duration",
                meta: { width: "auto" },
            }),

            table5ColumnHelper.accessor("b", {
                cell: (info) => (
                    <div className="flex w-full justify-center items-center border-solid border-blue_gray-100">
                        {"Standard"}
                    </div>
                ),
                header: () => "Suggested Difficulty",
                meta: { width: "auto" },
            }),
        ];
    }, [expandedRows]);

    const calculateUnitCompletion = useCallback((unit) => {
        const totalSections = unit.sections.length;
        if (totalSections === 0) return 0;

        // Assign points based on section progress
        // Completed (2): 100%
        // In Progress (1): 50%
        // Not Started (0): 0%
        const totalCompletionPoints = unit.sections.reduce((acc, section) => {
            if (section.progress === 2) return acc + 100;
            if (section.progress === 1) return acc + 50;
            return acc; // progress === 0
        }, 0);

        const percentageComplete = Math.round(totalCompletionPoints / totalSections);

        return percentageComplete;
    }, []);

    const calculateCourseCompletion = useCallback((units) => {
        const totalUnits = units.length;
        if (totalUnits === 0) return 0;

        const totalSections = units.reduce((acc, unit) => acc + unit.sections.length, 0);
        if (totalSections === 0) return 0;

        // Assign points based on section progress across all units
        // Completed (2): 100%
        // In Progress (1): 50%
        // Not Started (0): 0%
        const totalCompletionPoints = units.reduce((acc, unit) => {
            return acc + unit.sections.reduce((sectionAcc, section) => {
                if (section.progress === 2) return sectionAcc + 100;
                if (section.progress === 1) return sectionAcc + 50;
                return sectionAcc; // progress === 0
            }, 0);
        }, 0);

        const percentageComplete = Math.round(totalCompletionPoints / totalSections);

        return percentageComplete;
    }, []);

    const roundToTenth = (num) => {
        return Math.round(num * 10) / 10;
    };


    const calculateTotalMinutesSpent = (units) => {
        return roundToTenth(units.reduce((totalTime, unit) => {
            return totalTime + unit.sections.reduce((sectionTime, section) => {
                return sectionTime + section.completedSectionInMinutes;
            }, 0);
        }, 0));
    };

    const getSectionWithMostIncorrectQuestions = (units) => {
        let maxIncorrect = 0;
        let sectionWithMostIncorrect = null;

        units.forEach((unit) => {
            unit.sections.forEach((section) => {
                const incorrectCount = section.missedQuestions.reduce((acc, question) => {
                    return acc + (!question.correct ? 1 : 0); // Count incorrect answers
                }, 0);

                if (incorrectCount > maxIncorrect) {
                    maxIncorrect = incorrectCount;
                    sectionWithMostIncorrect = {
                        unitName: unit.unitName,
                        sectionName: section.sectionName,
                        incorrectCount: maxIncorrect,
                    };
                }
            });
        });

        return sectionWithMostIncorrect;
    };

    const calculateFastestSection = (analyticsData) => {
        let fastestSection = null;
        let fastestTime = Infinity; // Start with a high number

        analyticsData.forEach((unit) => {
            unit.sections.forEach((section) => {
                if (section.completedSectionInMinutes > 0 && section.completedSectionInMinutes < fastestTime) {
                    fastestTime = section.completedSectionInMinutes;
                    fastestSection = section.sectionName;
                }
            });
        });

        return fastestSection ? { name: fastestSection, time: roundToTenth(fastestTime) } : { name: 'No section completed', time: 0 };
    };

    return (
        <Tabs className={"overflow-hidden"}>
            <div className="flex w-full h-[75vh] p-4 gap-4">
                <div className="flex flex-col border min-w-[15%] max-w-[15%] rounded-lg">
                    <div className="flex justify-between items-center py-2 px-6 bg-blue-50 text-sm font-medium">
                        <h2>STUDENT NAME</h2>
                        <button onClick={handleSort} className="text-blue-500">
                            {isAscending ? <IconSortDescendingLetters /> : <IconSortAscendingLetters />}
                        </button>
                    </div>
                    <TabList className={"h-[75vh] overflow-auto"}>
                        {sortedStudents.map((student) => {
                            const handleTabClick = async () => {
                                setActiveTab(student.id);
                                setActiveSection('Courses');
                                await handleStudentInfo(student.id);
                            };

                            return (
                                <Tab
                                    key={student.id}
                                    className="flex items-center py-2 px-6 text-sm"
                                    selectedClassName="bg-blue-100"
                                    onClick={handleTabClick}
                                >
                                    {student.first} {student.last}
                                </Tab>
                            );
                        })}
                    </TabList>
                </div>

                <div className="w-full overflow-hidden">
                    {classroomData.students.map((student) => (
                        <TabPanel key={student.id} className={"overflow-auto"}>
                            <div className="flex flex-col w-full overflow-auto">
                                {isStudentLoading ? (
                                    <div className="h-[70vh]">
                                        <Spinner />
                                    </div>

                                ) : (
                                    <Tabs className={"flex flex-col w-full overflow-auto h-[71vh] gap-4"}>
                                        <div className="flex w-full justify-between">
                                            {activeSection === 'Courses' && (
                                                <div className="relative">
                                                    <button
                                                        onClick={() => setIsOpen(!isOpen)}
                                                        className="flex items-center justify-between px-4 py-2 gap-1 text-gray-700 bg-white-A700 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                                                    >
                                                        <div className="flex items-center text-sm truncate">
                                                            <span className="">{selectedOption}</span>
                                                        </div>
                                                        <svg
                                                            className={`w-4 h-4 transform ${isOpen ? 'rotate-180' : 'rotate-0'}`}
                                                            fill="none"
                                                            stroke="currentColor"
                                                            viewBox="0 0 24 24"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M19 9l-7 7-7-7"
                                                            ></path>
                                                        </svg>
                                                    </button>
                                                    {isOpen && (
                                                        <div className="absolute z-10 w-full mt-2 bg-white-A700 border border-gray-300 rounded-lg shadow-md">
                                                            {studentDetails && studentDetails.courses.map((option) => (
                                                                <div
                                                                    key={option.course.id}
                                                                    onClick={() => handleOptionClick(option)}
                                                                    className="flex items-center rounded-lg p-4 text-gray-700 cursor-pointer bg-white-A700 hover:bg-gray-100"
                                                                >
                                                                    <span>{option.course.name}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            <TabList className={"flex flex-grow justify-end"}>
                                                {['Courses', 'Stock Simulator', 'Startup Simulator'].map((section) => (
                                                    <Tab
                                                        key={section}
                                                        onClick={() => handleActiveSection(section)}
                                                        className={`px-4 py-2 rounded-full ${activeSection === section ? 'bg-blue-50 text-blue-500' : 'text-gray-700'} border-gray-300 focus:outline-none`}
                                                    >
                                                        {section}
                                                    </Tab>
                                                ))}
                                            </TabList>
                                        </div>

                                        <TabPanel className={"overflow-auto"}>
                                            <div className="flex flex-col gap-4">
                                                <div className="flex gap-2 justify-between">
                                                    <div className="flex flex-col w-[20%] justify-between gap-2">
                                                        <div className="flex flex-col items-center text-center justify-between gap-2 p-4 border rounded-lg">
                                                            <span className="text-md font-semibold">
                                                                Total Course Completion
                                                            </span>
                                                            <span className="font-bold text-6xl">
                                                                {calculateCourseCompletion(analyticsData)}%
                                                            </span>
                                                            <div className="w-48 bg-gray-200 rounded-full h-6">
                                                                <div
                                                                    className="bg-blue-500 h-6 rounded-full"
                                                                    style={{ width: `${calculateCourseCompletion(analyticsData)}%` }}
                                                                ></div>

                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col items-center text-center justify-between gap-2 p-4 border rounded-lg">
                                                            <span className="text-md font-semibold">
                                                                Total Minutes Spent
                                                            </span>
                                                            <span className="font-bold text-6xl">
                                                                {calculateTotalMinutesSpent(analyticsData)}
                                                            </span>
                                                            <span className="font-semibold">minutes</span>
                                                        </div>

                                                        <div className="flex flex-col items-center text-center justify-between gap-2 p-4 border rounded-lg">
                                                            <span className="text-md font-semibold">
                                                                Most Incorrect Questions
                                                            </span>
                                                            {getSectionWithMostIncorrectQuestions(analyticsData) ? (
                                                                <>
                                                                    <span className="font-bold text-6xl">
                                                                        {getSectionWithMostIncorrectQuestions(analyticsData).incorrectCount}
                                                                    </span>
                                                                    <span className="font-semibold">
                                                                        {getSectionWithMostIncorrectQuestions(analyticsData).sectionName}
                                                                    </span>
                                                                    <span className="text-sm">
                                                                        {getSectionWithMostIncorrectQuestions(analyticsData).unitName}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <span>No incorrect questions</span>
                                                            )}
                                                        </div>

                                                        <div className="flex flex-col items-center text-center justify-between gap-2 p-4 border rounded-lg">
                                                            <span className="text-md font-semibold">
                                                                Fastest Section Completed
                                                            </span>
                                                            <span className="font-bold text-6xl">
                                                                {calculateFastestSection(analyticsData).time}
                                                            </span>
                                                            <span className="font-semibold">minutes</span>
                                                            <span className="font-semibold">
                                                                {calculateFastestSection(analyticsData).name}
                                                            </span>
                                                        </div>

                                                    </div>


                                                    <div className="flex flex-col justify-between gap-2 w-[79%]">
                                                        <span className="border rounded-lg p-4"><AveragePerformanceChart analyticsData={analyticsData} /></span>

                                                        <span className="border rounded-lg p-4"><TimeCompletionChart analyticsData={analyticsData} /></span>
                                                    </div>
                                                </div>

                                                <div className="">
                                                    <Accordion allowMultipleExpanded allowZeroExpanded className="border rounded-lg"> {/* UNITS */}
                                                        {analyticsData.map((unit, unitIndex) => (
                                                            <AccordionItem
                                                                key={`unit-${unitIndex}`}
                                                                uuid={`unit-${unitIndex}`}
                                                                className="p-2 gap-4 flex flex-col"
                                                            >
                                                                <AccordionItemHeading> {/* UNIT HEADER */}
                                                                    <AccordionItemButton>
                                                                        <AccordionItemState>
                                                                            {({ expanded }) => (
                                                                                <div className="flex items-center justify-between px-4 py-2">
                                                                                    <div className="flex items-center gap-2 transition">
                                                                                        <IconChevronDown
                                                                                            size={16}
                                                                                            className={`transform transition-transform duration-200 ${expanded ? 'rotate-180' : 'rotate-0'
                                                                                                }`} />
                                                                                        <span className="font-medium">{unit.unitName}</span>
                                                                                    </div>
                                                                                    <div className="flex items-center gap-2">
                                                                                        <span className="text-sm font-semibold">
                                                                                            {calculateUnitCompletion(unit)}%
                                                                                        </span>
                                                                                        <div className="w-24 bg-gray-200 rounded-full h-2">
                                                                                            <div
                                                                                                className="bg-blue-500 h-2 rounded-full"
                                                                                                style={{ width: `${calculateUnitCompletion(unit)}%` }}
                                                                                            ></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </AccordionItemState>
                                                                    </AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel className="bg-gray-200 rounded-lg p-2 gap-4">
                                                                    <Accordion
                                                                        allowMultipleExpanded
                                                                        allowZeroExpanded
                                                                    > {/* SECTIONS */}
                                                                        {unit.sections.map((section, sectionIndex) => (
                                                                            <AccordionItem
                                                                                key={`section-${unitIndex}-${sectionIndex}`}
                                                                                uuid={`section-${unitIndex}-${sectionIndex}`}
                                                                                className="p-2 flex flex-col border border-gray-300 rounded-lg mb-4 bg-white-A700 gap-4"
                                                                            >
                                                                                <AccordionItemHeading> {/* SECTION HEADER */}
                                                                                    <AccordionItemButton>
                                                                                        <AccordionItemState>
                                                                                            {({ expanded }) => (
                                                                                                <div
                                                                                                    className="grid grid-cols-4 gap-4 p-2"
                                                                                                    style={{ gridTemplateColumns: '2fr 1fr 1fr 1fr' }}
                                                                                                >
                                                                                                    <div className="flex items-center gap-2 transition">
                                                                                                        <IconChevronDown
                                                                                                            size={16}
                                                                                                            className={`transform transition-transform duration-200 ${expanded ? 'rotate-180' : 'rotate-0'
                                                                                                                }`} />
                                                                                                        <span>{section.sectionName}</span>
                                                                                                    </div>
                                                                                                    <span className="text-center">
                                                                                                        {getStatus(section.progress)}
                                                                                                    </span>
                                                                                                    <span className="text-center">
                                                                                                        {roundToTenth(section.completedSectionInMinutes)} min
                                                                                                    </span>
                                                                                                    <span className="text-center">{section.progress > 1
                                                                                                        ? `4/${section.missedQuestions.length < 4 ? 4 : section.missedQuestions.length} Question Attempts`
                                                                                                        : "No Completed Quiz"}</span>
                                                                                                </div>
                                                                                            )}
                                                                                        </AccordionItemState>
                                                                                    </AccordionItemButton>
                                                                                </AccordionItemHeading>

                                                                                <AccordionItemPanel className="bg-white-A700 rounded-lg">
                                                                                    {/* Incorrect Questions Table */}
                                                                                    {section.missedQuestions && section.missedQuestions.some(question => !question.correct) ? (
                                                                                        <div className="p-2">
                                                                                            <h3 className="text-md font-semibold p-4 text-red-600">Incorrect Questions</h3>

                                                                                            {/* Grid Headers */}
                                                                                            <div
                                                                                                className="grid grid-cols-4 p-4 font-bold bg-gray-100 border-b border-gray-300"
                                                                                                style={{ gridTemplateColumns: '1.5fr 1fr 1fr 1fr' }}
                                                                                            >
                                                                                                <div className="border-r border-gray-300 px-2">Question</div>
                                                                                                <div className="border-r border-gray-300 px-2">Student's Answer</div>
                                                                                                <div className="border-r border-gray-300 px-2">Correct Answer</div>
                                                                                                <div className="px-2 text-center">Result</div>
                                                                                            </div>

                                                                                            {/* Grid Rows for Incorrect Questions */}
                                                                                            {section.missedQuestions
                                                                                                .filter(question => !question.correct) // Filter for incorrect questions
                                                                                                .map((question, index) => (
                                                                                                    <div
                                                                                                        key={index}
                                                                                                        className={`grid grid-cols-4 p-4 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100`}
                                                                                                        style={{ gridTemplateColumns: '1.5fr 1fr 1fr 1fr' }}
                                                                                                    >
                                                                                                        <div className="border-r border-gray-300 px-2">{question.questionText}</div>
                                                                                                        <div className="border-r border-gray-300 px-2">{question.selectedText}</div>
                                                                                                        <div className="border-r border-gray-300 px-2">{question.correctText}</div>
                                                                                                        <div className="px-2 text-center">
                                                                                                            <span className="text-red-600 font-semibold">Incorrect</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <p className="p-2">No incorrect questions in this section.</p>
                                                                                    )}

                                                                                    {/* Correct Questions Table */}
                                                                                    {section.missedQuestions && section.missedQuestions.some(question => question.correct) ? (
                                                                                        <div className="p-2 mt-6">
                                                                                            <h3 className="text-md font-semibold p-4 text-green-600">Correct Questions</h3>

                                                                                            {/* Grid Headers */}
                                                                                            <div
                                                                                                className="grid grid-cols-3 p-4 font-bold bg-gray-100 border-b border-gray-300"
                                                                                                style={{ gridTemplateColumns: '1fr 1fr 1fr' }} // 3 columns without correctText
                                                                                            >
                                                                                                <div className="border-r border-gray-300 px-2">Question</div>
                                                                                                <div className="border-r border-gray-300 px-2">Student's Answer</div>
                                                                                                <div className="px-2 text-center">Result</div>
                                                                                            </div>

                                                                                            {/* Grid Rows for Correct Questions */}
                                                                                            {section.missedQuestions
                                                                                                .filter(question => question.correct) // Filter for correct questions
                                                                                                .map((question, index) => (
                                                                                                    <div
                                                                                                        key={index}
                                                                                                        className={`grid grid-cols-3 p-4 ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100`}
                                                                                                        style={{ gridTemplateColumns: '1fr 1fr 1fr' }} // Adjust columns for correct answers
                                                                                                    >
                                                                                                        <div className="border-r border-gray-300 px-2">{question.questionText}</div>
                                                                                                        <div className="border-r border-gray-300 px-2">{question.selectedText}</div>
                                                                                                        <div className="px-2 text-center">
                                                                                                            <span className="text-green-600 font-semibold">Correct</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ))}
                                                                                        </div>
                                                                                    ) : (
                                                                                        <p className="p-2">No correct questions in this section.</p>
                                                                                    )}

                                                                                </AccordionItemPanel>

                                                                            </AccordionItem>
                                                                        ))}
                                                                    </Accordion>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                        ))}
                                                    </Accordion>
                                                </div>

                                            </div>

                                        </TabPanel>
                                        <TabPanel className={"overflow-auto"}>
                                            <StudentStockSimulator studentId={student.id} />
                                        </TabPanel>
                                        <TabPanel className={"overflow-auto"}>
                                            <StudentStartupSimulator studentId={student.id} />
                                        </TabPanel>
                                    </Tabs>
                                )}
                            </div>
                        </TabPanel>
                    ))}
                </div>
            </div>
        </Tabs>
    );
}

// CSS for the spinner
const style = `
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
}

.spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
`;

const styleSheet = document.createElement("style");
styleSheet.innerText = style;
document.head.appendChild(styleSheet);