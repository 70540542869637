import React from "react";
import { Helmet } from "react-helmet";
import { Img, Button, Text, Heading } from "../../components";
import Header from "../../components/Header";
import Sidebar1 from "../../components/Sidebar1";
import { useNavigate } from 'react-router-dom'
import { ReactTable } from "../../components/ReactTable";
import { createColumnHelper } from "@tanstack/react-table";
import { Link } from 'react-router-dom';

export default function InstructorPage() {
  const navigate = useNavigate();

  const handleAllClassesClick = () => {
    navigate('/teacherhome');
  };

  const handleAssignedCoursesClick = () => {
    navigate('/assignedcourses');
  };

  const handleStudentsClick = () => {
    navigate('/students');
  };

  const handleInstructorClick = () => {
    navigate('/instructor');
  };

  const handleAssignmentClick = () => {
    navigate('/assignments');
  };

  const handleStudentsPerformanceClick = () => {
    navigate('/performancecourses');
  };

  const table6Data = [
    { firstName: "John", lastName: "Doe", gradeLevel: "9", difficulty: "Medium", lastActivity: "January 1, 2024", dots: ":" },
    { firstName: "Michael", lastName: "Scott", gradeLevel: "10", difficulty: "Low", lastActivity: "January 1, 2024", dots: ":" },
    { firstName: "Steve", lastName: "Harvey", gradeLevel: "12", difficulty: "High", lastActivity: "January 1, 2024", dots: ":" },
  ];

  const table6Columns = React.useMemo(() => {
    const table6ColumnHelper = createColumnHelper();
    return [
      table6ColumnHelper.accessor("firstName", {
        cell: (info) => (
          <div className="flex w-full justify-between ml-20 items-center !text-blue-500 border-solid border-blue_gray-100">
            <Link to={`/Performancecourses`} className="text-blue-500 hover:text-blue-600">
              {info.getValue()}
            </Link>
          </div>
        ),
        header: () => "First Name",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("lastName", {
        cell: (info) => (
          <div className="flex w-full justify-between ml-20 items-center !text-blue-500 border-solid border-blue_gray-100">
            <Link to={`/Performancecourses`} className="text-blue-500 hover:text-blue-600">
              {info.getValue()}
            </Link>
          </div>
        ),
        header: () => "Last Name",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("gradeLevel", {
        cell: (info) => <div className="flex w-full justify-between ml-24 items-center border-solid border-blue_gray-100">{info.getValue()}</div>,
        header: () => "Grade Level",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("difficulty", {
        cell: (info) => <div className="flex w-full justify-between ml-16 items-center border-solid border-blue_gray-100">{info.getValue()}</div>,
        header: () => "Difficulty",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("lastActivity", {
        cell: (info) => <div className="flex w-full justify-between ml-11 items-center border-solid border-blue_gray-100">{info.getValue()}</div>,
        header: () => "Last Online Activity",
        meta: { width: "full" },
      }),
      table6ColumnHelper.accessor("dots", {
        cell: (info) => <div className="flex w-full justify-between ml-11 items-center border-solid border-blue_gray-100 cursor-pointer">{info.getValue()}</div>,
        header: () => "",
        meta: { width: "full" },
      }),
    ];
  }, []);

  return (
    
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-center bg-white-A700">
        <Sidebar1 />
        <div className="flex flex-col w-full p-5">
          <div>
            <div className="flex flex-col">
              <div className="flex self-start px-4 py-3.5">
                <div className="flex gap-2">
                  <Img src="images/img_arrow_left_blue_a200.svg" alt="arrowleft" className="h-[20px]" />
                  <div className="flex">
                    <Heading as="h1" className="!text-blue-500 mt-[2px] cursor-pointer" onClick={handleAllClassesClick}>
                      All Classes
                    </Heading>
                  </div>
                </div>
              </div>
              <div className="relative mt-[-3px] flex items-center justify-between gap-5 px-4 sm:flex-col">
                <div className="flex flex-col items-start gap-0.5">
                  <Heading size="11xl" as="h2" className="!font-poppins !text-gray-900_05 pb-4">
                    Class Financial 101
                  </Heading>
                  <div className="flex flex-wrap self-center pb-4">
                    <Text as="p" className="self-start tracking-[0.25px] !text-gray-800_01">
                      20 students
                    </Text>
                    <Text as="p" className="ml-3 self-end tracking-[0.25px] !text-gray-800_01">
                      |
                    </Text>
                    <Text as="p" className="ml-[15px] self-start tracking-[0.25px] !text-gray-800_01">
                      Grade 4th
                    </Text>
                    <Text as="p" className="ml-4 self-end tracking-[0.25px] !text-gray-800_01">
                      |
                    </Text>
                    <Text as="p" className="ml-1.5 self-end tracking-[0.25px] !text-gray-800_01">
                      Jan, 24 2024
                    </Text>
                  </div>
                </div>
                <div className="mb-2 flex gap-4 self-end">
                  <Button color="blue_gray_100" size="2xl" variant="outline" shape="round" className="w-[40px] border">
                    <Img src="images/img_search.svg" />
                  </Button>
                  <Button
                    size="3xl"
                    shape="round"
                    leftIcon={<Img src="images/img_plus_white_a700.svg" alt="plus" className="h-[18px] w-[18px]" />}
                    className="min-w-[157px] gap-2 font-roboto font-medium tracking-[0.10px] sm:pr-5"
                  >
                    Add Instructor
                  </Button>
                </div>
              </div>
              <div className="flex px-4 sm:flex-col" style={{ marginBottom: '-10px'}}>
                      <div className="flex px-4 pb-3.5 pt-4 cursor-pointer" onClick={handleAssignedCoursesClick}>
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01">
                            Assigned courses
                          </Text>
                        </div>
                        <div className="flex p-[15px]">
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer"  onClick={handleStudentsClick}>
                            Students
                          </Text>
                        </div>
                        <div className="flex px-4">
                          <div className="flex py-[15px]">
                            <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-blue-A200 cursor-pointer"  onClick={handleInstructorClick}>
                              Instructors
                            </Text>
                          </div>
                        </div>
                        <div className="flex px-4 pb-3.5 pt-4">
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer" onClick={handleAssignmentClick}>
                            Assignment
                          </Text>
                        </div>
                        <div className="flex w-[13%] flex-col items-center px-[15px] pt-[15px] sm:w-full">
                          <div className="flex w-full flex-col">
                            <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer" onClick={handleStudentsPerformanceClick}>
                              Student Performance
                            </Text>
                          </div>
                        </div>
                      </div>
                      <hr className="w-full border-gray-300" />
              <div>
                <div className="px-4 pb-[62px] pt-4 md:pb-5">
                  <div className="flex flex-col justify-center gap-2 pb-[575px] md:pb-5">
                    <div className="flex gap-2 self-end">
                      <Button
                        size="lg"
                        variant="outline"
                        shape="round"
                        className="min-w-[166px] font-roboto font-medium tracking-[0.10px] sm:px-5"
                      >
                        Create Credentials
                      </Button>
                      <div className="flex flex-col items-center p-1">
                        <Img src="images/img_notification.svg" alt="notification" className="h-[24px] w-[24px]" />
                      </div>
                    </div>
                    <div className="p-1 w-[97%] rounded-lg border border-solid border-blue_gray-50_01 md:w-full" style={{ boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)', margin: '5px' }}>
                          <ReactTable
                            size="xl"
                            bodyProps={{ className: "w-full" }}
                            headerProps={{
                              className: "w-full border-blue_gray-100 border-b border-solid bg-gray-50_01 md:flex-col",
                            }}
                            rowDataProps={{ className: "w-full border-t border-b border-solid border-blue_gray-100" }}
                            className="w-full"
                            columns={table6Columns}
                            data={table6Data}
                          />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
