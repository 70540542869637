import React, { useEffect, useRef } from 'react';

const CBYB = ({ stockSymbol, theme = 'light' }) => {
    useEffect(() => {
        // Load the Trendlyne widget script
        const script = document.createElement('script');
        script.src = "https://cdn-static.trendlyne.com/static/js/webwidgets/tl-widgets.js";
        script.async = true;
        script.charset = "utf-8";
        document.body.appendChild(script);

        // Clean up script on component unmount
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className='w-[500px]'>
            <blockquote
                className="trendlyne-widgets"
                data-get-url={`https://trendlyne.com/us/web-widget/checklist-widget/Poppins/${stockSymbol}/?posCol=00A25B&primaryCol=006AFF&negCol=EB3B00&neuCol=F7941E`}
                data-theme={theme}
            ></blockquote>
        </div>

    );
};

export default CBYB;

