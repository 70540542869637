import React from "react";
import { Heading } from "components";
import Chart from "react-apexcharts";

export default function MonthlySpendingChart() {
    const series = [30, 30, 10, 15, 15];
    const labels = ["Food", "Housing", "Travel", "Fun", "Others"];
    const colors = ["#3b00ed", "#9a26ad", "#d81b60", "#ff9800", "#d7d7d7"];

    const options = {
        series: series,
        chart: {
            type: 'donut',
            width: '100%', // Make it responsive to the parent container's width
            height: '100%', // Set a default height
            redrawOnParentResize: true, // Ensures resizing happens with container
            redrawOnWindowResize: true // Resize on window changes
        },
        labels: labels,
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: true,
            position: 'right',
            horizontalAlign: 'center',
            fontSize: '14px',
            padding: 4,
        },
        responsive: [
            {
                breakpoint: 1200, // Mobile breakpoint
                options: {
                    legend: {
                        position: 'bottom',
                    },
                },
            },
        ],
    };

    return (
        <div className="flex flex-col w-full  rounded-lg border border-solid bg-white-A700">
            <h1 className="flex items-center gap-2 p-4 border-b text-sm font-semibold">
                Monthly Spending
            </h1>
            <div className="flex w-full items-center justify-center">
                <Chart options={options} series={series} type="donut" height="100%" />
            </div>
        </div>
    );
}
