import React, { useState, useEffect } from 'react';
import { formatUSD } from 'utils';

const sessionBalancesTitles = {
    checkingAccount: {
        title: "Checking Account",
        img: "/images/img_bank.svg" // Path to your checking account icon
    },
    savingsAccount: {
        title: "Savings Account",
        img: "/images/img_bank.svg" // Path to your savings account icon
    },
    creditCard: {
        title: "Credit Card",
        img: "/images/img_credit_card_02.svg" // Path to your credit card icon
    },
    netCash: {
        title: "Net Cash",
        img: "/images/img_bank_note_01.svg" // Path to your net cash icon
    }
};

const BalancesBar = ({ balances }) => {
    useEffect(() => {
        // Any setup or data fetching logic can go here
        console.log(balances)
    }, []);

    return (
        <div className='flex w-full h-20 rounded-lg bg-white-A700 border divide-x divide-gray-200 font-inter'>
            {/* Only display the accountBalance as Checking Account */}
            <div className='flex w-full items-center p-4 justify-between'>
                <h1 className='flex items-center gap-4'>
                    <span>
                        <img
                            src={sessionBalancesTitles.checkingAccount.img}
                            alt='Checking Account'
                            className='h-10 w-10 p-2 rounded-full bg-slate-100 hidden md:block'
                        />
                    </span>
                    {sessionBalancesTitles.checkingAccount.title}
                </h1>
                <h2 className={`${balances < 0 ? 'text-red-600' : 'text-green-600'}`}>
                    {formatUSD(balances)}
                </h2>
            </div>
            {/* Keep placeholders for other balances with no data */}
            {['savingsAccount', 'creditCard', 'netCash'].map((key) => (
                <div key={key} className='flex w-full items-center p-4 justify-between'>
                    <h1 className='flex items-center gap-4'>
                        <span>
                            <img
                                src={sessionBalancesTitles[key].img}
                                alt={sessionBalancesTitles[key].title}
                                className='h-10 w-10 p-2 rounded-full bg-slate-100 hidden lg:block'
                            />
                        </span>
                        {sessionBalancesTitles[key].title}
                    </h1>
                    <h2 className='text-gray-400'>N/A</h2>
                </div>
            ))}
        </div>
    );
};

export default BalancesBar;
