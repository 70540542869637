import React, { useState, useEffect, useMemo } from "react";
import { Text, Button, Heading } from "../../components";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from 'react-router-dom';
import { fetchStudentPortfolioGraph } from "SimulatorAPIService";
import { deleteStudent } from "TeacherAPIService";
import { ReactTableHoldings } from "components/ReactTableHoldings";
import QuickLink from "components/QuickLink";
import { IconSortAscendingLetters, IconSortDescendingLetters, IconDotsVertical } from '@tabler/icons-react';
import Popup from 'reactjs-popup';  // Import reactjs-popup

const Spinner = () => (
    <div className="spinner-container">
        <div className="spinner"></div>
    </div>
);

const Students = ({ classroomData }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isAscending, setIsAscending] = useState(true);
    const [sortedStudents, setSortedStudents] = useState([]);
    const [loadingBalances, setLoadingBalances] = useState(true);
    const [deletingStudentId, setDeletingStudentId] = useState(null);
    const [openConfirmPopup, setOpenConfirmPopup] = useState(false); // For opening confirm popup
    const [openAlertPopup, setOpenAlertPopup] = useState(false); // For opening alert popup
    const [alertMessage, setAlertMessage] = useState(""); // Store alert message
    const [studentToDelete, setStudentToDelete] = useState(null); // Track student to delete
    const [hasActivityError, setHasActivityError] = useState(false); // Track if there's activity error
    const [secondConfirmation, setSecondConfirmation] = useState(false); // Track second confirmation

    const navigate = useNavigate();

    const handleSelectRow = (id) => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.includes(id)
                ? prevSelectedRows.filter((rowId) => rowId !== id)
                : [...prevSelectedRows, id]
        );
    };

    const handleSelectAllRows = () => {
        if (selectedRows.length === sortedStudents.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(sortedStudents.map((student) => student.id));
        }
    };

    useEffect(() => {
        const fetchBalances = async () => {
            const balancePromises = classroomData.students.map(async (student) => {
                try {
                    const portfolioData = await fetchStudentPortfolioGraph(student.id);

                    if (portfolioData.status === 401) {
                        navigate('/timeout');
                        return { ...student, balance: 0 }; // If unauthorized, assume balance 0
                    }

                    return { ...student, balance: portfolioData.currentBalance };
                } catch (error) {
                    console.error(`Failed to fetch balance for student ${student.id}`, error);
                    return { ...student, balance: 0 }; // Default to 0 balance on failure
                }
            });

            const updatedStudents = await Promise.all(balancePromises);
            // Filter out students with a balance of 0
            const filteredStudents = updatedStudents.filter((student) => student.balance !== 0);
            setSortedStudents(filteredStudents);
            setLoadingBalances(false);
        };

        fetchBalances();
    }, [classroomData.students, navigate]);

    const handleSort = () => {
        const sorted = [...sortedStudents].sort((a, b) => {
            if (isAscending) {
                return a.last.localeCompare(b.last);
            } else {
                return b.last.localeCompare(a.last);
            }
        });
        setSortedStudents(sorted);
        setIsAscending(!isAscending);
    };

    const handleCreateStudentsClick = () => {
        navigate('/createstudents', { state: { classroomId: classroomData.id } });
    };

    const handleDeleteStudent = async () => {
        if (deletingStudentId) {
            // Prevent multiple deletions at the same time
            return;
        }

        setDeletingStudentId(studentToDelete);

        try {
            await deleteStudent(studentToDelete, false);
            // Remove the student from the list
            setSortedStudents((prevStudents) => prevStudents.filter((student) => student.id !== studentToDelete));

            setAlertMessage('The student has been removed from the classroom.');
            setOpenAlertPopup(true);
            setHasActivityError(false); // Reset activity error flag
            setSecondConfirmation(false); // Reset second confirmation flag
        } catch (error) {
            if (error.status === 500) {
                // Handle the 500 error and show second confirmation
                setAlertMessage('This student has activity in this classroom. Are you sure you want to proceed with deletion?');
                setHasActivityError(true);
                setSecondConfirmation(true); // Set second confirmation flag
                setOpenConfirmPopup(true);
            } else {
                console.error('Error deleting student:', error);
                setAlertMessage(`Failed to delete student: ${error.message}`);
                setOpenAlertPopup(true);
            }
        } finally {
            setDeletingStudentId(null);
        }
    };

    const handleDeleteClick = (studentId) => {
        setStudentToDelete(studentId);
        setOpenConfirmPopup(true);
        setAlertMessage('Are you sure you want to delete this student from the classroom? This does not delete the student\'s account. You will not be able to undo this action.');
    };

    const handleCancelDelete = () => {
        setOpenConfirmPopup(false);
        setOpenAlertPopup(false);
        setSecondConfirmation(false);
    }

    const confirmDeleteWithActivity = async () => {
        try {
            await deleteStudent(studentToDelete, true);
            // Remove the student from the list
            setSortedStudents((prevStudents) => prevStudents.filter((student) => student.id !== studentToDelete));

            setAlertMessage('The student has been removed from the classroom.');
            setOpenAlertPopup(true);
            setHasActivityError(false); // Reset activity error flag
            setSecondConfirmation(false); // Reset second confirmation flag
        } catch (error) {
            // Handle the 500 error and show second confirmation
            setAlertMessage('Deletion failed. Please refresh the page and try again.');
            setSecondConfirmation(false);
        } finally {
            setDeletingStudentId(null);
            setOpenConfirmPopup(false); // Close the confirmation popup
            setSecondConfirmation(false);
        }
    };

    const table1Columns = useMemo(() => {
        const table1ColumnHelper = createColumnHelper();
        return [
            table1ColumnHelper.display({
                id: "selection",
                header: ({ table }) => (
                    <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        checked={selectedRows.length === sortedStudents.length}
                        onChange={handleSelectAllRows}
                    />
                ),
                cell: (info) => (
                    <input
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                        checked={selectedRows.includes(info.row.original.id)}
                        onChange={() => handleSelectRow(info.row.original.id)}
                    />
                ),
                meta: { width: "55px" },
            }),
            table1ColumnHelper.accessor((row) => `${row.first} ${row.last}`, {
                id: 'name',
                cell: (info) => (
                    <div className="items-start text-left">
                        <Text size="7xl" as="p" className="tracking-[0.10px]">
                            {info?.getValue?.()}
                        </Text>
                    </div>
                ),
                header: (info) => (
                    <div className="flex justify-between items-center">
                        <Heading size="4xl" as="h3" className="!text-blue_gray-500 items-start text-left">
                            Name
                        </Heading>
                    </div>
                ),
                meta: { width: "full" },
            }),
            table1ColumnHelper.accessor((row) => row.balance, {
                id: 'balance',
                header: (info) => (
                    <Heading size="4xl" as="h3" className="!text-blue_gray-500 items-center text-center">
                        Current Balance
                    </Heading>
                ),
                cell: (info) => (
                    <div className="items-center text-center">
                        <Text size="7xl" as="p" className="tracking-[0.10px]">
                            {info.getValue() !== undefined ? `$${info.getValue().toFixed(2)}` : "Loading..."}
                        </Text>
                    </div>
                ),
                meta: { width: "full" },
            }),
            // Add a column for the three-dot icon with no header
            table1ColumnHelper.display({
                id: 'menu',
                header: '', // No header for the three-dot icon
                cell: (info) => (
                    <div className="flex justify-center">
                        <Popup
                            trigger={
                                <button className="text-gray-500 hover:text-gray-700">
                                    <IconDotsVertical size={24} />
                                </button>
                            }
                            position="left top"
                            on="click"
                            closeOnDocumentClick
                        >
                            <div className="menu">
                                <button
                                    onClick={() => handleDeleteClick(info.row.original.id)}
                                    className="text-red-500 hover:text-red-800"
                                    disabled={deletingStudentId === info.row.original.id}
                                >
                                    Delete Student from Classroom
                                </button>
                            </div>
                        </Popup>
                    </div>
                ),
                meta: { width: "55px" },
            }),
        ];
    }, [selectedRows, sortedStudents, isAscending, deletingStudentId]);

    return (
        <div className="flex md:overflow-auto">
            <div className="flex flex-col w-[85%] h-[75vh] gap-4 p-4 md:w-full">
                <div className="flex w-full gap-2 justify-between">
                    <div className="flex gap-4">
                        <Button
                            size="3xl"
                            variant="outline"
                            shape="round"
                            className="min-w-[166px] font-roboto font-medium tracking-[0.10px] sm:px-5"
                            onClick={handleCreateStudentsClick}
                        >
                            Create Credentials
                        </Button>
                    </div>
                </div>

                <div className="flex flex-col h-full w-full border border-gray-300 rounded-lg overflow-hidden">
                    <div className="flex justify-end p-3 border-b">
                        <button onClick={handleSort} className="text-blue-500">
                            {isAscending ? <IconSortDescendingLetters /> : <IconSortAscendingLetters />}
                        </button>
                    </div>
                    <div className="overflow-auto">
                        {loadingBalances ? (
                            <div className="h-[70vh]">
                                <Spinner />
                            </div>
                        ) : (
                            <ReactTableHoldings
                                size="lg"
                                bodyProps={{ className: "" }}
                                headerProps={{
                                    className: "rounded-lg border-blue_gray-50_01 border-b border-solid bg-gray-50_01 md:flex-col",
                                }}
                                rowDataProps={{ className: "md:flex-col border-b h-[75px]" }}
                                className="w-full rounded-lg"
                                columns={table1Columns}
                                data={sortedStudents}
                            />
                        )}
                    </div>
                </div>
            </div>

            <div className="w-px min-h-[90vh] bg-gray-300"></div>

            <div className="flex flex-col w-[25%] h-full m-4 gap-5 md:w-[0%] md:hidden">
                <QuickLink classroomData={classroomData} />
            </div>

            {/* Confirmation Popup for Deletion */}
            <Popup open={openConfirmPopup} onClose={() => setOpenConfirmPopup(false)} modal>
                <div className="modal flex flex-col m-6 items-left justify-center gap-6 rounded-lg">
                    <div className="font-bold text-2xl text-blue-500">
                        {secondConfirmation ? '\u2757 Confirm Removal' : '\u2757 Remove Student From Classroom?'}
                    </div>

                    <div className="content">
                        {/* {alertMessage} */}
                        {!secondConfirmation && (
                            <div className="flex flex-col text-center gap-4">
                                <div className="flex flex-col gap-2">
                                    <h1 className="text-lg font-semibold">Are you sure you want to remove this student from the classroom?</h1>
                                    <h2 className="text-md text-red-500"> You will <span className="font-semibold underline">not</span> be able to undo this action.</h2>
                                </div>
                                <h2 className="text-sm italic">This does not delete the student's account, it only removes the student from the classroom.</h2>
                            </div>
                        )}

                        {secondConfirmation && (
                            <div className="flex flex-col text-center gap-4">
                                <div className="flex flex-col gap-4">
                                    <h1 className="text-lg font-semibold">This student has previous activity in this classroom.</h1>
                                    <h2 className="text-md text-red-500"> Are you sure you would like to proceed with removal?</h2>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="actions flex items-center justify-center gap-4">
                        {secondConfirmation && (
                            <Button onClick={confirmDeleteWithActivity} className="btn min-w-[64px] min-h-[32px] max-h-[64px] rounded-lg bg-blue-500 hover:bg-red-400">
                                Yes
                            </Button>
                        )}
                        {!secondConfirmation && (
                            <Button onClick={handleDeleteStudent} className="btn min-w-[64px] min-h-[32px] max-h-[64px] rounded-lg bg-blue-500 hover:bg-red-400">
                                Yes
                            </Button>
                        )}
                        <Button onClick={handleCancelDelete} className="btn min-w-[64px] min-h-[32px] max-h-[64px] rounded-lg bg-transparent text-blue-500 border-2 border-blue-500 hover:border-blue-400 hover:text-blue-400">
                            Cancel
                        </Button>
                    </div>
                </div>
            </Popup>

            {/* Alert Popup */}
            <Popup open={openAlertPopup} onClose={() => setOpenAlertPopup(false)} modal>
                <div className="modal flex flex-col m-6 items-left justify-center gap-6 rounded-lg">
                    <div className="font-bold text-2xl"> Deletion Successful </div>
                    <div className="content">
                        {alertMessage}
                    </div>
                    <div className="actions flex items-center justify-center gap-4">
                        <Button onClick={handleCancelDelete} className="btn min-w-[64px] min-h-[32px] max-h-[64px] rounded-lg bg-transparent text-blue-500 border-2 border-blue-500 hover:border-blue-400 hover:text-blue-400">
                            Close
                        </Button>
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default Students;
