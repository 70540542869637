// ConfirmEmailPage.jsx

import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Text, Img, Heading, Button } from "../../../../components";
import TopNavLogin from "components/TopNavLogin";
import LoginSideGraphic from "components/LoginSideGraphic";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;


export default function ConfirmEmailPage() {
    const [status, setStatus] = useState("pending");
    const [message, setMessage] = useState("");

    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const userId = searchParams.get("userId");

    const navigate = useNavigate();

    useEffect(() => {
        // Retrieve JWT from localStorage or sessionStorage
        const jwt = localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken");

        if (!jwt) {
            // If JWT is not found, redirect to login or show an error
            setStatus("error");
            setMessage("Authentication token not found. Please log in.");
            // Optionally, redirect to login page after a delay
            setTimeout(() => {
                navigate("/login");
            }, 3000);
            return;
        }

        // Define the API URL with userId and token (confirmation code)
        const apiUrl = `${API_ENDPOINT}/confirmEmail?userId=${userId}&code=${token}`;

        // Make the API request with JWT in Authorization header
        fetch(apiUrl, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${jwt}`, // Include JWT in headers
            },
        })
            .then(async (response) => {
                if (response.ok) {
                    setStatus("success");
                    setMessage("Email confirmation successful!");

                    // Optionally, redirect to dashboard or another page after success
                    setTimeout(() => {
                        navigate("/dashboard");
                    }, 3000);
                } else {
                    // Attempt to parse error message from response
                    let errorDetail = "There was an issue confirming your email. Contact support or try again.";
                    try {
                        const errorData = await response.json();
                        if (errorData.detail) {
                            errorDetail = errorData.detail;
                        }
                    } catch (e) {
                        console.error("Error parsing error response:", e);
                    }
                    setStatus("error");
                    setMessage(errorDetail);
                }
            })
            .catch((error) => {
                // Handle network or other errors
                console.error("Network error:", error);
                setStatus("error");
                setMessage("There was an issue confirming your email. Contact support or try again.");
            });
    }, [userId, token, navigate]);

    return (
        <>
            <Helmet>
                <title>Confirm Email | Intertwined</title>
                <meta name="description" content="Confirm your email address to complete registration." />
            </Helmet>
            <div className="flex h-screen overscroll-none overflow-hidden m-0">
                <div className="w-[75%] bg-white-A700 md:w-full relative">
                    <TopNavLogin className="absolute top-0 left-0 right-0 z-10" type={"register"} />

                    <div className="flex-1 flex flex-col gap-16 h-full justify-center items-center">
                        <div className="gap-3 flex flex-col items-center">
                            <Heading size="12xl" as="h1" className="!text-gray-900_05 flex gap-2 items-center">
                                Confirm Email
                            </Heading>
                            <Text as="p" className="!text-gray-900_05">
                                {message}
                            </Text>

                            {status === "success" && (
                                <Img
                                    src="/images/success_icon.svg"
                                    alt="Success"
                                    className="h-16 w-16"
                                />
                            )}

                            {status === "error" && (
                                <Img
                                    src="/images/error_icon.svg"
                                    alt="Error"
                                    className="h-16 w-16"
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className="w-[25%] md:hidden">
                    <LoginSideGraphic />
                </div>
            </div>
        </>
    );
}
