import React, { useState, useEffect } from 'react';
import { formatUSD } from 'utils';

const progressRecords = [
    {
        situationTitle: "Unexpected Opportunities for School Events",
        choiceDecision: 2,
        simDecisionDate: "2025-03-18",
        choice1Impact: 50,
        choice2Impact: 20,
        choice3Impact: 0
    },
    {
        situationTitle: "You sprain your ankle during a school sports event.",
        choiceDecision: 3,
        simDecisionDate: "2025-03-11",
        choice1Impact: 200,
        choice2Impact: 50,
        choice3Impact: 0
    },
    {
        situationTitle: "Unexpected school project requires additional resources.",
        choiceDecision: 1,
        simDecisionDate: "2025-03-04",
        choice1Impact: 80,
        choice2Impact: 20,
        choice3Impact: 5
    },
    {
        situationTitle: "You need a prom outfit for the upcoming event.",
        choiceDecision: 3,
        simDecisionDate: "2025-02-25",
        choice1Impact: 300,
        choice2Impact: 100,
        choice3Impact: 0
    },
    {
        situationTitle: "You want to join a new sports club at school.",
        choiceDecision: 1,
        simDecisionDate: "2025-02-18",
        choice1Impact: 20,
        choice2Impact: 10,
        choice3Impact: 0
    },
    // Add other records as necessary
];

const Statements = () => {
    const [data, setData] = useState(progressRecords);

    useEffect(() => {
        // Any setup or data fetching logic can go here
    }, []);

    const getImpact = (record) => {
        switch (record.choiceDecision) {
            case 1:
                return record.choice1Impact;
            case 2:
                return record.choice2Impact;
            case 3:
                return record.choice3Impact;
            default:
                return 0;
        }
    };

    return (
        <div className='p-4 h-full w-full'>
            <div className='flex flex-col h-full flex-grow rounded-lg border divide-y'>
                <h1 className='p-4 text-gray-700 text-sm'>USER CHOICES</h1>

                <div className='p-4 h-full'>
                    <table className="table-auto w-full text-sm">
                        <thead>
                            <tr className="border-b">
                                <th className="text-left px-4 py-2">Date</th>
                                <th className="text-left px-4 py-2">Situation</th>
                                <th className="text-left px-4 py-2">Impact</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((record, index) => (
                                <tr key={index} className="border-b hover:bg-gray-100">
                                    <td className="px-4 py-2">
                                        {new Date(record.simDecisionDate).toLocaleDateString()}
                                    </td>
                                    <td className="px-4 py-2">{record.situationTitle}</td>
                                    <td className={`px-4 py-2 ${getImpact(record) >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                        {`${formatUSD(getImpact(record))}`}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Statements;
