import React from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Img, Heading, SelectBox, Input } from "../../components";
import PerformanceCoursesAnalytics from "../../components/PerformanceCoursesAnalytics";
import { ReactTable } from "../../components/ReactTable";
import Sidebar1 from "../../components/Sidebar1";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from 'react-router-dom';

const dropDownOptions = [
  { label: "Option1", value: "option1" },
  { label: "Option2", value: "option2" },
  { label: "Option3", value: "option3" },
];
const table2Data = [
  { column: "Module 1 : Financial Responsibility", column1: "Q1 : question 1", duration: "Q1 : question 1" },
  {
    column: "Module 1 : Financial Responsibility",
    column1: "Active",
    duration: "20 min",
    column2: "-",
    tableheader: "Completed",
    column3: "Standard",
  },
  {
    column: "Module 3 : Financial Responsibility",
    column1: "Not Started",
    duration: "20 min",
    column2: "-",
    tableheader: "Not Started",
    column3: "Standard",
  },
];

export default function PerformancecoursesPage() {
  const navigate = useNavigate();

  const handleStudentClick = () => {
    navigate('/performancestock');
  };

  const handleAllClassesClick = () => {
    navigate('/teacherhome');
  };

  const handleAssignedCoursesClick = () => {
    navigate('/assignedcourses');
  };

  const handleStudentsClick = () => {
    navigate('/students');
  };

  const handleInstructorClick = () => {
    navigate('/instructor');
  };

  const handleAssignmentClick = () => {
    navigate('/assignments');
  };

  const table2Columns = React.useMemo(() => {
    const table2ColumnHelper = createColumnHelper();
    return [
      table2ColumnHelper.accessor("column", {
        cell: (info) => (
          <div className="flex w-[35%] justify-center gap-4 border border-solid border-blue_gray-50_01 bg-white-A700 p-2 md:w-full">
            <div className="flex rotate-[180deg] flex-col py-3">
              <Img src="images/img_arrow_down_gray_900_07.svg" alt="arrowdown" className="h-[16px]" />
            </div>
            <div className="flex pb-2 pt-[11px]">
              <Text as="p" className="!font-medium tracking-[0.50px]">
                {info?.getValue?.()}
              </Text>
            </div>
          </div>
        ),
        header: (info) => (
          <div className="flex border-r border-solid border-gray-100_05 md:p-5">
            <div className="flex w-full border-b border-solid border-gray-100_05 bg-gray-100_05 px-6 py-[22px] sm:p-5">
              <Heading size="4xl" as="h4">
                MODULES
              </Heading>
            </div>
          </div>
        ),
        meta: { width: "360px" },
      }),
      table2ColumnHelper.accessor("column1", {
        cell: (info) => (
          <div className="flex flex-1 border border-solid border-blue_gray-50_01 bg-white-A700 px-4 py-3 md:self-stretch">
            <Button
              color="gray_500"
              size="lg"
              shape="round"
              className="min-w-[60px] border-[0.5px] border-solid border-lime-800 tracking-[0.10px] !text-lime-800"
            >
              Active
            </Button>
          </div>
        ),
        header: (info) => (
          <div className="flex-1 border-r border-solid border-gray-100_05 md:self-stretch md:p-5">
            <div className="flex border-b border-solid border-gray-100_05 bg-gray-100_05 px-6 py-[22px] sm:p-5">
              <Heading size="4xl" as="h5">
                STATUS
              </Heading>
            </div>
          </div>
        ),
        meta: { width: "144px" },
      }),
      table2ColumnHelper.accessor("duration", {
        cell: (info) => (
          <Button
            color="white_A700"
            size="6xl"
            shape="square"
            className="w-full flex-1 border border-solid border-blue_gray-50_01 tracking-[0.10px] md:self-stretch sm:px-5"
          >
            20 min
          </Button>
        ),
        header: (info) => (
          <Button
            color="gray_100_05"
            size="7xl"
            shape="square"
            className="min-w-[128px] border-r border-solid border-gray-100_05 px-[26px] font-semibold md:p-5 sm:px-5"
          >
            DURATION
          </Button>
        ),
        meta: { width: "128px" },
      }),
      table2ColumnHelper.accessor("column2", {
        cell: (info) => (
          <Button
            color="white_A700"
            size="6xl"
            shape="square"
            className="min-w-[100px] border border-solid border-blue_gray-50_01 tracking-[0.10px] sm:px-5"
          >
            -
          </Button>
        ),
        header: (info) => (
          <div className="flex border-r border-solid border-gray-100_05 md:p-5">
            <div className="w-full border-b border-solid border-gray-100_05 bg-gray-100_05 px-2 py-3.5">
              <Heading size="4xl" as="h6" className="leading-[18px]">
                QUIZ ATTEPTS
              </Heading>
            </div>
          </div>
        ),
        meta: { width: "98px" },
      }),
      table2ColumnHelper.accessor("tableheader", {
        cell: (info) => (
          <div className="flex flex-1 border border-solid border-blue_gray-50_01 bg-white-A700 px-6 py-3 md:self-stretch sm:px-5">
            <Button
              color="gray_500"
              size="lg"
              shape="round"
              className="min-w-[60px] border-[0.5px] border-solid border-lime-800 tracking-[0.10px] !text-lime-800"
            >
              Active
            </Button>
          </div>
        ),
        header: (info) => (
          <div className="flex border-b border-solid border-gray-100_05 bg-gray-100_05 px-6 py-3.5 md:p-5 sm:px-5">
            <Heading size="4xl" as="p" className="w-[60%] leading-[18px]">
              QUIZ RESULT
            </Heading>
          </div>
        ),
        meta: { width: "140px" },
      }),
      table2ColumnHelper.accessor("column3", {
        cell: (info) => (
          <Button
            color="white_A700"
            size="6xl"
            shape="square"
            className="min-w-[172px] border border-solid border-blue_gray-50_01 tracking-[0.10px] sm:px-5"
          >
            Low
          </Button>
        ),
        header: (info) => (
          <div className="flex border-r border-solid border-gray-100_05 md:p-5">
            <div className="flex w-full border-b border-solid border-gray-100_05 bg-gray-100_05 px-6 py-3.5 sm:px-5">
              <Heading size="4xl" as="p" className="w-[70%] uppercase leading-[18px]">
                SUGGESTED DIFFICULTY{" "}
              </Heading>
            </div>
          </div>
        ),
        meta: { width: "173px" },
      }),
    ];
  }, []);

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-center bg-white-A700">
        <Sidebar1 />
        <div className="flex flex-col w-full p-5">
          <div className="pb-[23px] sm:pb-5">
            <header className="flex items-center border-b border-solid border-blue_gray-100 bg-white-A700 px-4 pb-2 pt-[7px]">
              <div className="flex bg-white-A700 pb-1 pt-2.5">
                <Heading size="11xl" as="h4" className="!text-gray-800_01">
                  Monday, March 4, 2024
                </Heading>
              </div>
            </header>
            <div className="flex flex-col">
              <div className="flex self-start px-4 py-3">
                <div className="flex items-center gap-2 cursor-pointer" onClick={handleAllClassesClick}>
                  <Img src="images/img_arrow_left_blue_a200.svg" alt="arrowleft" className="h-[20px]" />
                  <div className="flex">
                    <Heading size="4xl" as="h1" className="!text-blue-500 cursor-pointer">
                      All Classes
                    </Heading>
                  </div>
                </div>
              </div>
              <div className="relative mt-[-3px] bg-white-A700">
                <div className="flex flex-col gap-[3px]">
                  <div className="flex w-[98%] items-center justify-between gap-5 md:w-full sm:flex-col">
                    <div className="m-[15px] flex flex-col items-start gap-0.5 pb-[5px]">
                      <Heading size="11xl" as="h2" className="!font-poppins !text-gray-900_05 pb-4">
                        Class Financial 101
                      </Heading>
                      <div className="flex flex-wrap self-center">
                        <Text as="p" className="self-start !font-roboto tracking-[0.25px] !text-gray-800_01">
                          20 students
                        </Text>
                        <Text as="p" className="ml-[13px] self-end !font-roboto tracking-[0.25px] !text-gray-800_01">
                          |
                        </Text>
                        <Text as="p" className="ml-[22px] self-start !font-roboto tracking-[0.25px] !text-gray-800_01">
                          Grade 9
                        </Text>
                        <Text as="p" className="ml-[22px] self-end !font-roboto tracking-[0.25px] !text-gray-800_01">
                          |
                        </Text>
                        <Text as="p" className="ml-[5px] self-end !font-roboto tracking-[0.25px] !text-gray-800_01">
                          Jan, 24 2024
                        </Text>
                      </div>
                    </div>
                    <div className="mb-2 flex gap-4 self-end">
                      <Button
                        color="blue_gray_100"
                        size="2xl"
                        variant="outline"
                        shape="round"
                        className="w-[40px] border"
                      >
                        <Img src="images/img_search_blue_gray_100.svg" />
                      </Button>
                      <Button
                        size="3xl"
                        shape="round"
                        leftIcon={<Img src="images/img_icon_white_a700.svg" alt="icon" className="h-[18px] w-[18px]" />}
                        className="min-w-[176px] gap-2 font-roboto font-medium tracking-[0.10px] sm:pr-5"
                      >
                        Download Report
                      </Button>
                    </div>
                  </div>
                  <div className="rounded-lg bg-white-A700 pb-20 md:pb-5">
                    <div className="flex flex-col gap-2">
                      <div className="flex px-4 sm:flex-col" style={{ marginBottom: '-10px'}}>
                        <div className="flex px-4 pb-3.5 pt-4 cursor-pointer" onClick={handleAssignedCoursesClick}>
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01">
                            Assigned courses
                          </Text>
                        </div>
                        <div className="flex p-[15px]">
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer"  onClick={handleStudentsClick}>
                            Students
                          </Text>
                        </div>
                        <div className="flex px-4">
                          <div className="flex py-[15px]">
                            <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer"  onClick={handleInstructorClick}>
                              Instructors
                            </Text>
                          </div>
                        </div>
                        <div className="flex px-4 pb-3.5 pt-4">
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-gray-800_01 cursor-pointer" onClick={handleAssignmentClick}>
                            Assignment
                          </Text>
                        </div>
                        <div className="flex w-[13%] flex-col items-center px-[15px] pt-[15px] sm:w-full">
                        <div className="flex w-full flex-col">
                          <Text as="p" className="!font-roboto !font-medium tracking-[0.10px] !text-blue-A200">
                            Student Performance
                          </Text>
                        </div>
                      </div>
                      </div>
                      <hr className="w-full border-gray-300" />
                      <div className="pb-4">
                        <div className="flex items-center md:flex-col">
                          <div className="w-[22%] px-2 pb-[67px] pt-2 md:w-full md:pb-5">
                            <div className="flex flex-col items-start justify-center rounded-lg border border-solid border-blue_gray-50_01 pb-[747px] md:pb-5">
                              <Input
                                color="gray_50_01"
                                size="md"
                                shape="square"
                                type="text"
                                name="name"
                                placeholder={`STUDENT NAME`}
                                className="self-stretch border-b border-solid border-blue_gray-50_01 pr-[35px] font-semibold sm:px-5"
                              />
                              <Text as="p" className="ml-[22px] mt-[21px] !text-black-900 md:ml-0 cursor-pointer" onClick={handleStudentClick}>
                                John Doe
                              </Text>
                              <Text as="p" className="ml-[22px] mt-[21px] !text-black-900 md:ml-0 cursor-pointer" onClick={handleStudentClick}>
                                John Doe
                              </Text>
                              <Text as="p" className="ml-[22px] mt-[21px] !text-black-900 md:ml-0 cursor-pointer" onClick={handleStudentClick}>
                                John Doe
                              </Text>
                              <Text as="p" className="ml-[22px] mt-[21px] !text-black-900 md:ml-0 cursor-pointer" onClick={handleStudentClick}>
                                John Doe
                              </Text>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col gap-4 bg-white-A700 p-2 md:self-stretch">
                            <div className="flex items-center justify-between gap-5 sm:flex-col">
                              <SelectBox
                                shape="round"
                                indicator={
                                  <Img
                                    src="images/img_arrowdropdown.svg"
                                    alt="arrow_drop_down"
                                    className="h-[24px] w-[24px]"
                                  />
                                }
                                name="value"
                                placeholder={`Course A`}
                                options={dropDownOptions}
                                className="w-[20%] gap-px border border-solid border-blue_gray-100 font-adellesans tracking-[0.25px] sm:w-full sm:px-5"
                              />
                              <div className="flex w-[35%] justify-center sm:w-full">
                                <div className="flex">
                                  <Button
                                    color="blue_50_04"
                                    size="3xl"
                                    className="min-w-[104px] rounded-bl-[20px] rounded-tl-[20px] font-roboto font-medium tracking-[0.10px] sm:px-5"
                                  >
                                    Courses
                                  </Button>
                                </div>
                                <div className="relative ml-[-1px] flex-1">
                                  <Button
                                    color="blue_gray_50_01"
                                    size="3xl"
                                    variant="outline"
                                    shape="square"
                                    className="w-full border font-roboto font-medium tracking-[0.10px] !text-gray-900_05"
                                  >
                                    Stock Simulator
                                  </Button>
                                </div>
                                <div className="relative ml-[-1px] flex-1">
                                  <Button
                                    color="blue_gray_50_01"
                                    size="3xl"
                                    variant="outline"
                                    className="w-full rounded-br-[20px] rounded-tr-[20px] border font-roboto font-medium tracking-[0.10px] !text-gray-900_05"
                                  >
                                    Budget Simulator
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <div className="flex gap-4 md:flex-col">
                              <div className="flex w-[25%] flex-col items-start gap-2 rounded-lg border border-solid border-blue_gray-50_01 px-4 pb-[59px] pt-[17px] md:w-full md:pb-5">
                                <Heading size="6xl" as="h3" className="!text-gray-900_cc">
                                  Overview
                                </Heading>
                                <div className="flex flex-col gap-2 self-stretch">
                                  <div className="flex flex-wrap justify-between gap-5 opacity-0.8">
                                    <Text as="p" className="self-end">
                                      Completion %
                                    </Text>
                                    <Text as="p" className="self-start !text-black-900">
                                      30%
                                    </Text>
                                  </div>
                                  <div className="flex flex-wrap justify-between gap-5 opacity-0.8">
                                    <Text as="p" className="self-end">
                                      Avg Test Reattempts
                                    </Text>
                                    <Text as="p" className="self-start !text-black-900">
                                      30%
                                    </Text>
                                  </div>
                                  <div className="flex flex-wrap justify-between gap-5 opacity-0.8">
                                    <Text as="p" className="self-end">
                                      Completion %
                                    </Text>
                                    <Text as="p" className="self-start !text-black-900">
                                      30%
                                    </Text>
                                  </div>
                                  <div className="flex flex-wrap justify-between gap-5 opacity-0.8">
                                    <Text as="p" className="self-end">
                                      Late Assignments
                                    </Text>
                                    <Text as="p" className="self-start !text-black-900">
                                      3
                                    </Text>
                                  </div>
                                </div>
                              </div>
                              <div className="flex-1 md:self-stretch">
                                <PerformanceCoursesAnalytics />
                              </div>
                            </div>
                            <div className="rounded-lg border border-solid border-blue_gray-50_01">
                              <ReactTable
                                size="sm"
                                bodyProps={{ className: "" }}
                                headerProps={{ className: "bg-white-A700 md:flex-col" }}
                                rowDataProps={{ className: "" }}
                                columns={table2Columns}
                                data={table2Data}
                              />
                              <div className="flex md:flex-col">
                                <div className="flex w-[35%] items-center justify-center gap-4 border border-solid border-blue_gray-50_01 bg-white-A700 p-2 md:w-full">
                                  <Img
                                    src="images/img_arrow_down_gray_900_07_16x16.svg"
                                    alt="arrowdown"
                                    className="h-[16px] w-[16px]"
                                  />
                                  <div className="flex py-2">
                                    <Text as="p" className="!font-medium tracking-[0.50px]">
                                      Module 4 : Financial Responsibility
                                    </Text>
                                  </div>
                                </div>
                                <div className="flex border border-solid border-blue_gray-50_01 bg-white-A700 p-3">
                                  <Button
                                    color="gray_100_03"
                                    size="lg"
                                    shape="round"
                                    className="min-w-[97px] border-[0.5px] border-solid border-blue_gray-400 tracking-[0.10px]"
                                  >
                                    Not Started
                                  </Button>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="min-w-[128px] border border-solid border-blue_gray-50_01 tracking-[0.10px] sm:px-5"
                                >
                                  20 min
                                </Button>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="min-w-[100px] border border-solid border-blue_gray-50_01 tracking-[0.10px] sm:px-5"
                                >
                                  -
                                </Button>
                                <div className="flex flex-1 border border-solid border-blue_gray-50_01 bg-white-A700 px-4 py-3 md:self-stretch">
                                  <Button
                                    color="gray_100_03"
                                    size="lg"
                                    shape="round"
                                    className="min-w-[97px] border-[0.5px] border-solid border-blue_gray-400 tracking-[0.10px]"
                                  >
                                    Not Started
                                  </Button>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="w-full flex-1 border border-solid border-blue_gray-50_01 tracking-[0.10px] md:self-stretch sm:px-5"
                                >
                                  4
                                </Button>
                              </div>
                              <div className="flex md:flex-col">
                                <div className="flex w-[35%] items-center justify-center gap-4 border border-solid border-blue_gray-50_01 bg-white-A700 p-2 md:w-full">
                                  <Img
                                    src="images/img_arrow_down_gray_900_07_16x16.svg"
                                    alt="arrowdown"
                                    className="h-[16px] w-[16px]"
                                  />
                                  <div className="flex py-2">
                                    <Text as="p" className="!font-medium tracking-[0.50px]">
                                      Module 5 : Financial Responsibility
                                    </Text>
                                  </div>
                                </div>
                                <div className="flex border border-solid border-blue_gray-50_01 bg-white-A700 p-3">
                                  <Button
                                    color="gray_100_03"
                                    size="lg"
                                    shape="round"
                                    className="min-w-[97px] border-[0.5px] border-solid border-blue_gray-400 tracking-[0.10px]"
                                  >
                                    Not Started
                                  </Button>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="min-w-[128px] border border-solid border-blue_gray-50_01 tracking-[0.10px] sm:px-5"
                                >
                                  20 min
                                </Button>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="min-w-[100px] border border-solid border-blue_gray-50_01 tracking-[0.10px] sm:px-5"
                                >
                                  -
                                </Button>
                                <div className="flex flex-1 border border-solid border-blue_gray-50_01 bg-white-A700 px-4 py-3 md:self-stretch">
                                  <Button
                                    color="gray_100_03"
                                    size="lg"
                                    shape="round"
                                    className="min-w-[97px] border-[0.5px] border-solid border-blue_gray-400 tracking-[0.10px]"
                                  >
                                    Not Started
                                  </Button>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="w-full flex-1 border border-solid border-blue_gray-50_01 tracking-[0.10px] md:self-stretch sm:px-5"
                                >
                                  4
                                </Button>
                              </div>
                              <div className="flex md:flex-col">
                                <div className="flex w-[35%] items-center justify-center gap-4 border border-solid border-blue_gray-50_01 bg-white-A700 p-2 md:w-full">
                                  <Img
                                    src="images/img_arrow_down_gray_900_07_16x16.svg"
                                    alt="arrowdown"
                                    className="h-[16px] w-[16px]"
                                  />
                                  <div className="flex py-2">
                                    <Text as="p" className="!font-medium tracking-[0.50px]">
                                      Module 6 : Financial Responsibility
                                    </Text>
                                  </div>
                                </div>
                                <div className="flex border border-solid border-blue_gray-50_01 bg-white-A700 p-3">
                                  <Button
                                    color="gray_100_03"
                                    size="lg"
                                    shape="round"
                                    className="min-w-[97px] border-[0.5px] border-solid border-blue_gray-400 tracking-[0.10px]"
                                  >
                                    Not Started
                                  </Button>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="min-w-[128px] border border-solid border-blue_gray-50_01 tracking-[0.10px] sm:px-5"
                                >
                                  20 min
                                </Button>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="min-w-[100px] border border-solid border-blue_gray-50_01 tracking-[0.10px] sm:px-5"
                                >
                                  -
                                </Button>
                                <div className="flex flex-1 border border-solid border-blue_gray-50_01 bg-white-A700 px-4 py-3 md:self-stretch">
                                  <Button
                                    color="gray_100_03"
                                    size="lg"
                                    shape="round"
                                    className="min-w-[97px] border-[0.5px] border-solid border-blue_gray-400 tracking-[0.10px]"
                                  >
                                    Not Started
                                  </Button>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="w-full flex-1 border border-solid border-blue_gray-50_01 tracking-[0.10px] md:self-stretch sm:px-5"
                                >
                                  4
                                </Button>
                              </div>
                              <div className="flex md:flex-col">
                                <div className="flex w-[35%] items-center justify-center gap-4 border border-solid border-blue_gray-50_01 bg-white-A700 p-2 md:w-full">
                                  <Img
                                    src="images/img_arrow_down_gray_900_07_16x16.svg"
                                    alt="arrowdown"
                                    className="h-[16px] w-[16px]"
                                  />
                                  <div className="flex py-2">
                                  <Text as="p" className="!font-medium tracking-[0.50px] text-wrap">
                                    Module 7 : Financial Responsibility
                                  </Text>
                                </div>
                                </div>
                                <div className="flex border border-solid border-blue_gray-50_01 bg-white-A700 p-3">
                                  <Button
                                    color="gray_100_03"
                                    size="lg"
                                    shape="round"
                                    className="min-w-[97px] border-[0.5px] border-solid border-blue_gray-400 tracking-[0.10px]"
                                  >
                                    Not Started
                                  </Button>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="min-w-[128px] border border-solid border-blue_gray-50_01 tracking-[0.10px] sm:px-5"
                                >
                                  20 min
                                </Button>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="min-w-[100px] border border-solid border-blue_gray-50_01 tracking-[0.10px] sm:px-5"
                                >
                                  -
                                </Button>
                                <div className="flex flex-1 border border-solid border-blue_gray-50_01 bg-white-A700 px-4 py-3 md:self-stretch">
                                  <Button
                                    color="gray_100_03"
                                    size="lg"
                                    shape="round"
                                    className="min-w-[97px] border-[0.5px] border-solid border-blue_gray-400 tracking-[0.10px]"
                                  >
                                    Not Started
                                  </Button>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="w-full flex-1 border border-solid border-blue_gray-50_01 tracking-[0.10px] md:self-stretch sm:px-5"
                                >
                                  4
                                </Button>
                              </div>
                              <div className="flex md:flex-col">
                                <div className="flex w-[35%] items-center justify-center gap-4 border border-solid border-blue_gray-50_01 bg-white-A700 p-2 md:w-full">
                                  <Img
                                    src="images/img_arrow_down_gray_900_07_16x16.svg"
                                    alt="arrowdown"
                                    className="h-[16px] w-[16px]"
                                  />
                                  <div className="flex py-2">
                                    <Text as="p" className="!font-medium tracking-[0.50px]">
                                      Module 8 : Financial Responsibility
                                    </Text>
                                  </div>
                                </div>
                                <div className="flex border border-solid border-blue_gray-50_01 bg-white-A700 p-3">
                                  <Button
                                    color="gray_100_03"
                                    size="lg"
                                    shape="round"
                                    className="min-w-[97px] border-[0.5px] border-solid border-blue_gray-400 tracking-[0.10px]"
                                  >
                                    Not Started
                                  </Button>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="min-w-[128px] border border-solid border-blue_gray-50_01 tracking-[0.10px] sm:px-5"
                                >
                                  20 min
                                </Button>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="min-w-[100px] border border-solid border-blue_gray-50_01 tracking-[0.10px] sm:px-5"
                                >
                                  -
                                </Button>
                                <div className="flex flex-1 border border-solid border-blue_gray-50_01 bg-white-A700 px-4 py-3 md:self-stretch">
                                  <Button
                                    color="gray_100_03"
                                    size="lg"
                                    shape="round"
                                    className="min-w-[97px] border-[0.5px] border-solid border-blue_gray-400 tracking-[0.10px]"
                                  >
                                    Not Started
                                  </Button>
                                </div>
                                <Button
                                  color="white_A700"
                                  size="6xl"
                                  shape="square"
                                  className="w-full flex-1 border border-solid border-blue_gray-50_01 tracking-[0.10px] md:self-stretch sm:px-5"
                                >
                                  4
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
