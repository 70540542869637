// CompleteLearningView.jsx

import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { IconLock } from '@tabler/icons-react';
import { Button } from 'components';
import LearningViewTopBar from './LearningViewTopBar';
import { Img, Text, Heading } from 'components';
import ArticleContent from './ArticleContent';
import VideoLessonContent from './VideoLessonContent';
import { LESSON_ARTICLE } from 'utils';
import ConfettiExplosion from 'react-confetti-explosion';
import QuizAnswerChoice from './QuizAnswerChoice';
import { shuffle } from 'utils';
import Popup from 'reactjs-popup';
import LoadingSpinner from 'components/LoadingSpinner';

import {
  fetchUnitTree,
  startLesson,
  endLesson,
  startQuestion,
  endQuestion,
  fetchSectionContent,
  markCompleted,
  submitAnswer
} from 'StudentAPIService';

const getRandomVariantIndex = (variantsLength) => {
  return Math.floor(Math.random() * variantsLength);
};

export default function CompleteLearningView() {
  const { courseId, studentId, unitId, lessonId, quizId } = useParams();
  const navigate = useNavigate();
  const [tree, setTree] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [expandedSection, setExpandedSection] = useState(null);
  const [activeLesson, setActiveLesson] = useState(null);
  const [section, setSection] = useState(null);
  const contentRef = useRef(null);

  const [showConfetti, setShowConfetti] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [variantIndex, setVariantIndex] = useState(1);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isWrongAnswer, setIsWrongAnswer] = useState(false);
  const [questionStartTime, setQuestionStartTime] = useState(null);
  const [questionCompleted, setQuestionCompleted] = useState(false);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [menuState, setMenuState] = useState(true);
  const [lessonStartTime, setLessonStartTime] = useState(null);
  const isActiveQuiz = section?.quiz?.id === quizId;

  const isLastQuizInCourse = () => {
    if (!tree) return false;
    const currentSectionIndex = tree.sections.findIndex(
      (section) => section.quiz && section.quiz.id === quizId
    );
    if (currentSectionIndex === -1) return false;
    for (let i = currentSectionIndex + 1; i < tree.sections.length; i++) {
      if (tree.sections[i].quiz) {
        return false;
      }
    }
    return true;
  };

  const celebrateConfetti = () => {
    if (!showConfetti) {
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000);
    }
  };

  useEffect(() => {
    if (expandedSection === null && tree && lessonId) {
      const sectionContainingLesson = tree.sections.find((section) =>
        section.lessons.some((lesson) => lesson.id === lessonId)
      );
      if (sectionContainingLesson) {
        setExpandedSection(sectionContainingLesson.id);
      }
    } else if (expandedSection === null && tree && quizId) {
      const sectionContainingQuiz = tree.sections.find(
        (section) => section.quiz && section.quiz.id === quizId
      );
      if (sectionContainingQuiz) {
        setExpandedSection(sectionContainingQuiz.id);
      }
    }
  }, [lessonId, quizId, tree, expandedSection]);

  const toggleSection = (sectionId) => {
    if (expandedSection === sectionId) {
      setExpandedSection(null); // Close the section if it's already open
    } else {
      setExpandedSection(sectionId); // Open the clicked section
    }
  };

  useEffect(() => {
    fetchTree();
  }, []);

  const fetchTree = async () => {
    setLoading(true);
    try {
      const data = await fetchUnitTree(unitId, studentId);
      setTree(data);
      setLoading(false);

      if (!lessonId && !quizId && data.sections.length > 0) {
        navigate(
          `/courses/course/${courseId}/student/${studentId}/unit/${unitId}/lesson/${data.sections[0].lessons[0].id}`
        );
      }
    } catch (error) {
      if (error.message.includes('401')) {
        navigate('/timeout');
      } else {
        console.error('Fetching unit tree failed', error);
      }
    }
  };

  const handleBackClick = () => {
    if (!tree || !activeLesson) return;

    for (let i = 0; i < tree.sections.length; i++) {
      const section = tree.sections[i];
      for (let j = 0; j < section.lessons.length; j++) {
        const lesson = section.lessons[j];

        if (lesson.id === activeLesson.id) {
          if (j > 0) {
            navigate(
              `/courses/course/${courseId}/student/${studentId}/unit/${unitId}/lesson/${section.lessons[j - 1].id}`
            );
          } else if (i > 0) {
            const prevSection = tree.sections[i - 1];
            const lastLessonIndex = prevSection.lessons.length - 1;
            navigate(
              `/courses/course/${courseId}/student/${studentId}/unit/${unitId}/lesson/${prevSection.lessons[lastLessonIndex].id}`
            );
          } else {
            navigate(`/courses/course/${courseId}/student/${studentId}`);
          }
          break;
        }
      }
    }

    window.scrollTo(0, 0);
  };

  const handleStartLesson = async (lessonId) => {
    setLessonStartTime(Date.now());
    try {
      await startLesson(lessonId);
    } catch (error) {
      if (error.message.includes('401')) {
        navigate('/timeout');
      } else {
        console.error('Error starting the lesson:', error);
      }
    }
  };

  const handleEndLesson = async (lessonId) => {
    const lessonEndTime = Date.now();
    const duration = Math.floor((lessonEndTime - lessonStartTime) / 1000);

    try {
      await endLesson(lessonId, duration);
    } catch (error) {
      if (error.message.includes('401')) {
        navigate('/timeout');
      } else {
        console.error('Error ending the lesson:', error);
      }
    }
  };

  const handleNextClick = () => {
    if (!tree || (!activeLesson && !quizId)) return;

    if (activeLesson) {
      const lessonInTree = tree.sections
        .flatMap((s) => s.lessons)
        .find((lesson) => lesson.id === activeLesson.id);

      if (lessonInTree && !lessonInTree.completed) {
        handleEndLesson(activeLesson.id);
      }
    }

    const currentSectionIndex = tree.sections.findIndex((section) => {
      return (
        section.lessons.some((lesson) => lesson.id === lessonId) ||
        (section.quiz && section.quiz.id === quizId)
      );
    });

    if (quizId) {
      if (isLastQuizInCourse()) {
        markCompletedAndGo(
          quizId,
          `/courses/course/${courseId}/student/${studentId}`,
          true
        );
        return;
      }
      for (let i = 0; i < tree.sections.length; i++) {
        const section = tree.sections[i];
        if (section.quiz && section.quiz.id === quizId) {
          if (i < tree.sections.length - 1) {
            const nextSection = tree.sections[i + 1];
            const nextLesson = nextSection.lessons[0];
            markCompletedAndGo(
              quizId,
              `/courses/course/${courseId}/student/${studentId}/unit/${unitId}/lesson/${nextLesson.id}`,
              true
            );
            setExpandedSection(nextSection.id);
          }
        }
      }
    }

    for (let i = 0; i < tree.sections.length; i++) {
      const section = tree.sections[i];
      for (let j = 0; j < section.lessons.length; j++) {
        const lesson = section.lessons[j];

        if (lesson.id === lessonId) {
          if (j < section.lessons.length - 1) {
            // Move to the next lesson in the same section
            markCompletedAndGo(
              lesson.id,
              `/courses/course/${courseId}/student/${studentId}/unit/${unitId}/lesson/${section.lessons[j + 1].id}`,
              false
            );
          } else if (section.quiz) {
            // If there’s a quiz at the end of the section, go to the quiz
            markCompletedAndGo(
              lesson.id,
              `/courses/course/${courseId}/student/${studentId}/unit/${unitId}/quiz/${section.quiz.id}`,
              false
            );
          } else if (i < tree.sections.length - 1) {
            // If this is the last lesson in the section, go to the first lesson of the next section
            const nextSection = tree.sections[i + 1];
            if (nextSection.lessons.length > 0) {
              markCompletedAndGo(
                lesson.id,
                `/courses/course/${courseId}/student/${studentId}/unit/${unitId}/lesson/${nextSection.lessons[0].id}`,
                false
              );
              setExpandedSection(nextSection.id);
            }
          } else {
            // If no more sections, navigate to the course overview or completion page
            markCompletedAndGo(
              lesson.id,
              `/courses/course/${courseId}/student/${studentId}`,
              false
            );
          }
          break;
        }
      }
    }

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (section && section.quiz && section.quiz.questions[currentQuestion]) {
      const questionId = section.quiz.questions[currentQuestion].questionID;
      handleStartQuestion(questionId);
    }
  }, [section, currentQuestion]);

  const handleTryAgain = () => {
    setIsSubmitted(false);
    setSelectedAnswer(null);
    setIsWrongAnswer(false);
    setQuestionCompleted(false);

    let nextVariantIndex =
      (variantIndex + 1) %
      section.quiz.questions[currentQuestion].questionVariants.length;
    setVariantIndex(nextVariantIndex);
    setAnswers(
      shuffle(
        section.quiz.questions[currentQuestion].questionVariants[nextVariantIndex]
          .answers
      )
    );
  };

  const handleStartQuestion = async (questionId) => {
    setQuestionStartTime(Date.now());
    try {
      await startQuestion(questionId);
    } catch (error) {
      if (error.message.includes('401')) {
        navigate('/timeout');
      } else {
        console.error('Error starting the question:', error);
      }
    }
  };

  const handleEndQuestion = async (questionId) => {
    try {
      await endQuestion(questionId);
    } catch (error) {
      if (error.message.includes('401')) {
        navigate('/timeout');
      } else {
        console.error('Error ending the question:', error);
      }
    }
  };

  const handleSubmitAnswer = async () => {
    const currentQuestionId =
      section.quiz.questions[currentQuestion].questionID;

    if (selectedAnswer !== null) {
      const selectedAnswerId = answers[selectedAnswer].id;

      if (answers[selectedAnswer].isCorrect) {
        setIsSubmitted(true);
        setQuestionCompleted(true);
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000);

        await handleEndQuestion(currentQuestionId);
      } else {
        setIsWrongAnswer(true);
        setIsSubmitted(true);
        setShowModal(true);
      }

      try {
        await submitAnswer(section.id, selectedAnswerId, studentId);
      } catch (error) {
        console.error('Error submitting answer:', error);
      }
    }
  };

  const handleNextQuestion = async () => {
    if (currentQuestion === section.quiz.questions.length - 1) {
      // If this is the last question, complete the quiz
      setQuizCompleted(true);
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 3000);
    } else {
      const nextQuestionId =
        section.quiz.questions[currentQuestion + 1].questionID;
      await handleStartQuestion(nextQuestionId);
      setIsSubmitted(false);
      setSelectedAnswer(null);
      setIsWrongAnswer(false);
      setQuestionCompleted(false);
      setVariantIndex(0);
      setAnswers(
        shuffle(
          section.quiz.questions[currentQuestion + 1].questionVariants[0].answers
        )
      );
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  useEffect(() => {
    if (activeLesson) {
      const lessonInTree = tree.sections
        .flatMap((section) => section.lessons)
        .find((lesson) => lesson.id === activeLesson.id);

      if (lessonInTree && !lessonInTree.completed) {
        handleStartLesson(activeLesson.id);
      }
    }
  }, [activeLesson]);

  useEffect(() => {
    if (!tree) return;

    const currentSection = tree.sections.find(
      (s) =>
        s.lessons.some((l) => l.id === lessonId) ||
        (s.quiz && s.quiz.id === quizId)
    );

    if (!currentSection) return;

    const getSectionContent = async () => {
      try {
        const s = await fetchSectionContent(currentSection.id);
        setSection(s);

        // If it's a quiz, set up the quiz state
        if (quizId && s.quiz) {
          const randomVariantIndex = getRandomVariantIndex(
            s.quiz.questions[0].questionVariants.length
          );
          setVariantIndex(randomVariantIndex);
          setAnswers(
            shuffle(
              s.quiz.questions[currentQuestion].questionVariants[variantIndex]
                .answers
            )
          );
          setCurrentQuestion(0);
          setQuizCompleted(false);
        }

        // If it's a lesson, set the active lesson
        const al = s.lessons.find((lesson) => lesson.id === lessonId);
        if (al) setActiveLesson(al);
      } catch (error) {
        if (error.message.includes('401')) {
          navigate('/timeout');
        } else {
          console.error('Fetching section content failed', error);
        }
      }
    };

    getSectionContent();
  }, [unitId, lessonId, quizId, studentId, tree]);

  const isFirstAvailableLesson = (lessons) => {
    let lastCompletedIndex = -1;
    lessons.forEach((lesson, index) => {
      if (lesson.completed) {
        lastCompletedIndex = index;
      }
    });
    if (lastCompletedIndex === -1) {
      return (index) => index === 0;
    }
    return (index) => index === lastCompletedIndex + 1;
  };

  const isQuizAccessible = (lessons) => {
    return lessons.every((lesson) => lesson.completed);
  };

  const isSectionLocked = () => false; // All sections are unlocked

  const isLessonPage = Boolean(lessonId);
  const isQuizPage = Boolean(quizId);

  const navigateToLesson = (lessonId) => {
    navigate(
      `/courses/course/${courseId}/student/${studentId}/unit/${unitId}/lesson/${lessonId}`
    );
  };

  const navigateToQuiz = (quizId) => {
    navigate(
      `/courses/course/${courseId}/student/${studentId}/unit/${unitId}/quiz/${quizId}`
    );
  };

  const markCompletedAndGo = async (id, nextPath, isQuiz = false) => {
    try {
      await markCompleted(id, studentId, isQuiz);

      setTree((prevTree) => {
        const updatedSections = prevTree.sections.map((section) => ({
          ...section,
          lessons: section.lessons.map((lesson) =>
            lesson.id === id && !isQuiz ? { ...lesson, completed: true } : lesson
          ),
          quiz:
            section.quiz && section.quiz.id === id && isQuiz
              ? { ...section.quiz, completed: true }
              : section.quiz,
        }));
        return { ...prevTree, sections: updatedSections };
      });

      navigate(nextPath, { replace: true });
    } catch (error) {
      if (error.message.includes('401')) {
        navigate('/timeout');
      } else {
        console.error('Error marking complete:', error);
      }
    }
  };

  useEffect(() => {
    // Reset quiz-related state variables when quizId changes
    setSelectedAnswer(null);
    setAnswers([]);
    setIsSubmitted(false);
    setIsWrongAnswer(false);
    setQuestionCompleted(false);
    setQuizCompleted(false);
    setCurrentQuestion(0);
    setVariantIndex(0);
    setShowConfetti(false);
    setQuestionStartTime(null);
  }, [quizId]);

  return (
    <>
      <Helmet>
        <title>{isLessonPage ? 'Lesson Page' : 'Quiz Page'}</title>
      </Helmet>

      <div className="h-screen w-full flex flex-col overflow-hidden">
        <div className="w-full flex-none">
          <LearningViewTopBar
            courseId={courseId}
            studentId={studentId}
            unitInfo={tree && { name: tree.name, sequenceNumber: tree.number || 0 }}
          />
        </div>

        {loading ? (
          <div className="flex-grow w-full flex items-center justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="flex-grow flex p-2 gap-2 overflow-hidden">
            {menuState && (
              <div className="w-[30%] flex-shrink-0 flex flex-col gap-2 overflow-auto">
                {tree.sections.map((section, sectionIndex) => {
                  const sectionLocked = isSectionLocked(sectionIndex);
                  const isSectionCompleted =
                    section.lessons.every((lesson) => lesson.completed) &&
                    (!section.quiz || section.quiz.completed);

                  return (
                    <div key={section.id} className="rounded-lg border bg-white shadow-sm">
                      <div
                        className={`flex flex-col items-center justify-between gap-4 px-4 py-3 cursor-pointer ${sectionLocked ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                        onClick={() => {
                          if (!sectionLocked) {
                            toggleSection(section.id);
                          }
                        }}
                      >
                        <div className="flex justify-between w-full">
                          <div className="flex items-center gap-3">
                            <h4 className="text-sm font-medium">{`${sectionIndex + 1}. ${section.name
                              }`}</h4>
                          </div>
                          {sectionLocked ? (
                            <IconLock className="text-gray-600 w-[20px]" />
                          ) : (
                            <div className="flex items-center gap-2">
                              {isSectionCompleted && (
                                <Img
                                  src="/images/img_check_circle.svg"
                                  alt="completed"
                                  className="h-[24px] w-[24px]"
                                />
                              )}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className={`h-5 w-5 text-gray-500 transform transition-transform duration-300 ${expandedSection === section.id ? 'rotate-180' : ''
                                  }`}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                            </div>
                          )}
                        </div>

                        {!sectionLocked && expandedSection === section.id && (
                          <div className="w-full space-y-2 px-4 pb-4">
                            <div className="flex flex-col justify-between gap-2">
                              {section.lessons.map((lesson, index) => {
                                const isFirstAvailable = isFirstAvailableLesson(
                                  section.lessons
                                )(index);
                                const isActiveLesson = lesson.id === lessonId;
                                const lessonType =
                                  lesson.contentType === 0 ? 'Video Lesson' : 'Lesson';

                                return (
                                  <div
                                    key={lesson.id}
                                    onClick={() => {
                                      if (lesson.completed || isFirstAvailable) {
                                        navigateToLesson(lesson.id);
                                      }
                                    }}
                                    className={`cursor-pointer w-full flex items-center justify-between rounded-md px-3 py-2 text-sm font-medium transition-colors
                                      ${isActiveLesson
                                        ? 'bg-blue-500 text-white-A700'
                                        : lesson.completed
                                          ? 'bg-gray-100 text-black-900 cursor-pointer'
                                          : isFirstAvailable
                                            ? 'bg-gray-50 text-black-900 cursor-pointer'
                                            : 'bg-gray-200 text-gray-700 cursor-not-allowed'
                                      }`}
                                  >
                                    <div className="flex w-full items-center gap-3 justify-between">
                                      <span>{lessonType}</span>
                                      {!lesson.completed && !isFirstAvailable && (
                                        <IconLock className="text-gray-600 w-[20px]" />
                                      )}
                                    </div>
                                    {lesson.completed && !isActiveLesson && (
                                      <Img
                                        src="/images/img_check_circle.svg"
                                        alt="completed"
                                        className="h-[24px] w-[24px] ml-auto"
                                      />
                                    )}
                                  </div>
                                );
                              })}

                              {section.quiz && (
                                <div
                                  onClick={() => {
                                    if (isQuizAccessible(section.lessons)) {
                                      navigateToQuiz(section.quiz.id);
                                    }
                                  }}
                                  className={`cursor-pointer flex items-center justify-between rounded-md px-3 py-2 text-sm font-medium transition-colors
    ${section.quiz.id === quizId
                                      ? // This is the currently active quiz
                                      'bg-blue-500 text-white-A700'
                                      : isQuizAccessible(section.lessons)
                                        ? // Quiz accessible but not currently active
                                        'bg-gray-100 text-gray-900 cursor-pointer'
                                        : // Quiz not accessible
                                        'bg-gray-200 text-gray-700 cursor-not-allowed'
                                    }`}
                                >
                                  <div className="flex w-full items-center gap-3 justify-between">
                                    <span>Quiz</span>
                                    {!isQuizAccessible(section.lessons) && (
                                      <IconLock className="text-gray-600 w-[20px]" />
                                    )}
                                  </div>
                                  {section.quiz.completed && section.quiz.id === quizId && (
                                    <Img
                                      src="/images/img_check_circle.svg"
                                      alt="completed"
                                      className="h-[24px] w-[24px] ml-auto"
                                    />
                                  )}
                                </div>
                              )}

                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            <div className={`flex flex-col gap-4 transition-all duration-300 ${menuState ? 'w-[70%]' : 'w-full'} h-full overflow-hidden`}>
              <div className="flex-none">
                <Button
                  size="3xl"
                  variant="outline"
                  leftIcon={
                    <Img
                      src="/images/img_megaphone.svg"
                      alt="megaphone"
                      className="h-[18px] w-[18px]"
                    />
                  }
                  className="w-[180px] gap-2 rounded-[20px] font-medium tracking-[0.10px] sm:pr-5"
                  onClick={() => setMenuState(!menuState)}
                >
                  {menuState ? 'Close Menu' : 'Open Menu'}
                </Button>
              </div>

              {/* Scrollable container after the menustate button */}
              <div className="flex-grow overflow-auto">
                {isLessonPage && activeLesson ? (
                  <div ref={contentRef} className="border rounded-lg p-4">
                    {activeLesson.contentType === LESSON_ARTICLE ? (
                      <ArticleContent lesson={activeLesson} rerender={true} />
                    ) : (
                      <VideoLessonContent lesson={activeLesson} />
                    )}
                    <div className="flex justify-between p-8">
                      <Button
                        size="3xl"
                        variant="outline"
                        leftIcon={
                          <Img
                            src="/images/img_arrowleft_blue_a200_1.svg"
                            alt="arrow_left"
                            className="h-[18px] w-[18px]"
                          />
                        }
                        onClick={handleBackClick}
                        className="min-w-[99px] gap-2 rounded-[20px] font-medium tracking-[0.10px] !text-blue-A400 sm:pr-5"
                      >
                        Back
                      </Button>
                      <Button
                        size="3xl"
                        rightIcon={
                          <Img
                            src="/images/img_arrowright_white_a700.svg"
                            alt="arrow_right"
                            className="h-[18px] w-[18px]"
                          />
                        }
                        onClick={handleNextClick}
                        className="min-w-[98px] gap-2 rounded-[20px] font-medium tracking-[0.10px] sm:pr-5 cursor-pointer"
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                ) : isQuizPage && section ? (
                  <div className="w-full p-4">
                    {quizCompleted ? (
                      <div className="flex flex-col h-60 justify-around items-center rounded-lg border border-solid border-indigo-50 bg-white-A700">
                        <Heading size="10xl">You Did It!</Heading>
                        <Text>Great job on finishing this Lesson!</Text>
                        <div className="relative">
                          <Button
                            size="3xl"
                            onClick={celebrateConfetti}
                            className="min-w-[98px] gap-2 rounded-[20px] font-medium tracking-[0.10px] sm:pr-5"
                          >
                            Celebrate!
                          </Button>

                          {showConfetti && (
                            <div className="absolute -top-10 left-1/2 transform -translate-x-1/2">
                              <ConfettiExplosion />
                            </div>
                          )}
                        </div>
                        <Button
                          size="3xl"
                          rightIcon={
                            <Img
                              src="/images/img_arrowright_white_a700.svg"
                              alt="arrow_right"
                              className="h-[18px] w-[18px]"
                            />
                          }
                          onClick={handleNextClick}
                          className="min-w-[98px] gap-2 rounded-[20px] font-medium tracking-[0.10px] sm:pr-5"
                        >
                          Keep Learning!
                        </Button>
                      </div>
                    ) : (
                      <div className="rounded-lg w-full border border-solid border-indigo-50 bg-white-A700">
                        {section.quiz && (
                          <div className="flex flex-col items-center gap-[31px] rounded-lg bg-white-A700 px-4 pb-4 pt-6 sm:pt-5">
                            <div className="flex w-[97%] justify-between gap-5 md:w-full">
                              <div className="flex items-center gap-[19px]">
                                <Img
                                  src="/images/img_edit_05_blue_gray_700_02.svg"
                                  alt="editfive"
                                  className="h-[24px] w-[24px]"
                                />
                                <Text as="p" className="self-end !text-blue_gray-500">
                                  <span className="text-blue_gray_700_02">
                                    Questions {currentQuestion + 1} /
                                  </span>
                                  <span className="text-blue_gray-500">&nbsp;</span>
                                  <span className="text-blue_gray-300">
                                    {section.quiz.questions.length}
                                  </span>
                                </Text>
                              </div>
                            </div>
                            <div className="flex flex-col gap-4 self-stretch rounded-lg bg-gray-100_01 px-4 py-12 md:py-5">
                              <div className="flex items-start gap-2.5 self-start px-4">
                                <div className="flex flex-col px-2 pb-1.5 pt-2">
                                  <Heading as="h3" className="text-[22px]">
                                    {currentQuestion + 1}.
                                  </Heading>
                                </div>
                                <Heading size="9xl" as="h4" className="self-end">
                                  {
                                    section.quiz.questions[currentQuestion]
                                      .questionVariants[variantIndex].text
                                  }
                                </Heading>
                              </div>
                              <div className="flex flex-col px-4">
                                {answers.map((answer, i) => (
                                  <QuizAnswerChoice
                                    answer={answer}
                                    key={`${quizId}-${currentQuestion}-${i}`}
                                    selected={selectedAnswer}
                                    val={i}
                                    onChange={() => setSelectedAnswer(i)}
                                    feedback={
                                      isSubmitted &&
                                      (isWrongAnswer
                                        ? (i === selectedAnswer && 'wrong') ||
                                        (answer.isCorrect && 'correct')
                                        : answer.isCorrect && 'correct')
                                    }
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="pb-[235px] md:pb-5">
                          <div className="rounded-lg bg-white-A700">
                            <div className="flex justify-between gap-5 bg-white-A700 p-4">
                              <Popup
                                open={showModal}
                                closeOnDocumentClick={false}
                                modal
                                contentStyle={{
                                  width: '350px',
                                  height: '350px',
                                  borderRadius: '1rem',
                                }}
                              >
                                <div className="flex flex-col items-center justify-center h-full p-4 gap-2">
                                  <Img
                                    src="/images/img_avatars_3d_avatar_26.png"
                                    alt="Incorrect Answer"
                                    className="h-36 w-36"
                                  />
                                  <Heading as="h3" size="8xl">
                                    Sorry, that is incorrect.
                                  </Heading>
                                  <Text className="text-center">
                                    Review the question before trying again!
                                  </Text>
                                  <button
                                    className="bg-blue-500 text-white-A700 px-4 py-2 rounded hover:bg-blue-600 mt-4"
                                    onClick={() => setShowModal(false)}
                                  >
                                    Dismiss
                                  </button>
                                </div>
                              </Popup>
                              {isWrongAnswer && (
                                <div className="min-w-[200px]">
                                  <button
                                    className="bg-blue-500 text-white-A700 px-4 py-2 rounded hover:bg-blue-600"
                                    onClick={handleTryAgain}
                                  >
                                    Try Again
                                  </button>
                                </div>
                              )}
                              <div className="relative flex w-full justify-end">
                                {showConfetti && (
                                  <div className="absolute -top-10 left-1/2 transform -translate-x-1/2">
                                    <ConfettiExplosion />
                                  </div>
                                )}
                                <Button
                                  size="3xl"
                                  rightIcon={
                                    questionCompleted ? (
                                      <Img
                                        src="/images/img_arrowright_white_a700.svg"
                                        alt="arrow_right"
                                        className="h-[18px] w-[18px]"
                                      />
                                    ) : (
                                      ''
                                    )
                                  }
                                  onClick={
                                    !questionCompleted
                                      ? handleSubmitAnswer
                                      : handleNextQuestion
                                  }
                                  disabled={
                                    selectedAnswer === null ||
                                    (isSubmitted && isWrongAnswer)
                                  }
                                  className={`min-w-[98px] gap-2 rounded-[20px] font-medium tracking-[0.10px] ${selectedAnswer === null ||
                                      (isSubmitted && isWrongAnswer)
                                      ? 'bg-gray-400 cursor-not-allowed'
                                      : 'bg-blue-500'
                                    } sm:pr-5`}
                                >
                                  {!questionCompleted
                                    ? 'Submit Answer'
                                    : 'Next Question'}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <LoadingSpinner size={8} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
