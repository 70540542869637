import React, { useState, useEffect } from 'react';

const LoadingAnimation = () => {
    const [data, setData] = useState(null);

    useEffect(() => {

    }, []);

    return (
        <div className="flex flex-auto h-full items-center justify-center">
            <div className="h-12 w-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
    );
};

export default LoadingAnimation;