const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

export const apiClient = async (url, options = {}) => {
    const accessToken =
        localStorage.getItem("accessToken") ||
        sessionStorage.getItem("accessToken");

    const response = await fetch(`${API_ENDPOINT}${url}`, {
        ...options,
        headers: {
            ...options.headers,
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (response.status === 401) {
        localStorage.removeItem("accessToken");
        sessionStorage.removeItem("accessToken");
        window.location.href = "/login";
        return;
    }

    return response;
};
