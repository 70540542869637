import React, { useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // Optional: For interactions

export default function BudgetSimDashboardCalendar({ events, currentDay }) {
    return (
        <div className="grow h-full w-full p-4">
            <FullCalendar
                titleFormat={{ year: 'numeric', month: 'long'}}
                key={currentDay} // Change key to force remount
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                weekends={true}
                events={events} // Use events from props
                height="100%"
                nowIndicator={true}
                now={currentDay}
            />
        </div>
    );
}
