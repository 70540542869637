import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Button, Heading } from "../../components";
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar1 from "components/Sidebar1";
import HomeDate from "components/HomeDate";
import { fetchCourses, fetchCourseDetails, fetchClassroomDetails, addAssignment, fetchUnitDetails } from "TeacherAPIService";
import { IconAlertCircle } from "@tabler/icons-react";

export default function CreateAssignment() {
    const { classroomID } = useParams();

    const [courses, setCourses] = useState([]);
    const [units, setUnits] = useState([]);
    const [sections, setSections] = useState([]);

    const [selectedCourse, setSelectedCourse] = useState('');
    const [selectedUnit, setSelectedUnit] = useState('');
    const [selectedSection, setSelectedSection] = useState('');

    const [dueDate, setDueDate] = useState('');
    const [publishDate, setPublishDate] = useState('');

    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false); // New state to track submission status
    const [successMessage, setSuccessMessage] = useState(''); // New state for success message
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const coursesData = await fetchCourses();
                setCourses(coursesData);
                setIsLoading(false);
            } catch (error) {
                setError('Error fetching courses');
                setIsLoading(false);
            }
        };
        fetchInitialData();
    }, []);

    useEffect(() => {
        if (selectedCourse) {
            const fetchUnits = async () => {
                try {
                    const courseDetails = await fetchCourseDetails(selectedCourse);

                    // Sort units by sequenceNumber
                    const sortedUnits = courseDetails.units.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

                    setUnits(sortedUnits);
                } catch (error) {
                    setError('Error fetching units');
                }
            };
            fetchUnits();
        }
    }, [selectedCourse]);


    useEffect(() => {
        if (selectedUnit) {
            const fetchSections = async () => {
                try {
                    const unitDetails = await fetchUnitDetails(selectedUnit);
                    const sortedSections = unitDetails.sections.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
                    setSections(sortedSections);
                } catch (error) {
                    setError('Error fetching sections');
                }
            };
            fetchSections();
        }
    }, [selectedUnit]);

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(`/classroom/${classroomID}`);
    };

    const handleReleaseAssignment = async () => {
        // If no due date is provided, default to today's date
        if (!dueDate) {
            const today = new Date();
            const formattedDate = today.toISOString().split('T')[0]; // Get YYYY-MM-DD format
            setDueDate(formattedDate);
        }
    

        const assignmentData = {
            sectionId: selectedSection,
            dueUTC: new Date(dueDate || new Date()).toISOString(), // Use dueDate or default to today
        };
    
        if (publishDate) {
            assignmentData.publishUTC = new Date(publishDate).toISOString();
        }
    
        try {
            setIsSubmitting(true); // Start submission
            setError(null); // Reset error before submitting
            setSuccessMessage(''); // Reset success message
            await addAssignment(classroomID, assignmentData);
            setSuccessMessage("Assignment successfully released!"); // Show success message
        } catch (error) {
            setSuccessMessage(''); // Reset success message
            setError('Error releasing assignment');
        } finally {
            setIsSubmitting(false); // Stop submission
        }
    };    
    
    return (
        <>
            <Helmet>
                <title>intertwined</title>
                <meta name="description" content="Create and assign assignments for your classroom" />
            </Helmet>

            <div className="flex h-screen overflow-hidden">
                <Sidebar1 />

                <div className="flex flex-col w-full text-left">
                    <HomeDate />

                    <button className="flex shadow-md p-4" onClick={handleBackClick}>
                        <Img
                            src="/images/img_arrow_left_blue_a200.svg"
                            alt="arrowleft"
                            className="relative right-px"
                        />
                        <Text as="p" className="text-blue-500 cursor-pointer justify-start ">
                            Back
                        </Text>
                    </button>

                    <div className="p-4">
                        <div className="flex flex-col gap-3">
                            <h1 className="text-3xl font-semibold py-4">Create Assignment</h1>
                            <h2 size="text-lg">To create and generate an assignment for your classroom, please follow the instructions below.</h2>
                        </div>

                        <div className="flex flex-col gap-6 mt-6 text-left w-1/2">
                            {/* Course Selection */}
                            <div className="flex flex-col">
                                <label className="mb-1">Course Name</label>
                                <div className="border rounded-lg">
                                    <select
                                        className="p-2 w-full"
                                        value={selectedCourse}
                                        onChange={(e) => setSelectedCourse(e.target.value)}
                                    >
                                        <option value="" disabled>Select a course</option>
                                        {courses.map(course => (
                                            <option key={course.id} value={course.id}>{course.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {/* Unit Selection */}
                            <div className="flex flex-col">
                                <label className="mb-1">Unit</label>
                                <div className="border rounded-lg">
                                    <select
                                        className="p-2 w-full"
                                        value={selectedUnit}
                                        onChange={(e) => setSelectedUnit(e.target.value)}
                                        disabled={!selectedCourse}
                                    >
                                        <option value="" disabled>Select a unit</option>
                                        {units.map(unit => (
                                            <option key={unit.id} value={unit.id}>{unit.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {/* Section Selection */}
                            <div className="flex flex-col">
                                <label className="mb-1">Section</label>
                                <div className="border rounded-lg">
                                    <select
                                        className="p-2 w-full"
                                        value={selectedSection}
                                        onChange={(e) => setSelectedSection(e.target.value)}
                                        disabled={!selectedUnit}
                                    >
                                        <option value="" disabled>Select a section</option>
                                        {sections.map(section => (
                                            <option key={section.id} value={section.id}>{section.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            {/* Date Inputs */}
                            <div className="flex gap-4">
                                <div className="flex flex-col w-1/2">
                                    <label className="mb-1">Due Date</label>
                                    <div className="border rounded-lg w-full">
                                        <input
                                            type="date"
                                            className="m-2 w-[95%]"
                                            value={dueDate}
                                            onChange={(e) => setDueDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col w-1/2">
                                    <label className="mb-1">Publish Date</label>
                                    <div className="border rounded-lg w-full">
                                        <input
                                            type="date"
                                            className="m-2 w-[95%]"
                                            value={publishDate}
                                            onChange={(e) => setPublishDate(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Buttons */}
                            <div className="flex gap-4 mt-4">
                                <Button
                                    size="3xl"
                                    variant="outline"
                                    shape="round"
                                    className="min-w-[200px] font-roboto font-medium tracking-[0.10px] sm:px-5"
                                >
                                    Save Draft
                                </Button>

                                <Button
                                    size="3xl"
                                    variant="outline"
                                    shape="round"
                                    className="w-full min-w-[200px] bg-blue-500 font-roboto font-medium tracking-[0.10px] sm:px-5"
                                    onClick={handleReleaseAssignment}
                                    disabled={isSubmitting}
                                >
                                    <span className="text-white-A700">
                                        {isSubmitting ? "Please wait..." : "Release Assignment"}
                                    </span>
                                </Button>
                            </div>

                            {/* Success or Error Messages */}
                            {successMessage && (
                                <div className="mt-4 p-4 text-green-600 bg-green-100 border border-green-400 rounded">
                                    {successMessage}
                                </div>
                            )}
                            {error && !successMessage && (  // Only show error if no success message
                                <div className="mt-4 p-4 text-red-600 bg-red-100 border border-red-400 rounded">
                                    <IconAlertCircle className="inline-block mr-2" />
                                    {error}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
