import React from "react";
import { Heading } from "..";
import Chart from 'react-apexcharts'

export default function StartupOwnership() {
  const series = [75, 15, 5, 3, 2];
  const labels = ['Founders', 'Investor', 'Employees', 'Advisors', 'Unassigned'];
  const colors = ['#3b00ed', '#9a26ad', '#d81b60', '#ff9800', '#d7d7d7'];
  const options = {series: series, labels: labels, colors: colors, plotOptions: { pie: { customScale: 1.0, donut: { labels: { total: { showAlways: true } }}  }}};

  return (
  <div className="flex flex-col w-full h-full rounded-lg border border-solid border-slate-300 bg-white-A700 sm:flex-col">
    <div className="flex flex-row w-full px-4 py-3 justify-content items-center">
      <div className="flex items-center gap-2 px-[15px] sm:px-5">
        <div className="flex self-start">
          <Heading size="4xl" as="h4" className="!font-poppins !text-gray-800_02 mr-3">
          Ownership Distribution     
          </Heading>
          <div className="relative group">
            <div className="flex flex-col items-center justify-center w-4 h-4 bg-white-A700 text-xs border-2 border-gray-500_02 rounded-full">
              i   
            </div>
            <div style={{zIndex: 10000}} className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 w-[calc(100%+15rem)] opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto bg-gray-800 text-white-A700 text-xs rounded py-1 px-2 whitespace-no-wrap">
              <Heading className="!font-poppins !text-white-A700 py-1">Ownership Distribution</Heading>
              <p>Breakdown of business ownership among founders and investors</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full rounded-b-lg border-t border-solid border-slate-300">
        <div className="self-start w-full h-full flex justify-center items-center ">
          <Chart options={options} series={series} labels={labels} colors={colors} type="donut" width="350px" />
        </div>
    </div>
  </div>
  );
}
