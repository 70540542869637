import React, {useEffect, useState, useRef} from "react";
import { Button } from "..";
import { IconAlertCircle } from "@tabler/icons-react";
import { fetchStatus, postSettings } from "StartupSimAPIService";
import { useParams } from 'react-router-dom';
import NotFound from "pages/NotFound";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Redirect } from "react-router-dom";

export default function StartupSimCalendar({setShowPopup, unformattedDate, simDates, setViewingOldDate, setOldDateProgressId}) {
  const [selectedEventData, setSelectedEventData] = useState(null);

  useEffect(() => {
    //console.log(unformattedDate);
  },[])


  const calendarRef = useRef(null); // Create a ref for the FullCalendar instance
  const handleCustomTodayClick = () => {
    const calendarApi = calendarRef.current.getApi();
    const initialDate = unformattedDate;
    calendarApi.gotoDate(initialDate);
  };
  const handleEventClick = (info) => {
    const { title, start, extendedProps } = info.event;
    console.log(unformattedDate + " -- " + start.toISOString().split('T')[0]);
    if(unformattedDate == start.toISOString().split('T')[0]){
      setViewingOldDate(false);
    } else {
      setSelectedEventData({
        title,
        start,
        progressId: extendedProps.progressId
      });
      setViewingOldDate(true);
      setOldDateProgressId(extendedProps.progressId);
    }
    setShowPopup(false);
  };

  return (
    <div className="p-4">
    <div className="flex flex-col gap-3">
    <FullCalendar
        ref={calendarRef}
        height="auto"
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        initialDate={unformattedDate}
        events={simDates}
        eventInteractive={true}
        eventClick={handleEventClick}
        validRange={{
          start: simDates[simDates.length - 1].start, // Earliest date users can navigate to
          end: new Date(new Date(simDates[0].start).getTime() + 24 * 60 * 60 * 1000) // Latest date users can navigate to
          .toISOString()
          .split('T')[0], // Adds one day  
        }}
        /*eventContent={(eventInfo) => {
          var color = "";
          var message = "Current"
          switch(eventInfo.event.extendedProps.affect){
            case "positive":
              color = "green";
              message = "Review";
              break;
            case "negative":
              color = "red";
              message = "Review";
              break;
            default:
              break;
          }
          return (
            <div style={{cursor: "pointer", pointerEvents: "auto"}}> 
              <div style={{  textAlign: 'center', backgroundColor: color, borderColor: color }}>
                <span>{message}</span>
              </div>
            </div>
        )}}*/
        eventContent={(eventInfo) => {
          var color = "";
          var message = "Current"
          var border = "1px solid white"
          switch(eventInfo.event.extendedProps.affect){
            case "positive":
              color = "green";
              message = "Review";
              break;
            case "negative":
              color = "red";
              message = "Review";
              break;
            default:
              break;
          }
          return (
          <div style={{ cursor: "pointer", backgroundColor: color }}>{message}</div>
        )}}
        customButtons={{
          customToday: {
            text: 'Today', // Button text
            click: handleCustomTodayClick, // Custom click handler
          },
        }}
        headerToolbar={{
            left: 'prev,next customToday',
            center: 'title',
            right: '',
        }}
    />
    </div>
</div>
  );
}
