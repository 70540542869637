import React, { useEffect } from 'react';

const EarningsCalendarWidget = ({ width = '100%', height = '100%' }) => {
    useEffect(() => {
        const scriptExists = document.querySelector(
            'script[src="https://widget.finlogix.com/Widget.js"]'
        );

        if (!scriptExists) {
            const script = document.createElement('script');
            script.src = 'https://widget.finlogix.com/Widget.js';
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                if (window.Widget) {
                    window.Widget.init({
                        widgetId: '974ebde9-fd71-4df3-97aa-ee39bd3b550a',
                        type: 'EarningCalendar',
                        language: 'en',
                        importanceOptions: ['low', 'medium', 'high'],
                        dateRangeOptions: [
                            'recentAndNext',
                            'today',
                            'tomorrow',
                            'thisWeek',
                            'nextWeek',
                            'thisMonth',
                        ],
                        isAdaptive: true,
                    });
                }
            };
        } else {
            if (window.Widget) {
                window.Widget.init({
                    widgetId: '974ebde9-fd71-4df3-97aa-ee39bd3b550a',
                    type: 'EarningCalendar',
                    language: 'en',
                    importanceOptions: ['low', 'medium', 'high'],
                    dateRangeOptions: [
                        'recentAndNext',
                        'today',
                        'tomorrow',
                        'thisWeek',
                        'nextWeek',
                        'thisMonth',
                    ],
                    isAdaptive: true,
                });
            }
        }

        return () => {
            const widgetContainer = document.querySelector('.finlogix-container');
            if (widgetContainer) {
                widgetContainer.innerHTML = '';
            }
        };
    }, []);

    return (
        <div className="relative finlogix-container" style={{ width, height }}>
            {/* Widget container */}
            <div className="absolute bottom-0 left-0 w-full h-10 bg-white-A700"></div> {/* Cover footer */}
        </div>
    );
};

export default EarningsCalendarWidget;
