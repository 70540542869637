import React from 'react';
import 'react-tabs/style/react-tabs.css'; // Import default styles
import Skeleton from 'react-loading-skeleton';
import { formatNumber, formatDate } from "utils";

const KeyData = ({ stockInfo }) => {
    const s = stockInfo.summary || {};

    const renderValue = (value, formatter = (val) => val) => {
      return value != null ? formatter(value) : <Skeleton width="90px" height="1em" />;
    };

    return (
        <div className='flex flex-col w-full h-full p-4 bg-white rounded-lg'>
            <span className='font-bold text-lg mb-2'>Key Data</span>
            <div className='grid grid-rows-6 gap-2 text-xs h-full rounded-lg border'>
                {/* Row 1 (bg-gray-200) */}
                <div className='grid grid-cols-2 gap-2 items-center bg-gray-200 rounded-t-lg p-2'>
                    <div className='flex justify-between items-center text-xs'>
                        <span>Previous Close</span>
                        <span className='font-semibold'>
                          {renderValue(s.previousClose)}
                        </span>
                    </div>
                    <div className='flex justify-between items-center text-xs'>
                        <span>Market Cap</span>
                        <span className='font-semibold'>
                          {renderValue(s.marketCap, formatNumber)}
                        </span>
                    </div>
                </div>
                
                {/* Row 2 */}
                <div className='grid grid-cols-2 gap-2 items-center p-2'>
                    <div className='flex justify-between items-center text-xs'>
                        <span>Open</span>
                        <span className='font-semibold'>
                          {renderValue(s.open)}
                        </span>
                    </div>
                    <div className='flex justify-between items-center text-xs'>
                        <span>Beta (5Y Monthly)</span>
                        <span className='font-semibold'>
                          {renderValue(s.beta)}
                        </span>
                    </div>
                </div>
                
                {/* Row 3 (bg-gray-200) */}
                <div className='grid grid-cols-2 gap-2 items-center bg-gray-200 p-2'>
                    <div className='flex justify-between items-center text-xs'>
                        <span>Day&apos;s Range</span>
                        {(s.daysLow != null && s.daysHigh != null) ? (
                            <span className='font-semibold'>{s.daysLow} - {s.daysHigh}</span>
                        ) : (
                            <Skeleton width="90px" />
                        )}
                    </div>
                    <div className='flex justify-between items-center text-xs'>
                        <span>P/E Ratio (TTM)</span>
                        <span className='font-semibold'>
                          {renderValue(s.peRatio)}
                        </span>
                    </div>
                </div>
                
                {/* Row 4 */}
                <div className='grid grid-cols-2 gap-2 items-center p-2'>
                    <div className='flex justify-between items-center text-xs'>
                        <span>52 Week Range</span>
                        {(s.fiftyTwoWeekLow != null && s.fiftyTwoWeekHigh != null) ? (
                            <span className='font-semibold'>{s.fiftyTwoWeekLow} - {s.fiftyTwoWeekHigh}</span>
                        ) : (
                            <Skeleton width="90px" />
                        )}
                    </div>
                    <div className='flex justify-between items-center text-xs'>
                        <span>EPS (TTM)</span>
                        <span className='font-semibold'>
                          {renderValue(s.eps)}
                        </span>
                    </div>
                </div>
                
                {/* Row 5 (bg-gray-200) */}
                <div className='grid grid-cols-2 gap-2 items-center bg-gray-200 p-2'>
                    <div className='flex justify-between items-center text-xs'>
                        <span>Volume</span>
                        <span className='font-semibold'>
                          {renderValue(s.volume, formatNumber)}
                        </span>
                    </div>
                    <div className='flex justify-between items-center text-xs'>
                        <span>Forward Dividend Yield</span>
                        <span className='font-semibold'>
                          {renderValue(s.forwardDividendYield)}
                        </span>
                    </div>
                </div>
                
                {/* Row 6 */}
                <div className='grid grid-cols-2 gap-2 items-center p-2 rounded-b-lg'>
                    <div className='flex justify-between items-center text-xs'>
                        <span>Avg. Volume</span>
                        <span className='font-semibold'>
                          {renderValue(s.averageVolume, formatNumber)}
                        </span>
                    </div>
                    <div className='flex justify-between items-center text-xs'>
                        <span>Ex-Dividend Date</span>
                        <span className='font-semibold'>
                          {renderValue(s.exDividendDate, formatDate)}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KeyData;
