import React, { useEffect } from 'react';

const TopStocksWidget = ({
  theme = "wt_bg_light",
  uid = "1680084366733b6c87a9cd6733b6c87a9d0",
  width = "100%", // Set to 100% to take full container width
  height = "100%", // Set to 100% to take full container height
  stocks = "AAPL,AMZN,BFM:CA,GOOGL,INTC,META,MSFT,NVDA,SHOP,TSLA"
}) => {
  useEffect(() => {
    const scriptExists = document.querySelector(
      'script[src="https://www.stocktargetadvisor.com/assets/js/stocks_analyst_rating_comparison_widget.js"]'
    );

    if (!scriptExists) {
      const script = document.createElement('script');
      script.src = "https://www.stocktargetadvisor.com/assets/js/stocks_analyst_rating_comparison_widget.js";
      script.async = true;
      script.className = "stocks-analyst-rating-comparison";
      script.setAttribute("data-theme", theme);
      script.setAttribute("data-uid", uid);
      script.setAttribute("data-width", "100%");
      script.setAttribute("data-height", "950px");
      script.setAttribute("data-stocks", stocks);
      document.body.appendChild(script);

      return () => {
        // Remove script and container content on component unmount
        if (script.parentNode) {
          script.parentNode.removeChild(script);
        }
        const widgetContainer = document.querySelector('.sta-stocks-analyst-rating-comparison-display');
        if (widgetContainer) {
          widgetContainer.innerHTML = '';
        }
      };
    }
  }, [theme, uid, stocks]);

  return (
    <div className="relative" style={{ width, height }}>
      {/* Main widget container */}
      <div className="sta-stocks-analyst-rating-comparison-display"></div>

      {/* Overlay div to cover footer */}
      <div className="relative bottom-16 left-0  w-full h-16 bg-white-A700"></div> {/* Adjust height as needed */}
    </div>
  );
};

export default TopStocksWidget;
