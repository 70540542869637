import React, { useEffect, useState, useRef } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import AccountComponent from "../AccountComponent";

const accounts = {
    "user": {
        "id": "user_12345",
        "name": "Jane Doe",
        "email": "jane.doe@example.com",
        "createdAt": "2020-05-15T10:30:00Z"
    },
    "accounts": [
        {
            "type": "checking",
            "nickname": "Daily Expenses",
            "accountNumber": "****1234",
            "balance": 1500.75,
            "availableBalance": 1500.75,
            "currency": "USD",
            "interestRate": 0.01,
            "createdAt": "2020-05-15T10:30:00Z"
        },
        {
            "type": "savings",
            "nickname": "Emergency Fund",
            "accountNumber": "****5678",
            "balance": 10000.00,
            "availableBalance": 10000.00,
            "currency": "USD",
            "interestRate": 0.02,
            "createdAt": "2019-03-20T09:00:00Z"
        },
        {
            "type": "credit_card",
            "nickname": "Visa Platinum",
            "accountNumber": "****9012",
            "balance": -235.00,
            "creditLimit": 5000.00,
            "availableCredit": 4765.00,
            "currency": "USD",
            "interestRate": 0.18,
            "createdAt": "2021-07-10T12:00:00Z",
            "dueDate": "2024-12-05T00:00:00Z",
            "minimumPayment": 35.00
        }
    ],
    "currentDate": "2024-11-27T14:30:00+00:00"
};

export default function BudgetSimulatorAccounts() {
    const [selectedEventData, setSelectedEventData] = useState(null);

    useEffect(() => {
        //console.log(unformattedDate);
    }, [])

    return (
        <div className="flex flex-col gap-4 p-4 w-full">

            {accounts.accounts.map((account) => (
                <AccountComponent
                    id={"account"}
                    name={account.type}
                    numbers={account.availableBalance}
                />
            ))}

        </div>
    );
}
