import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Button, Heading } from "../../components";
import Header from "../../components/Header";
import { ReactTable } from "../../components/ReactTable";
import StudentSidebar from "../../components/StudentSidebar";
import { createColumnHelper } from "@tanstack/react-table";
import { useNavigate } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { fetchClassroomDetails } from "TeacherAPIService";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ReactTableHoldings } from "components/ReactTableHoldings";
import ClassPopup from "components/CreateClassPopup";
import AddCourseModal from "components/AddCourseModal";
import { fetchStudentStartupSimAllProgressData } from "SimulatorAPIService";
import Skeleton from "react-loading-skeleton"
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from "react-router-dom";
import StartupProgressSteps from "components/StartupProgressSteps";
import StartupProgressList from "components/StartupProgressList";
import StartupProgressBar from "components/StartupProgressBar";
import StartupInfoItem from "components/StartupInfoItem";
import StartupDecision from "components/StartupDecision";
import StartupOwnership from "components/StartupOwnership";
import StartupFinancials from "components/StartupFinancials";
import StartupTeam from "components/StartupTeam";
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import { formatDate } from "utils";


export default function StudentStartupSimulator({ studentId }) {
    const [startupSimData, setstartupSimData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [readOnlyDecisions, setReadOnlyDecisions] = useState(true);
    const [formatted, setFormatted] = useState(true);
    const [showCalendarPopup, setShowCalendarPopup] = useState(false);
    const [activeSection, setActiveSection] = useState('Student View');
    const fromTeacherView = true;
    //const navigate = useNavigate();

    const numberFormatOptions = { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 };

    const handleActiveSection = (newSection) => {
        setActiveSection(newSection);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (studentId) {
                    const startupSimData = await fetchStudentStartupSimAllProgressData(studentId);
                    setstartupSimData(startupSimData);
                    const date = new Date(startupSimData.progressRecords[0].simDecisionDate);
                    date.setHours(date.getHours() + 6); //Fix for Time Zone
                    const options = { day: 'numeric', month: 'long', year: 'numeric' };
                    setFormatted(date.toLocaleDateString('en-US', options));
                }
            } catch (error) {
                console.error('Error fetching data or posting trade:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [studentId]);


    return (
        <div className="flex flex-col overflow-y-auto gap-4">
            {!isLoading ? (
                <>
                    {(startupSimData.sessionInfo) ? (
                        <>
                        <Tabs>
                            <TabList className={"flex flex-grow justify-end"}>
                                {['Student View', 'Teacher View'].map((section) => (
                                    <Tab
                                        key={section}
                                        className={`px-4 py-2 rounded-full text-gray-700 border-gray-300 focus:outline-none`}
                                        selectedClassName={`px-4 py-2 rounded-full bg-blue-50 text-blue-500 border-gray-300 focus:outline-none`}
                                    >
                                        {section}
                                    </Tab>
                                ))}
                            </TabList>
                            <TabPanel>
                                <>
                                <StartupProgressSteps sessionData={startupSimData.sessionInfo} dashData={startupSimData.progressRecords[0]} stageNumber={startupSimData.stage} />

                                <div className="flex rounded-lg border border-solid border-slate-300 bg-white-A700 sm:flex-col m-3 h-full" style={{overflow: "scroll"}}>
                                    <div className="flex flex-col w-2/3 h-full border-r border-solid border-slate-300">
                                    <div className="m-3">
                                        <StartupProgressBar sessionData={startupSimData.sessionInfo} dashData={startupSimData.progressRecords[0]} stageNumber={startupSimData.stage} stages={startupSimData.stages} />
                                    </div>
                                    <div className="flex flex-row h-full">
                                        <div className="flex flex-col grow-0 w-1/4 h-full">
                                        <div className="mx-3 mb-3">
                                            <StartupInfoItem title="Current Market Value" value={new Intl.NumberFormat('en-US', numberFormatOptions).format(startupSimData.progressRecords[0].currentMarketValue)} description="The current valuation of your business in the market" />
                                        </div>
                                        <div className="mx-3 mb-3">
                                            <StartupInfoItem title="Employee Wellness" value={startupSimData.progressRecords[0].employeeWellness + "/100"} description="Measure of your team’s morale, health, and engagement"/>
                                        </div>
                                        <div className="mx-3 mb-3">
                                            <StartupInfoItem title="Gross Revenue" value={new Intl.NumberFormat('en-US', numberFormatOptions).format(startupSimData.progressRecords[0].grossRevenue)} valueClass={(startupSimData.progressRecords[0].grossRevenue.toString().startsWith("-") ? "!text-red-500" : "!text-green-500")} description="Total income generated from business operations before expenses"/>
                                        </div>
                                        <div className="mx-3 mb-3">
                                            <StartupInfoItem title="Profit/Loss" value={new Intl.NumberFormat('en-US', numberFormatOptions).format(startupSimData.progressRecords[0].profitLoss)} valueClass={(startupSimData.progressRecords[0].profitLoss.toString().startsWith("-") ? "!text-red-500" : "!text-green-500")} description="The net financial outcome after subtracting costs from revenue"/>
                                        </div>
                                        </div>
                                        <div className="flex flex-col grow w-3/4 h-full">
                                        <div className="mr-3 mb-3">
                                            <StartupDecision 
                                                studentId={studentId}
                                                sessionData={startupSimData.sessionInfo}
                                                //setSessionData={setSessionData}
                                                //stageNumber={stageNumber}
                                                //setStageNumber={setStageNumber}
                                                data={startupSimData.progressRecords[0]}
                                                //setData={setDashData}
                                                unformattedDate={startupSimData.latestDate}
                                                date={formatted}
                                                //setDate={setFormattedDate}
                                                //finishedStage={finishedStage}
                                                //setFinishedStage={setFinishedStage}
                                                //showCalendarPopup={showCalendarPopup}
                                                setShowCalendarPopup={setShowCalendarPopup}
                                                //simDates={simDates}
                                                //setSimDates={setSimDates}
                                                //viewingOldDate={viewingOldDate}
                                                //setViewingOldDate={setViewingOldDate}
                                                //readOnlyDecisions={readOnlyDecisions}
                                                setReadOnlyDecisions={setReadOnlyDecisions}
                                                //setOldDateProgressId={setOldDateProgressId}
                                                fromTeacherView={fromTeacherView}
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="flex flex-col w-1/3 h-full">
                                    <div className="flex-row mx-3 my-3">
                                        <StartupOwnership />
                                    </div>
                                    <div className="h-1/2 mx-3 mb-3">
                                        <StartupFinancials stageNumber={startupSimData.stage} revenue={startupSimData.progressRecords[0].annualRevenue} expenses={startupSimData.progressRecords[0].expenses}/>
                                    </div>
                                    <div className="h-1/4 mx-3 mb-3">
                                        <StartupTeam />
                                    </div>
                                    </div>
                                </div>
                                </>
                            </TabPanel>
                            <TabPanel>
                                <div className="flex">
                                    {/* <div className="w-[30%] border rounded-lg p-4 items-center justify-center">
                                        <h2 className="font-semibold">Trading Dates</h2>
                                        
                                        <h2 className="flex h-full items-center justify-center text-center">Coming soon!</h2>
                                    </div> */}
                                    <div className="flex w-full flex-col gap-6 rounded-lg border border-solid border-blue_gray-50_01 bg-white-A700 p-4 ml-3 md:w-full">
                                        <StartupProgressList startupSimData={startupSimData} height={250} />
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                        </>
                    ) : (
                        "Not Yet Started"
                    )}
                </>
            ) : (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                  }}>
                    <Img src="/images/loading.gif" style={{
                      maxWidth: '100%', // Ensures the image scales properly
                      maxHeight: '100%'
                    }}/>
                  </div>
            )}
            
        </div>
    );
};

