import React, {useEffect, useState} from "react";
import { Text, Button, Heading, Img, Input } from "..";
import { IconBuildingBank, IconCashBanknote, IconCreditCard } from "@tabler/icons-react";

export default function StartupFinancials({stageNumber, revenue, expenses}) {
  const [salaries, setSalaries] = useState(0);
  const [rent, setRent] = useState(0);
  const [marketing, setMarketing] = useState(0);
  const [other, setOther] = useState(0);

  const numberFormatOptions = { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 };

  useEffect(() => {
    setSalaries((expenses*.6));
    setRent((expenses*.09));
    setMarketing((expenses*.11));
    setOther((expenses*.2));
  },[revenue, expenses])


  return (
    <div className="flex flex-col w-full h-full rounded-lg border border-solid border-slate-300 bg-white-A700 sm:flex-col">
    <div className="flex flex-row w-full px-4 py-3 justify-content items-center">
      <div className="flex items-center gap-2 border-blue_gray-100 px-[15px] sm:px-5">
        <div className="flex self-start">
          <Heading size="4xl" as="h4" className="!font-poppins !text-gray-800_02 mr-3">
            Annual Financials     
          </Heading>
          <div className="relative group">
            <div className="flex flex-col items-center justify-center w-4 h-4 bg-white-A700 text-xs border-2 border-gray-500_02 rounded-full">
              i   
            </div>
            <div className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 w-[calc(100%+15rem)] opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto bg-gray-800 text-white-A700 text-xs rounded py-1 px-2 whitespace-no-wrap">
              <Heading className="!font-poppins !text-white-A700 py-1">Annual Financials</Heading>
              <p>Summary of yearly income, expenses, and financial performance</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-row w-full rounded-b-lg border-t border-solid border-slate-300 h-full">
    {(stageNumber == 1) ? (
      <div className="items-center w-full">
        <div className="flex justify-center items-center h-64 border text-center"><span>Financials will be calculated after the first year has completed</span></div>
      </div>
    ) : (
      <div className="flex flex-col items-center gap-2 border-blue_gray-100 py-3 px-5 w-full">
        <div className="flex flex-row w-full h-1/5 items-center">
          <div className="w-1/6 text-center">
            <IconBuildingBank />
          </div>
          <div className="w-4/6">
            <Text size="6xl" className="!font-poppins !text-gray-800_02">
              Revenue          
            </Text>
          </div>
          <div className="w-1/6 text-right">
            <Text size="6xl" className="!font-poppins !text-green-500">
              {new Intl.NumberFormat('en-US', numberFormatOptions).format(revenue)}          
            </Text>
          </div>
        </div>
        <div className="flex flex-row w-full h-1/5 items-center">
          <div className="w-1/6 text-center">
            <IconBuildingBank />
          </div>
          <div className="w-4/6">
            <Text size="6xl" className="!font-poppins !text-gray-800_02">
              Salaries          
            </Text>
          </div>
          <div className="w-1/6 text-right">
            <Text size="6xl" className="!font-poppins !text-red-500">
              {new Intl.NumberFormat('en-US', numberFormatOptions).format(-1*salaries)}          
            </Text>
          </div>
        </div>
        <div className="flex flex-row w-full h-1/5 items-center">
          <div className="w-1/6 text-center">
            <IconCreditCard />
          </div>
          <div className="w-4/6">
            <Text size="6xl" className="!font-poppins !text-gray-800_02">
              Rent          
            </Text>
          </div>
          <div className="w-1/6 text-right">
            <Text size="6xl" className="!font-poppins !text-red-500">
              {new Intl.NumberFormat('en-US', numberFormatOptions).format(-1*rent)}          
            </Text>
          </div>
        </div>
        <div className="flex flex-row w-full h-1/5 items-center">
          <div className="w-1/6 text-center">
            <IconCashBanknote />
          </div>
          <div className="w-4/6">
            <Text size="6xl" className="!font-poppins !text-gray-800_02">
              Marketing          
            </Text>
          </div>
          <div className="w-1/6 text-right">
            <Text size="6xl" className="!font-poppins !text-red-500">
              {new Intl.NumberFormat('en-US', numberFormatOptions).format(-1*marketing)}          
            </Text>
          </div>
        </div>
        <div className="flex flex-row w-full h-1/5 items-center">
          <div className="w-1/6 text-center">
            <IconCashBanknote />
          </div>
          <div className="w-4/6">
            <Text size="6xl" className="!font-poppins !text-gray-800_02">
              Other Expenses          
            </Text>
          </div>
          <div className="w-1/6 text-right">
            <Text size="6xl" className="!font-poppins !text-red-500">
              {new Intl.NumberFormat('en-US', numberFormatOptions).format(-1*other)}          
            </Text>
          </div>
        </div>
      </div>
    )}
    </div>
  </div>
  );
}
