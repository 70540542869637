import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Text, Heading, Img, Button } from "../../components";
import CourseCard from "../../components/CourseCard";
import HomeAssignmentCard from "../../components/HomeAssignmentCard";
import HomeDate from "../../components/HomeDate";
import StudentSidebar from "../../components/StudentSidebar";
import DonutChart from "../../components/DounutChart";
import { useNavigate } from 'react-router-dom';
import { ConvertDuration } from "utils";
import PortfolioChart from "components/PortfolioChart";
import { fetchInfo, fetchStudentId, fetchStudentPortfolioGraph } from "SimulatorAPIService";
import { fetchStudentStartupSimResumeData } from "SimulatorAPIService";
import { fetchCourses } from "StudentAPIService";
import DashboardCalendar from "components/DashboardCalendar";
import StartupInfoItem from "components/StartupInfoItem";
import Sidebar1 from "components/Sidebar1";
import { UserContext } from 'UserContext';

const categories = [
  { name: "Gas", value: 15, color: "#9ECCFE" },
  { name: "Rent", value: 15, color: "#FDEFC5" },
  { name: "Food", value: 15, color: "#FBE6F5" },
  { name: "Utilities", value: 15, color: "pink" },
  { name: "Misc", value: 15, color: "#FE94FC" }
];

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;

const numberFormatOptions = { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 };

export default function StudentDashboardPage() {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [studentId, setStudentId] = useState();
  const [info, setInfo] = useState();
  const { userInfo, setUserInfo } = useContext(UserContext);


  const [portfolioGraph, setPortfolioGraph] = useState([]);
  const [startupSimDashData, setStartupSimDashData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = await fetchStudentId();
        setStudentId(id);

        if (id) {
          // Fetch courses data
          const coursesData = await fetchCourses();

          if (coursesData) {
            setCourses(
              coursesData.flatMap((s) =>
                s.courses.map((c) => ({
                  studentId: s.studentId,
                  course: c.course,
                  class: c.class,
                }))
              )
            );
          }

          // Fetch portfolio data
          const portfolioData = await fetchStudentPortfolioGraph(id);
          setPortfolioGraph(portfolioData);

          // Fetch startupSim data
          const startupSimData = await fetchStudentStartupSimResumeData(id);
          setStartupSimDashData(startupSimData.progress);
        }
      } catch (error) {
        if (error.status === 401) {
          navigate('/timeout');
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const handleCardClick = () => {
    navigate('/courseview');
  };

  const handleViewAllCourses = () => {
    navigate('/courses');
  };

  return (
    <>
      <Helmet>
        <title>Dashboard | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>

      <div className="relative w-full h-full">
        <div className="absolute inset-0 -z-10">
          <div className="relative h-[400px] w-full">
            <Img src="images/img_rectangle_9_309x1352.png" alt="image" className="h-full w-full object-fill" />
            <div className="absolute inset-0">
              <div className="h-full w-full relative">
                <div className="absolute bottom-0 left-0 right-0 h-[100px] bg-gradient-to-t from-white to-transparent"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-full h-screen overflow-hidden m-auto">

          <div><StudentSidebar /></div>

          <div className="flex flex-col w-full m-4 gap-4">
            <HomeDate className="w-full px-4 rounded-lg" />
            <div className="flex w-full rounded-lg gap-4 overflow-y-auto">

              <div className="flex flex-col w-[65%] h-full gap-4">

                <div className="flex flex-col p-3 gap-2 rounded-lg" style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>

                  <div className="flex items-start justify-start gap-2 rounded md:w-full">
                    <Img
                      src="images/img_new_releases.svg"
                      alt="newreleases"
                      className="h-[16px] w-[16px] self-start"
                    />
                    <Heading as="h1" className="uppercase">
                      DID You KNOW?
                    </Heading>
                  </div>


                  <div className="flex flex-col items-end gap-3 ">
                    <h1 className="w-full text-sm sm:text-md md:text-md font-inter font-semibold !text-blue_gray-900_01">
                      Nearly 9 in 10 U.S. adults said high school did not leave them “fully prepared” for handling money in the real world.
                    </h1>

                    <h2 className="text-xs md:text-sm !text-blue_gray-900_01">
                      - Financial Literacy Crisis Report 2023
                    </h2>

                  </div>

                </div>


                <div className="flex flex-col gap-3.5 rounded-lg border border-solid border-blue_gray-100 bg-white-A700 px-4 pb-[31px] pt-4 md:ml-0 md:w-full sm:pb-5">
                  <div className="flex justify-between gap-5">
                    <Heading size="6xl" as="h3" className="!text-gray-900_02">
                      My Courses
                    </Heading>
                    <div className="flex items-center gap-1 self-start">
                      <a href="#">
                        <Text as="p" className="!text-blue-500" onClick={handleViewAllCourses} style={{ cursor: 'pointer' }}>
                          View All
                        </Text>
                      </a>
                      <Img src="images/img_arrow_right_blue_a200.svg" alt="arrowright" className="h-[16px] w-[16px]" />
                    </div>
                  </div>
                  <div className="flex gap-4 rounded-lg">
                    {courses.slice(0, 3).map((course, index) => (
                      <CourseCard
                        key={index}
                        className={course.class.name} // Adjust according to  data structure
                        courseName={course.course.name} // Example: "Not Started", "Continue"
                        expectedDuration={course.course.expectedDuration}
                        durationUnit={ConvertDuration(course.course.durationUnit)}
                        courseId={course.course.id}
                        studentId={studentId}
                        //TODO: choose color based on some pseudorandom property
                        //TODO: Get completion
                        to={`/courses/course/${course.course.id}/student/${course.studentId}`} // Adjust routing as needed
                      />
                    ))}
                  </div>
                </div>

                <div className="flex flex-col gap-2 rounded-lg border border-solid bg-white-A700 border-blue_gray-100 p-4">
                  <div className="flex self-start pb-[7px] pt-[11px]">
                    <Heading size="6xl" as="h2">
                      My Stock Simulation Portfolio
                    </Heading>
                  </div>
                  <PortfolioChart portfolioData={portfolioGraph} height={200} />
                </div>

                <div className="flex flex-col gap-2 rounded-lg border border-solid bg-white-A700 border-blue_gray-100 p-4">
                  <div className="flex self-start pb-[7px] pt-[11px]">
                    <Heading size="6xl" as="h2">
                      My Startup Simulation Progress
                    </Heading>
                  </div>
                  {!startupSimDashData.currentMarketValue ? (
                    <></>
                  ) : (
                    <div className="flex flex-row">
                      <div style={{ display: "inline" }} className="mx-3 mb-3 w-1/4">
                        <StartupInfoItem title="Current Market Value" value={new Intl.NumberFormat('en-US', numberFormatOptions).format(startupSimDashData?.currentMarketValue)} description="The current valuation of your business in the market" />
                      </div>
                      <div style={{ display: "inline" }} className="mx-3 mb-3 w-1/4">
                        <StartupInfoItem title="Employee Wellness" value={startupSimDashData?.employeeWellness + "/100"} description="Measure of your team’s morale, health, and engagement" />
                      </div>
                      <div style={{ display: "inline" }} className="mx-3 mb-3 w-1/4">
                        <StartupInfoItem title="Gross Revenue" value={new Intl.NumberFormat('en-US', numberFormatOptions).format(startupSimDashData?.grossRevenue)} valueClass={(startupSimDashData?.grossRevenue?.toString().startsWith("-") ? "!text-red-500" : "!text-green-500")} description="Total income generated from business operations before expenses" />
                      </div>
                      <div style={{ display: "inline" }} className="mx-3 mb-3 w-1/4">
                        <StartupInfoItem title="Profit / Loss" value={new Intl.NumberFormat('en-US', numberFormatOptions).format(startupSimDashData?.profitLoss)} valueClass={(startupSimDashData?.profitLoss?.toString().startsWith("-") ? "!text-red-500" : "!text-green-500")} description="The net financial outcome after subtracting costs from revenue" />
                      </div>
                    </div>
                  )}
                </div>

              </div>

              <div className="flex flex-col flex-grow h-full gap-4">

                <div className="h-full w-full border border-solid border-gray-300 rounded-lg">
                  {studentId && <DashboardCalendar studentId={studentId} />}
                </div>

                <div className="relative flex flex-grow w-full h-full min-h-[300px] bg-white-A700 border border-solid border-gray-300 items-center justify-center rounded-lg">

                  <div className="flex flex-grow"> {/* TODO get rid of this absolute height, try to find a way to make it dynamic with the portfolio*/}
                    <div className="absolute inset-0 flex items-center justify-center blur-md">
                      <div className="flex flex-col items-center">
                        <DonutChart size={200} thickness={20} categories={categories} />

                        <div className="flex flex-col w-[86%] justify-center md:w-full">
                          <div className="flex justify-center gap-2">
                            {categories.slice(0, 2).map(category => (
                              <div key={category.name} className="flex items-center gap-2">
                                <div className="h-4 w-4 rounded-full" style={{ backgroundColor: category.color }} />
                                <Text as="p" className="!text-blue_gray-500">
                                  {category.name}
                                </Text>
                              </div>
                            ))}
                          </div>
                          <div className="flex justify-center gap-2">
                            {categories.slice(2).map(category => (
                              <div key={category.name} className="flex items-center gap-2">
                                <div className="h-4 w-4 rounded-full" style={{ backgroundColor: category.color }} />
                                <Text as="p" className="!text-blue_gray-500">
                                  {category.name}
                                </Text>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="flex flex-col items-center justify-center gap-4 ">
                        <h2 className="text-white text-2xl font-bold">Budget Simulator</h2>
                        <h2 className="text-white text-xl font-bold">Coming Fall 2024!</h2>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  );
}
