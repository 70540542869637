import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <div style={{ height: "100vh", padding: "20px", backgroundColor: "#232323", color: "white" }}>
      <ul style={{ listStyle: "none", padding: "0" }}>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/login" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LoginPage
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/register" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Register
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/registerteacher" style={{ color: "#87CEFA", textDecoration: "none" }}>
            RegisterTeacher
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/registerteacherone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            RegisterTeacherOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/registerone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            RegisterOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/registerteachertwo" style={{ color: "#87CEFA", textDecoration: "none" }}>
            RegisterTeacherTwo
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/myprofile" style={{ color: "#87CEFA", textDecoration: "none" }}>
            MyProfile
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/home1" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Home1
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/courses" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Courses
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/courseview" style={{ color: "#87CEFA", textDecoration: "none" }}>
            CourseView
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/learningview" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LearningView
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/learningviewone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LearningViewOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/learningviewtwo" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LearningViewTwo
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/learningviewthree" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LearningViewThree
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/tryagainmodal" style={{ color: "#87CEFA", textDecoration: "none" }}>
            TryAgainModal
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/learningviewfour" style={{ color: "#87CEFA", textDecoration: "none" }}>
            LearningViewFour
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/teachersdashboard" style={{ color: "#87CEFA", textDecoration: "none" }}>
            TeachersDashboard
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/classeshome" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Classeshome
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/assignedcourses" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Assignedcourses
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/students" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Students
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/instructor" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Instructor
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/performancecourses" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Performancecourses
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/performancestock" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Performancestock
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/dashboard" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Dashboard
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/orders" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Orders
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/holdings" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Holdings
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/assignments" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Assignments
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/stockview" style={{ color: "#87CEFA", textDecoration: "none" }}>
            StockView
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/watchlist" style={{ color: "#87CEFA", textDecoration: "none" }}>
            Watchlist
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/budgetsimulator" style={{ color: "#87CEFA", textDecoration: "none" }}>
            BudgetSimulator
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/budgetsimulatorone" style={{ color: "#87CEFA", textDecoration: "none" }}>
            BudgetSimulatorOne
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/budgetsimulatortwo" style={{ color: "#87CEFA", textDecoration: "none" }}>
            BudgetSimulatorTwo
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/budgetsimulatorthree" style={{ color: "#87CEFA", textDecoration: "none" }}>
            BudgetSimulatorThree
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/budgetsimulatorfour" style={{ color: "#87CEFA", textDecoration: "none" }}>
            BudgetSimulatorFour
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link to="/temp" style={{ color: "#87CEFA", textDecoration: "none" }}>
            temp
          </Link>
        </li>
      </ul>
    </div>
  );
};
export default Home;
