import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { CloseSVG } from "../../assets/images";
import { Img, Heading, Text, Input } from "../../components";
import DashboardWatchlist from "../../components/DashboardWatchlist";
import StudentSidebar from "../../components/StudentSidebar";
import { TabPanel, TabList, Tab, Tabs } from "react-tabs";
import { Link } from 'react-router-dom';
import TickerTape from "components/TickerTape";
import StockMarketOverviewWatchlist from "components/StockMarketOverviewWatchlist";
import MarketDataWatchlist from "components/MarketDataWatchlist";
import StockMarketWatchlist from "components/StockMarketWatchlist";
import StockSearch from "components/StockSearch";
import StockSimulatorTabs from "components/StockSimulatorTabs";

export default function WatchlistPage() {
  const [searchBarValue35, setSearchBarValue35] = React.useState("");

  return (
    <>
      <Helmet>
        <title>intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>

      <div className="flex w-full h-screen overflow-hidden m-auto">

        <div><StudentSidebar /></div>

        <div className="flex flex-col w-full">
          <TickerTape />

          <div className="flex w-full h-full">

            <div className="flex flex-grow flex-shrink-0 w-[25%] ml-4 mt-4 h-[94%] border rounded-lg">
              <StockSearch />
            </div>

            <div className="flex flex-col justify-start  w-full h-[94%] gap-4 m-4 border border-solid rounded-lg ">

              <div className="flex w-full gap-6 border-b p-4 justify-end">
                <StockSimulatorTabs currentTab={"watchlist"} />
              </div>

              <div className="flex flex-col gap-4 p-4 overflow-auto">

                <div className="flex flex-col items-stretch w-full gap-1.5">
                  <div className="flex flex-col h-max gap-3.5">
                    <div className="flex self-start">
                      <Heading size="11xl" as="h2">
                        Watchlist
                      </Heading>
                    </div>
                    <div className="flex h-full items-center justify-evenly gap-4 min-h-[500px]">
                      <div className="flex flex-col w-full h-full">
                        <div className="flex">
                          <Heading size="6xl" as="h3" className="p-4">
                            Stock Market
                          </Heading>
                        </div>
                        <div className="flex-grow">
                          <StockMarketWatchlist />
                        </div>

                      </div>
                      <div className="flex flex-col w-full h-full">
                        <div className="flex">
                          <Heading size="6xl" as="h4" className="p-4">
                            Market Data
                          </Heading>
                        </div>
                        <StockMarketOverviewWatchlist />
                      </div>
                    </div>
                    <div className="rounded-lg">
                      <div className="flex flex-col h-full items-start ">
                        <Heading size="6xl" as="h5" className="p-4">
                          Market Overview
                        </Heading>
                        <div className="flex flex-grow h-full self-stretch rounded-lg bg-white-A700 min-h-[700px] ">
                          <MarketDataWatchlist />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>


      </div>


    </>
  );
}


