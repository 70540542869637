import React, { useState, useEffect } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';
import { IconChevronDown } from '@tabler/icons-react';
import TotalMissedQuestionsChart from "components/TotalMissedQuestionsChart";
import AverageQuizCompletionTimeChart from "components/AverageQuizCompletionTimeChart";
import { fetchClassroomAnalytics } from "TeacherAPIService"; // Import the API service

const Spinner = () => (
    <div className="spinner-container">
        <div className="spinner"></div>
    </div>
);

// Helper function to format time in seconds
const formatTime = (seconds) => {
    if (seconds >= 86400) {
        return '>24h'; // Capped at 24 hours
    } else if (seconds >= 3600) {
        return `${(seconds / 3600).toFixed(0)}h`; // If over 3600 seconds, show hours
    } else if (seconds >= 60) {
        return `${(seconds / 60).toFixed(0)}m`; // If over 60 seconds, show minutes
    } else {
        return `${seconds.toFixed(0)}s`; // Show seconds if under a minute
    }
};

export default function ClassroomPerformance({ classroomData }) {
    const [classData, setClassData] = useState(null); // Holds the fetched data
    const [selectedUnitId, setSelectedUnitId] = useState(null); // Keeps track of selected unit
    const [selectedCourseId, setSelectedCourseId] = useState(null); // Keeps track of selected course
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [isUnitDropdownOpen, setIsUnitDropdownOpen] = useState(false); // For unit dropdown
    const [isCourseDropdownOpen, setIsCourseDropdownOpen] = useState(false); // For course dropdown

    useEffect(() => {
        // Set the initial selected course ID
        if (classroomData.courses && classroomData.courses.length > 0) {
            setSelectedCourseId(classroomData.courses[0].id);
        }
    }, [classroomData.courses]);

    useEffect(() => {
        // Fetch classroom analytics data when the selected course changes
        async function fetchData() {
            if (!selectedCourseId) return;
            setIsLoading(true);
            try {
                const classroomId = classroomData.id;

                // Fetch data from the endpoint
                const analyticsData = await fetchClassroomAnalytics(classroomId, selectedCourseId);
                setClassData(analyticsData);

                // Automatically select the first unit on initial load
                if (analyticsData?.unitsStatistics?.length > 0) {
                    // Sort units before setting the selected unit
                    const sortedUnits = sortUnitsByLeadingNumber(analyticsData.unitsStatistics);
                    setSelectedUnitId(sortedUnits[0].unitId);
                }

                setIsLoading(false); // Data is fetched, stop loading
            } catch (error) {
                console.error("Error fetching classroom analytics:", error);
                setIsLoading(false); // Even in case of error, stop loading
            }
        }

        fetchData();
    }, [classroomData.id, selectedCourseId]);

    // Helper function to sort units by leading number in unitName
    const sortUnitsByLeadingNumber = (unitsArray) => {
        return [...unitsArray].sort((a, b) => {
            const numA = parseInt(a.unitName.match(/^\d+/));
            const numB = parseInt(b.unitName.match(/^\d+/));
            return numA - numB;
        });
    };

    if (isLoading) {
        return (
            <div className="flex flex-col h-[85vh] justify-center items-center">
                <div><Spinner /></div>
            </div>
        );
    }

    if (!classData) {
        return <div>No data available</div>;
    }

    // Sort units for display
    const sortedUnitsStatistics = sortUnitsByLeadingNumber(classData.unitsStatistics);

    return (
        <div className="flex flex-col w-full items-center p-4 gap-8 h-[80vh] overflow-auto">
            {/* Course Selection Dropdown */}
            <div className="w-full flex gap-4 justify-start">
                <h1 className="font-semibold h-full text-center items-center flex justify-center">Course: </h1>
                <div className="relative w-full max-w-xs">
                    <button
                        onClick={() => setIsCourseDropdownOpen(!isCourseDropdownOpen)}
                        className="flex items-center justify-between px-4 py-2 gap-1 text-gray-700 bg-white-A700 border border-gray-300 rounded-md shadow-sm focus:outline-none w-full"
                    >
                        <div className="flex items-center text-sm truncate">
                            <span>
                                {classroomData.courses.find(course => course.id === selectedCourseId)?.name || "Select Course"}
                            </span>
                        </div>
                        <svg
                            className={`w-4 h-4 transform ${isCourseDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M19 9l-7 7-7-7"
                            ></path>
                        </svg>
                    </button>
                    {isCourseDropdownOpen && (
                        <div className="absolute z-10 w-full mt-2 bg-white-A700 border border-gray-300 rounded-lg shadow-md overflow-auto max-h-60">
                            {classroomData.courses.map((course) => (
                                <div
                                    key={course.id}
                                    onClick={() => {
                                        setSelectedCourseId(course.id);
                                        setIsCourseDropdownOpen(false);
                                    }}
                                    className="flex items-center rounded-lg p-4 text-gray-700 cursor-pointer bg-white-A700 hover:bg-gray-100"
                                >
                                    <span>{course.name}</span>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {/* Top statistics section */}
            <div className="grid grid-cols-4 gap-4 w-full max-h-[320px]">
                {/* Top Student Card */}
                <div className="flex flex-col items-center text-center justify-between gap-2 p-4 border rounded-lg">
                    <h1 className="font-semibold text-md">🚀 Top Student</h1>
                    <div>
                        <h1 className="font-bold text-2xl">{classData.topStudentFirstName}</h1>
                        <h1 className="font-bold text-2xl">{classData.topStudentLastName}</h1>
                    </div>
                    <div className="flex gap-2 md:hidden">
                        <div>
                            <h2 className="text-sm font-bold">Total Completed Quizzes</h2>
                            <h2 className="text-xl font-semibold">{classData.topStudentTotalCompletedQuizzes}</h2>
                        </div>
                        <div>
                            <h2 className="text-sm font-semibold">Total Missed Questions</h2>
                            <h2 className="text-xl font-bold">{classData.topStudentTotalMissedQuestions}</h2>
                        </div>
                    </div>
                    <div className="lg:hidden"></div>
                </div>

                {/* Total Correct Answers Card */}
                <div className="flex flex-col items-center text-center justify-between gap-2 p-4 border rounded-lg">
                    <h1 className="font-semibold text-md">✅ Total Correct Answers</h1>
                    <h1 className="font-bold text-6xl">{classData.totalCorrectAnswers}</h1>
                    <h1></h1>
                </div>

                {/* Average Lessons Completed Card */}
                <div className="flex flex-col items-center text-center justify-between gap-2 p-4 border rounded-lg">
                    <h1 className="font-semibold text-md">📊 Average Lessons Completed</h1>
                    <h1 className="font-bold text-6xl">{(classData.avgLessonsPerStudent ?? 0).toFixed(1)}</h1>
                    <h1></h1>
                </div>

                {/* Average Quizzes Completed Card */}
                <div className="flex flex-col items-center text-center justify-between gap-2 p-4 border rounded-lg">
                    <h1 className="font-semibold text-md">📝 Average Quizzes Completed</h1>
                    <h1 className="font-bold text-6xl">{(classData.avgQuizzesPerStudent ?? 0).toFixed(1)}</h1>
                    <h1></h1>
                </div>
            </div>

            <div className="h-full w-full">
                {/* Unit Selection Dropdown */}
                <div className="flex gap-4 p-4 justify-start items-center">
                    <h1 className="font-semibold h-full text-center items-center flex">Unit: </h1>
                    <div className="relative min-w-[20%]">
                        <button
                            onClick={() => setIsUnitDropdownOpen(!isUnitDropdownOpen)}
                            className="flex items-center justify-between px-4 py-2 gap-1 text-gray-700 bg-white-A700 border border-gray-300 rounded-md shadow-sm focus:outline-none w-full"
                        >
                            <div className="flex items-center text-sm truncate">
                                <span>
                                    {sortedUnitsStatistics.find(unit => unit.unitId === selectedUnitId)?.unitName || "Select Unit"}
                                </span>
                            </div>
                            <svg
                                className={`w-4 h-4 transform ${isUnitDropdownOpen ? 'rotate-180' : 'rotate-0'}`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M19 9l-7 7-7-7"
                                ></path>
                            </svg>
                        </button>
                        {isUnitDropdownOpen && (
                            <div className="absolute z-10 w-full mt-2 bg-white-A700 border border-gray-300 rounded-lg shadow-md overflow-auto max-h-60">
                                {sortedUnitsStatistics.map((unit) => (
                                    <div
                                        key={unit.unitId}
                                        onClick={() => {
                                            setSelectedUnitId(unit.unitId);
                                            setIsUnitDropdownOpen(false);
                                        }}
                                        className="flex items-center rounded-lg p-4 text-gray-700 cursor-pointer bg-white-A700 hover:bg-gray-100"
                                    >
                                        <span>{unit.unitName}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                {/* Charts Section */}
                <div className="flex justify-between gap-4 max-h-[380px] p-4 md:flex-col md:max-h-[700px]">
                    <div className="border rounded-lg p-4 w-[50%] md:w-full">
                        <TotalMissedQuestionsChart classData={classData} selectedUnitId={selectedUnitId} />
                    </div>

                    <div className="border rounded-lg p-4 w-[50%] md:w-full">
                        <AverageQuizCompletionTimeChart classData={classData} selectedUnitId={selectedUnitId} />
                    </div>
                </div>
            </div>

            {/* Accordion Section */}
            <div className="w-full">
                <Accordion allowMultipleExpanded allowZeroExpanded className="border rounded-lg w-full">
                    {sortedUnitsStatistics.map((unit, unitIndex) => (
                        <AccordionItem
                            key={`unit-${unitIndex}`}
                            uuid={`unit-${unitIndex}`}
                            className="p-2 flex flex-col border-gray-300 rounded-lg mb-4 bg-white-A700 gap-4"
                        >
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <AccordionItemState>
                                        {({ expanded }) => (
                                            <div className="flex items-center justify-between px-4 py-2">
                                                <div className="flex items-center gap-2 transition">
                                                    <IconChevronDown
                                                        size={16}
                                                        className={`transform transition-transform duration-200 ${expanded ? 'rotate-180' : 'rotate-0'}`}
                                                    />
                                                    <span className="font-medium">{unit.unitName}</span>
                                                </div>
                                                <div className="flex items-center gap-2">
                                                    <span className="text-sm font-semibold">
                                                        {`${unit.sectionsStatistics.length} Sections`}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </AccordionItemState>
                                </AccordionItemButton>
                            </AccordionItemHeading>

                            <AccordionItemPanel className="bg-gray-200 rounded-lg p-2 gap-4">
                                <Accordion allowMultipleExpanded allowZeroExpanded>
                                    {/* Sort sections if needed */}
                                    {unit.sectionsStatistics.map((section, sectionIndex) => (
                                        <AccordionItem
                                            key={`section-${unitIndex}-${sectionIndex}`}
                                            uuid={`section-${unitIndex}-${sectionIndex}`}
                                            className="p-2 flex flex-col border border-gray-300 rounded-lg mb-4 bg-white-A700 gap-4"
                                        >
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    <AccordionItemState>
                                                        {({ expanded }) => (
                                                            <div
                                                                className="grid grid-cols-4 gap-4 p-2"
                                                                style={{ gridTemplateColumns: '2fr 1fr 1fr 1fr' }}
                                                            >
                                                                <div className="flex items-center gap-2 transition">
                                                                    <IconChevronDown
                                                                        size={16}
                                                                        className={`transform transition-transform duration-200 ${expanded ? 'rotate-180' : 'rotate-0'}`}
                                                                    />
                                                                    <span>{section.sectionName}</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </AccordionItemState>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>

                                            <AccordionItemPanel className="bg-white-A700 rounded-lg p-6">
                                                <div className="grid grid-cols-2 gap-8">
                                                    <div className="flex items-center justify-between border-b">
                                                        <span className="font-semibold text-gray-700">Total Missed Questions</span>
                                                        <span className="text-gray-900 font-bold">{section.totalMissedQuestionsInSection}</span>
                                                    </div>

                                                    <div className="flex items-center justify-between border-b">
                                                        <span className="font-semibold text-gray-700">Avg. Quiz Completion Time</span>
                                                        <span className="text-gray-900 font-bold">
                                                            {formatTime(section.avgQuizCompletionTimeInSeconds ?? 0)}
                                                        </span>
                                                    </div>

                                                    <div className="flex items-center justify-between border-b">
                                                        <span className="font-semibold text-gray-700">Total Completed Lessons</span>
                                                        <span className="text-gray-900 font-bold">{section.totalCompletedLessonsInSection}</span>
                                                    </div>

                                                    <div className="flex items-center justify-between border-b">
                                                        <span className="font-semibold text-gray-700">Total Completed Quizzes</span>
                                                        <span className="text-gray-900 font-bold">{section.totalCompletedQuizzesInSection}</span>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    ))}
                                </Accordion>
                            </AccordionItemPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </div>
        </div>
    );
}
