import React from 'react';
import Chart from 'react-apexcharts';

const AveragePerformanceChart = ({ analyticsData }) => {
    const modules = analyticsData.map((_, index) => `Module ${index + 1}`);
    const retries = analyticsData.map((module) =>
        module?.sections?.reduce((total, section) => total + section.retriesUsed, 0)
    );

    const chartOptions = {
        chart: {
            type: 'line',
            toolbar: {
                show: true,
                tools: {
                    download: true, // Enable download button
                    selection: false, // You can disable some features if needed
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false,
                },
            },
            events: {
                markerClick: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
                    event.stopPropagation();
                }
            },
        },
        xaxis: {
            categories: modules.map((_, index) => (index + 1).toString()), // Only the module numbers
            title: {
                text: 'Module',
            },
            labels: {
                style: {
                    fontSize: '12px',
                },
            },
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
        },
        yaxis: {
            tickAmount: Math.max(...retries), // Ensure we have enough ticks for whole numbers
            labels: {
                formatter: (value) => Math.round(value),
                style: {
                    fontSize: '12px',
                },
            },
        },
        grid: {
            show: true, // Enable grid lines
            yaxis: {
                lines: {
                    show: true, // Show y-axis grid lines
                }
            }
        },
        stroke: {
            curve: 'straight',
        },
        title: {
            text: 'Missed Questions By Module',
            align: 'left'
        },
        markers: {
            size: 5,
            colors: ['#007bff'],
            strokeWidth: 2,
            hover: {
                size: 7,
            },
            showNullDataPoints: false,
        },
        tooltip: {
            enabled: true,
        },
    };

    const chartSeries = [
        {
            name: 'Total Missed Questions',
            data: retries,
        },
    ];

    return (
        <div>
            <Chart
                options={chartOptions}
                series={chartSeries}
                type="line"
                height="320"
            />
        </div>
    );
};

export default AveragePerformanceChart;
