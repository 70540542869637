import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { Heading } from '../../components'; // Ensure this is correctly imported
import PropTypes from 'prop-types';
import { formatUSD } from 'utils';

const PortfolioChart = ({ portfolioData }) => {
    const [filteredData, setFilteredData] = useState([]);
    const [activeRange, setActiveRange] = useState('1W');

    useEffect(() => {
        if (portfolioData && portfolioData.historicalGraphPoints) {
            filterData('1W'); // Default filter to 1 week
        }
    }, [portfolioData]);

    const filterData = (range) => {
        const now = new Date();
        let filtered = [];

        switch (range) {
            case '1W':
                filtered = portfolioData.historicalGraphPoints.filter(point => {
                    const date = new Date(point.dateTime);
                    return now - date <= 7 * 24 * 60 * 60 * 1000;
                });
                break;
            case '1M':
                filtered = portfolioData.historicalGraphPoints.filter(point => {
                    const date = new Date(point.dateTime);
                    return now - date <= 30 * 24 * 60 * 60 * 1000;
                });
                break;
            case '6M':
                filtered = portfolioData.historicalGraphPoints.filter(point => {
                    const date = new Date(point.dateTime);
                    return now - date <= 6 * 30 * 24 * 60 * 60 * 1000;
                });
                break;
            case '1Y':
                filtered = portfolioData.historicalGraphPoints.filter(point => {
                    const date = new Date(point.dateTime);
                    return now - date <= 365 * 24 * 60 * 60 * 1000;
                });
                break;
            case 'All':
            default:
                filtered = portfolioData.historicalGraphPoints;
                break;
        }

        setFilteredData(filtered.map(point => ({
            date: new Date(point.dateTime).toISOString(),
            value: point.portfolioValue
        })));
    };

    const handleRangeChange = (range) => {
        setActiveRange(range);
        filterData(range);
    };

    const options = {
        chart: {
            type: 'area',
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        labels: filteredData.map(d => d.date),
        xaxis: {
            type: 'datetime',
            labels: {
                show: false // Hide x-axis labels
            }
        },
        yaxis: {
            opposite: true,
            labels: {
                show: true, // Show y-axis labels
                formatter: function (val) {
                    return formatUSD(val);
                }
            }
        },
        legend: {
            horizontalAlign: 'left'
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return formatUSD(val);
                }
            }
        },
        fill: {
            type: 'solid',
        },
        colors: ['#2e90fa'],
    };

    const series = [
        {
            name: "Portfolio Value",
            data: filteredData.map(d => d.value)
        }
    ];

    return (
        <div className="flex flex-col h-full grow">
            {/* Chart Container */}
            <Chart
                options={options}
                series={series}
                type="area"
                height="100%" // Ensure Chart takes 100% height of its container
                width="100%"  // Ensure Chart takes 100% width of its container
            />

            {/* Range Buttons */}
            <div className="flex justify-center mb-4 gap-4">
                {['1W', '1M', '6M', '1Y', 'All'].map(range => (
                    <button
                        key={range}
                        className={`rounded transition-colors duration-300 ${activeRange === range
                            ? 'bg-blue-500 text-white-A700'
                            : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                            } py-2 px-4`}
                        onClick={() => handleRangeChange(range)}
                    >
                        {range}
                    </button>
                ))}
            </div>
        </div>
    );
};

PortfolioChart.propTypes = {
    portfolioData: PropTypes.shape({
        historicalGraphPoints: PropTypes.arrayOf(
            PropTypes.shape({
                dateTime: PropTypes.string.isRequired,
                portfolioValue: PropTypes.number.isRequired,
            })
        ).isRequired,
    }).isRequired,
};

export default PortfolioChart;
