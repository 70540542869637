import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Img, Text, Heading, Button, Link } from "../../components";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';  // Import the CSS for react-loading-skeleton
import {
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemState,
  Accordion,
  AccordionItem,
} from "react-accessible-accordion";
import { useNavigate, useParams } from 'react-router-dom';
import HomeDate from "../../components/HomeDate";
import { ConvertDuration } from "utils";
import Sidebar1 from "components/Sidebar1";
import { fetchUnits } from "TeacherAPIService";

const API_ENDPOINT = process.env.REACT_APP_API_BASE_URL;


const colors = {
  "orange": {
    bg: "bg-yellow-100_01",
    image: "/images/img_currency_dollar.svg",
    border: "border-deep_orange-900_87",
    text: "!text-deep_orange-900_7f",
    progress: "bg-deep_orange-900_7f",
  },
  "blue": {
    bg: "bg-blue-50_04",
    image: "/images/img_line_chart_up_03.svg",
    border: "border-blue-900_87",
    text: "!text-blue-900_7f",
    progress: "bg-blue-900_7f",
  },
  "pink": {
    bg: "bg-purple-50",
    image: "/images/img_graduation_hat_02.svg",
    border: "border-pink-800_87",
    text: "!text-pink-800_7f",
    progress: "bg-pink-800_7f",
  }
};

const colorKeys = Object.keys(colors);

function seededRandom(seed) {
  let x = Math.sin(seed) * 10000;
  return x - Math.floor(x);
}

export default function TeacherCourseCardsView() {
  const { courseId } = useParams();

  const navigate = useNavigate();
  const [expanded, setExpanded] = useState([]);

  const [course, setCourse] = useState({ units: [] });
  const [status, setStatus] = useState({ courseStatus: 0 });

  useEffect(() => {
    const getCourseUnits = async () => {
      try {
        const data = await fetchUnits(courseId);
        // Sort units by sequenceNumber
        data.units.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
        setCourse(data);
      } catch (error) {
        console.error("Fetching course units failed", error);
        // Optionally, set an error state to display to the user
      }
    };

    getCourseUnits();
  }, [courseId]);


  const toggleAccordion = (uuid) => {
    const currentIndex = expanded.indexOf(uuid);
    const newExpanded = [...expanded];

    if (currentIndex === -1) {
      newExpanded.push(uuid);
    } else {
      newExpanded.splice(currentIndex, 1);
    }


    setExpanded(newExpanded);
  };

  const seed = course.name ? course.name.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0) : 0;
  const colorIndex = Math.floor(seededRandom(seed) * colorKeys.length);
  const assignedColor = colorKeys[colorIndex];
  const colorScheme = colors[assignedColor];

  return (
    <>
      <Helmet>
        <title>Courses | Intertwined</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="flex w-full items-start justify-center bg-white-A700 ">
        <Sidebar1 />
        <div className="container-md md:p-5">
          <HomeDate className="mb-4" />
          <div className="flex flex-col">
            <div className="flex flex-col items-center gap-6">

              <div className="flex w-[78%] flex-col items-start justify-center gap-4">
                <div className="flex items-start pt-1">
                  <Img
                    src="/images/img_arrow_left_blue_a200.svg"
                    alt="arrowleft"
                    className="relative right-px"
                  />
                  <Text as="p" className="text-blue-500 cursor-pointer justify-start" onClick={() => navigate('/teacherhome')}>
                    Back to all courses
                  </Text>
                </div>
                <div className="flex self-start pb-[5px]">
                  <Heading size="13xl" as="h2">
                    {course.name}
                  </Heading>
                </div>
                <div className="flex items-center p-4 rounded-lg border border-solid border-indigo-50 bg-white-A700 md:flex-col">
                  <div className={`w-[25%] rounded-lg p-2 md:w-full md:p-5 ${colorScheme.bg}`}>
                    <div className="rounded-md px-1.5 pt-1.5">
                      <div className="p-12 items-center justify-center md:py-5 sm:p-5">
                        <Img
                          src={colorScheme.image}
                          alt="course-icon"
                          className="h-[148px] w-[148px]"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col items-start gap-6 rounded-lg bg-white-A700 p-4 md:self-stretch md:p-5">
                    <div className="flex flex-col items-start gap-[15px] self-stretch">
                      <Heading size="6xl" as="h3" className="!font-bold">
                        {/* TODO: The question isn't supported so I'm using the name */}
                        {course.name}
                      </Heading>
                      <Heading as="h4" className="w-[85%] leading-[170%] !text-gray-500_02 md:w-full">
                        {course.description}
                      </Heading>
                    </div>

                    <div className="flex justify-left w-fit gap-4 py-2 w-full mt-4 border-b border-t border-solid border-indigo-50 bg-white-A700 md:w-full md:p-5 sm:flex-col">
                      <div className="flex p-2 border-r border-solid border-gray-300">
                        <Text className="!font-bold">Course Content</Text>
                      </div>
                      <div className="flex p-2 px-8 gap-2 items-center border-r border-solid border-gray-300">
                        <Img src="/images/img_library_books.svg" alt="library books" className="h-[16px] w-[16px]" />
                        <Text>{course.units.length} Units</Text>
                      </div>
                      <div className="flex p-2 px-8 gap-2 items-center border-r border-solid border-gray-300">
                        <Img src="/images/img_access_time.svg" alt="access time" className="h-[16px] w-[16px]" />
                        <Text>{course.units.reduce((acc, cur) => acc + cur.expectedDuration, 0)} Hours of Content</Text>
                      </div>
                      <div className="flex p-2 px-8 gap-2 items-center">
                        <Img src="/images/img_edit_document.svg" alt="edit document" className="h-[16px] w-[16px]" />
                        <Text>{course.units.reduce((acc, cur) => acc + cur.sections.length, 0)} Lessons</Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[78%] rounded-lg border border-solid border-indigo-50 bg-white-A700">
                <div className="rounded-lg">
                  <Text className="font-bold bg-blue-50 p-2 rounded text-lg">Units</Text>
                  <Accordion allowZeroExpanded preExpanded={expanded} className="flex flex-col gap-px">
                    {course.units.map((u, index) => (
                      <AccordionItem uuid={index} key={`expandablelistt${index}`}>
                        <AccordionItemHeading className="w-full">
                          <AccordionItemButton onClick={() => toggleAccordion(index)}>
                            <AccordionItemState>
                              {(props) => (
                                <>
                                  <div className="flex items-center justify-between gap-5 self-stretch border-b border-solid border-indigo-50 bg-white-A700 px-4 pb-[19px] pt-5">
                                    <Heading size="6xl" as="h6" className="self-end">
                                      {u.name}
                                    </Heading>
                                    <div className="flex flex-wrap items-center gap-2.5 self-start">
                                      <Text as="p" className="!text-gray-500_02">
                                        {`${u.expectedDuration} ${ConvertDuration(u.durationUnit)}`}
                                      </Text>
                                      {expanded ? (
                                        <Img src="/images/img_arrow_up_black_900.svg" alt="arrowup" className="h-[24px] w-[24px]" />
                                      ) : (
                                        <Img src="/images/img_arrow_down_black_900.svg" alt="arrowdown" className="h-[24px] w-[24px]" />
                                      )}
                                    </div>
                                  </div>
                                </>
                              )}
                            </AccordionItemState>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <div className="flex justify-between m-[20px] gap-4">
                            <Heading as="p" className="min-w-[50px] text-center self-start 
                                text-deep_purple-A200_01 bg-gray-50 p-1 rounded-md border-2 border-deep_purple-A200_01">
                              Unit {index + 1}
                            </Heading>
                            <div className="flex flex-col items-start gap-4">
                              <div className="flex justify-between items-start">
                                <Heading
                                  size="6xl"
                                  as="h6"
                                  className="!font-poppins leading-[130%] md:ml-0 md:w-full md:p-5"
                                >
                                  <span className="font-notosans text-gray-900_07">
                                    <>
                                      {u.name} <br />
                                    </>
                                  </span>
                                  <span className="text-gray-900_07">
                                    <>
                                      <br />
                                    </>
                                  </span>
                                  <span className="font-notosans font-normal text-gray-900_07">
                                    <>
                                      {u.description}<br />
                                    </>
                                  </span>
                                </Heading>

                                <Button
                                  size="3xl"
                                  className="z-[2] mr-4 min-w-[113px] rounded-[20px] font-medium tracking-[0.10px] md:mr-0 sm:px-5"
                                  onClick={() => navigate(`unit/${u.id}`)}
                                >
                                  View Unit
                                </Button>
                              </div>

                              <div className="relative z-[1] w-[86%] rounded-lg border border-solid border-indigo-50 md:ml-0 md:w-full md:p-5">
                                {u.sections
                                  .sort((a, b) => a.sequenceNumber - b.sequenceNumber) // Sort sections by sequence number
                                  .map((s, j) => (
                                    <div key={`sectionitem${j}`} className="flex bg-gray-50_01 md:flex-col">
                                      <div className="flex flex-1 border border-solid border-indigo-50 p-2 md:self-stretch md:p-5">
                                        <div className="flex pb-2 pt-[11px]">
                                          <Text as="p" className="tracking-[0.50px] !text-blue_gray-500">
                                            {`${index + 1}.${j + 1} ${s.name}`}
                                          </Text>
                                        </div>
                                      </div>
                                      <Text
                                        size="7xl"
                                        as="p"
                                        className="border-b border-r border-t border-solid border-indigo-50 py-[17px] pl-6 pr-[35px] tracking-[0.10px] !text-blue_gray-500 md:p-5 sm:px-5"
                                      >
                                        20 min
                                      </Text>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

