// src/components/TransferMoneyModal.jsx
import React, { useState, useEffect } from 'react';
import { formatUSD } from 'utils'; // Ensure this utility function is correctly imported

const sampleData = {
    "user": {
        "id": "user_12345",
        "name": "Jane Doe",
        "email": "jane.doe@example.com",
        "createdAt": "2020-05-15T10:30:00Z"
    },
    "accounts": [
        {
            "id": "acc_001",
            "type": "checking",
            "nickname": "Daily Expenses",
            "accountNumber": "****1234",
            "balance": 1500.75,
            "availableBalance": 1500.75,
            "currency": "USD",
            "interestRate": 0.01,
            "createdAt": "2020-05-15T10:30:00Z",
            "earnings": 50.00
        },
        {
            "id": "acc_002",
            "type": "savings",
            "nickname": "Emergency Fund",
            "accountNumber": "****5678",
            "balance": 10000.00,
            "availableBalance": 10000.00,
            "currency": "USD",
            "interestRate": 0.02,
            "createdAt": "2019-03-20T09:00:00Z",
            "earnings": 200.00
        },
        {
            "id": "acc_003",
            "type": "credit_card",
            "nickname": "Visa Platinum",
            "accountNumber": "****9012",
            "balance": -235.00,
            "creditLimit": 5000.00,
            "availableCredit": 4765.00,
            "currency": "USD",
            "interestRate": 0.18,
            "createdAt": "2021-07-10T12:00:00Z",
            "dueDate": "2024-12-05T00:00:00Z",
            "minimumPayment": 35.00,
            "earnings": 0.00
        }
    ],
    "currentDate": "2024-11-27T14:30:00+00:00"
};

const typeDisplayNames = {
    'checking': 'Checking Account',
    'savings': 'Savings Account',
    // 'credit_card': 'Credit Card', // Removed since credit cards are excluded
};

const TransferMoneyModal = ({ onClose }) => {
    const [data, setData] = useState(null);
    const [selectedAccountId, setSelectedAccountId] = useState('');
    const [transferToAccountID, setTransferToAccountID] = useState('');
    const [transferAmount, setTransferAmount] = useState('');
    const [noteToSelf, setNoteToSelf] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Simulate fetching data from an API
        // In a real scenario, replace this with an actual API call
        // Filter out credit_card accounts
        const filteredAccounts = sampleData.accounts.filter(acc => acc.type !== 'credit_card');
        setData({ ...sampleData, accounts: filteredAccounts });
        if (filteredAccounts.length > 0) {
            setSelectedAccountId(filteredAccounts[0].id); // Default to the first account
            setTransferToAccountID(filteredAccounts[0].id); // Default to the first account for transfer to
        }
    }, []);

    if (!data) {
        return <div>Loading...</div>;
    }

    const handleAccountChange = (e) => {
        setSelectedAccountId(e.target.value);
        setError('');
    };

    const handleTransferTo = (e) => {
        setTransferToAccountID(e.target.value);
        setError('');
    };

    const handleAmountChange = (e) => {
        setTransferAmount(e.target.value);
        setError('');
    };

    const handleNoteToSelf = (e) => {
        setNoteToSelf(e.target.value); // Fixed to update noteToSelf
        setError('');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const fromAccount = data.accounts.find(acc => acc.id === selectedAccountId);
        const toAccount = data.accounts.find(acc => acc.id === transferToAccountID);

        if (!fromAccount) {
            setError('Selected source account not found.');
            return;
        }

        if (!toAccount) {
            setError('Selected destination account not found.');
            return;
        }

        if (fromAccount.id === toAccount.id) {
            setError('Source and destination accounts must be different.');
            return;
        }

        const amount = parseFloat(transferAmount);
        if (isNaN(amount) || amount <= 0) {
            setError('Please enter a valid transfer amount.');
            return;
        }

        if (amount > fromAccount.availableBalance) {
            setError('Transfer amount exceeds available balance.');
            return;
        }

        // Proceed with the transfer logic
        console.log(`Transferring ${formatUSD(amount)} from ${fromAccount.nickname} to ${toAccount.nickname}`);
        if (noteToSelf) {
            console.log(`Note: "${noteToSelf}"`);
        }

        // Reset form or provide feedback to the user
        setTransferAmount('');
        setNoteToSelf('');
        alert(`Successfully initiated transfer of ${formatUSD(amount)} from ${fromAccount.nickname} to ${toAccount.nickname}.`);
        onClose(); // Close the modal if needed
    };

    const fromAccount = data.accounts.find(acc => acc.id === selectedAccountId);
    const toAccount = data.accounts.find(acc => acc.id === transferToAccountID);

    return (
        <div className='flex flex-col p-6 rounded-lg'>
            <h1 className='text-2xl font-semibold mb-4'>Transfer Money</h1>
            <p className='mb-6 text-gray-600'>Select the accounts and the amount you want to transfer.</p>

            <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
                {/* Transfer From and To Dropdowns */}
                <div className='flex w-full gap-4'>
                    {/* Transfer From */}
                    <div className='flex flex-col w-full'>
                        <label htmlFor='transfer-from' className='mb-2 font-medium text-gray-700'>Transfer from:</label>
                        <select
                            id='transfer-from'
                            value={selectedAccountId}
                            onChange={handleAccountChange}
                            className='p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                        >
                            {data.accounts.map(account => (
                                <option key={account.id} value={account.id}>
                                    {typeDisplayNames[account.type]}
                                </option>
                            ))}
                        </select>
                    </div>

                    {/* Transfer To */}
                    <div className='flex flex-col w-full'>
                        <label htmlFor='transfer-to' className='mb-2 font-medium text-gray-700'>Transfer to:</label>
                        <select
                            id='transfer-to'
                            value={transferToAccountID}
                            onChange={handleTransferTo}
                            className='p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
                        >
                            {data.accounts.map(account => (
                                <option key={account.id} value={account.id}>
                                    {typeDisplayNames[account.type]}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Display Available Balance */}
                <div className='flex w-full justify-between gap-4'>
                    {fromAccount && (
                        <div className='flex flex-col w-full'>
                            <span className='font-medium text-gray-700'>Available Balance:</span>
                            <span className='text-lg font-semibold text-green-500'>
                                {formatUSD(fromAccount.availableBalance)}
                            </span>
                        </div>
                    )}

                    {toAccount && (
                        <div className='flex flex-col w-full'>
                            <span className='font-medium text-gray-700'>Available Balance:</span>
                            <span className='text-lg font-semibold text-green-500'>
                                {formatUSD(toAccount.availableBalance)}
                            </span>
                        </div>
                    )}
                </div>

                {/* Transfer Amount Input */}
                <div className='flex flex-col'>
                    <label htmlFor='transfer-amount' className='mb-2 font-medium text-gray-700'>Amount:</label>
                    <div className='p-4 rounded-lg border'>
                        <input
                            type='number'
                            step="any"
                            id='transfer-amount'
                            value={transferAmount}
                            onChange={handleAmountChange}
                            placeholder='Enter amount'
                            className='p-2 border border-gray-300 rounded-md focus:outline-none placeholder-gray-400'
                            min='0'
                            required
                        />
                    </div>

                </div>

                {/* Note to Self Input */}
                <div className='flex flex-col'>
                    <label htmlFor='transfer-note' className='mb-2 font-medium text-gray-700'>Note to self:</label>
                    <div className='p-4 rounded-lg border w-full'>
                        <textarea
                            id='transfer-note'
                            value={noteToSelf}
                            onChange={handleNoteToSelf}
                            placeholder='Describe your reason for the transfer here'
                            className='w-full h-full placeholder-gray-400'
                            rows='3'
                        ></textarea>
                    </div>

                </div>

                {/* Error Message */}
                {error && <p className='text-red-500'>{error}</p>}

                {/* Submit Button */}
                <div className='flex w-full gap-4'>
                    <button
                        type="button" // Prevents form submission
                        onClick={onClose} // Closes the popup
                        className={`flex w-[30%] justify-center h-[80%] items-center p-4 font-inter font-semibold text-blue-500 text-md border border-blue-500 rounded-lg hover:bg-blue-100 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
                    >
                        Back
                    </button>
                    <button
                        className={`flex w-[70%] justify-center h-[80%] items-center p-4 font-inter font-semibold bg-blue-500 text-white-A700 text-md border rounded-lg hover:bg-blue-400 transform transition-transform duration-300 ease-in-out hover:scale-105 active:scale-100`}
                    >
                        Transfer
                    </button>
                </div>

            </form>
        </div>
    );
};

export default TransferMoneyModal;
