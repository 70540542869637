import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "UserContext";
import { Img } from "components";

const menuItems = [
  {
    name: "Dashboard",
    path: "/teacherhome",
    icon: "/images/img_container.svg",
    activeIconStyle:
      "bg-blue-100 saturate-100 invert-33 sepia-94 saturate-1863 hue-rotate-196 brightness-101 contrast-101",
  },
  {
    name: "Courses",
    path: "/teachercourses",
    icon: "/images/img_container_gray_800_01_40x40.svg",
    activeIconStyle:
      "bg-blue-100 saturate-100 invert-33 sepia-94 saturate-1863 hue-rotate-196 brightness-101 contrast-101",
  },
  {
    name: "Teaching Supplements",
    path: "https://intertwinedfinance.notion.site/Intertwined-Teaching-Supplements-aa47c3cece4d4775907902980479a2e0",
    icon: "/images/img_container_gray_800_01_40x40.svg",
    activeIconStyle:
      "bg-blue-100 saturate-100 invert-33 sepia-94 saturate-1863 hue-rotate-196 brightness-101 contrast-101",
    external: true,
  },
];

export default function Sidebar1({ className = "" }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);

  const isActive = (path) => location.pathname.startsWith(path);

  return (
    <div
      className={`flex flex-col font-semibold justify-between w-20 sticky overflow-hidden overscroll-none pt-2 pb-2 border-r border-blue_gray-100 bg-white-A700 h-screen top-0 left-0 ${className}`}
    >
      <div className="flex flex-col space-y-6">
        {/* Brand Logo */}
        <div
          className="cursor-pointer flex justify-center"
          onClick={() => navigate("/teacherhome")}
        >
          <Img src="/images/img_brand.svg" alt="brand" className="h-8 w-8" />
        </div>

        {/* Menu Items */}
        <nav className="flex flex-col">
          {menuItems.map((item) => (
            item.external ? (
              <a
                key={item.name}
                href={item.path}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center p-2 rounded text-gray-700 hover:text-blue-500 transition-colors"
              >
                <Img
                  src={item.icon}
                  alt={item.name}
                  className={`h-10 w-10 rounded hover:bg-blue-200`}
                />
                <span className="text-[10px] text-center">{item.name}</span>
              </a>
            ) : (
              <button
                key={item.name}
                onClick={() => navigate(item.path)}
                className={`flex flex-col items-center p-2 gap-2 rounded ${
                  isActive(item.path) ? "text-blue-500" : "text-gray-700"
                } hover:text-blue-500 transition-colors`}
              >
                <Img
                  src={item.icon}
                  alt={item.name}
                  className={`h-10 w-10 rounded hover:bg-blue-200 ${
                    isActive(item.path) ? item.activeIconStyle : ""
                  }`}
                />
                <span className="text-xs font-semibold text-center">{item.name}</span>
              </button>
            )
          ))}
        </nav>
      </div>

      {/* User Profile */}
      <div className="flex flex-col text-sm md:text-md items-center">
        <button
          onClick={() => navigate("/myprofile")}
          className="flex flex-col items-center space-y-1 cursor-pointer"
        >
          <Img
            src={userInfo?.profileImage.url || "/images/default_avatar.png"}
            alt="Profile"
            className="h-7 w-7 md:w-10 md:h-10 rounded-full object-cover"
          />
          <span className="text-sm text-gray-700">
            {userInfo?.firstName} {userInfo?.lastName}
          </span>
        </button>
      </div>
    </div>
  );
}
