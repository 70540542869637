
import React, { useState, useEffect } from 'react';
import GaugeChart from 'react-gauge-chart';

const DashboardGaugeChart = (props) => {

    const { id, name, value, maxValue } = props;

    const colors = [
        '#FF4C4C', // Very Poor
        '#FF8000', // Poor
        '#FFD700', // Fair
        '#ADFF2F', // Good
        '#32CD32'  // Excellent
    ];

    const chartStyle = {
        width: '60%',
    }


    useEffect(() => {

    }, []);

    return (
        <div className='flex flex-col w-full rounded-lg border'>

            <div className='flex items-center justify-between p-2'>

                <div className='flex flex-col  pl-4'>

                    <h2 className='font-semibold'>
                        <span
                            className={`text-3xl ${value / maxValue < 0.2
                                ? 'text-[#FF4C4C]'
                                : value / maxValue < 0.4
                                    ? 'text-[#FF8000]'
                                    : value / maxValue < 0.6
                                        ? 'text-[#FFD700]'
                                        : value / maxValue < 0.8
                                            ? 'text-[#ADFF2F]'
                                            : 'text-[#32CD32]'
                                }`}
                        >
                            {value}
                        </span>
                        <span className='text-2xl'>/{maxValue}</span>
                    </h2>

                    <h1 className='font-inter font-semibold text-sm whitespace-nowrap'>{name}</h1>
                </div>


                <GaugeChart
                    id={id}
                    nrOfLevels={5}
                    arcWidth={0.3}
                    hideText={true}
                    colors={colors}
                    arcPadding={0}
                    cornerRadius={0}
                    percent={value / maxValue}
                    style={chartStyle}
                />
            </div>
        </div>
    );
};

export default DashboardGaugeChart;
